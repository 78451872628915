
const GrapesJSButtons = ({ editor, fncSaveEditorData }) => {
    const handleShowJson = () => {
        if (editor) {
            console.log(JSON.stringify(editor.getComponents()), editor.getCss({ avoidProtected: true }));
        }
    };

    const handleSave = () => {
        if (editor) {
            let comps = editor.getComponents();
            const checkLayoutWrapper = (model) => {
                if (model.attributes.type !== 'LayoutWrapper') {
                    model.get('components').each(model => checkLayoutWrapper(model));
                } else {
                    model.set('components', []);
                }
            };

            let componentsInLayoutPlaceHolder = null;
            const getLayoutPlaceHolderComponents = (model) => {
                if (model.attributes.type === 'LayoutPlaceHolder') {
                    componentsInLayoutPlaceHolder = model.get('components');
                }
                // Continue to search deeper even if we found one LayoutPlaceHolder
                model.get('components').each(m => getLayoutPlaceHolderComponents(m));
            };

            comps.forEach(element => {
                checkLayoutWrapper(element);
            });

            comps.forEach(element => {
                getLayoutPlaceHolderComponents(element);
            });

            if (componentsInLayoutPlaceHolder === null || componentsInLayoutPlaceHolder.models.length === 0) {
                fncSaveEditorData(JSON.stringify(comps), editor.getCss({ avoidProtected: true }));
            } else {
                fncSaveEditorData(JSON.stringify(componentsInLayoutPlaceHolder), editor.getCss({ avoidProtected: true }));
            }
        }
    };

    const handleImportJson = () => {
        if (editor) {
            const modal = editor.Modal;
            modal.setTitle('Import JSON');
            const content = ImportJSON(editor);
            modal.setContent(content);
            modal.open();
        }
    };

    return (
        <div className="button-container">
            <button className="btn btn-default" onClick={handleShowJson}>Show JSON</button>
            <button className="btn btn-default" onClick={handleSave}>Save</button>
            <button className="btn btn-default" onClick={handleImportJson}>Import JSON</button>
        </div>
    );
};

export default GrapesJSButtons;
