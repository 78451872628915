import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
//import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { setWindowClass } from 'utils/helpers';
import { PfCheckbox, PfButton } from '@profabric/react-components';
//import * as Yup from 'yup';

// import {
//   GoogleProvider,
//   authLogin,
//   facebookLogin,
// } from '@app/utils/oidc-providers';
//import { Form, InputGroup } from 'react-bootstrap';
import { useAppDispatch } from 'store/hooks';
import { B2CApiModels, B2CApiServiceModels, B2CApiServices } from '@santsg/ui-component-core';
import { setUser } from 'store/features/user/UserSlice';
import { CookieManager } from '@santsg/ui-common';
import { Form, FormInstance, Input, InputGroup, Schema } from 'rsuite';
import { mailRule, requireStringRule } from 'utils/globalRules';
import { getSiteCulture } from 'store/features/siteCulture/SiteCultureSlice';
import _ from 'lodash';

const LoginScheme = Schema.Model({
  mail: mailRule,
  password: requireStringRule
});

const Login = () => {
  const [t] = useTranslation();
  const userDispatch = useAppDispatch();
  const [isAuthLoading, setAuthLoading] = useState(false);
  const [isGoogleAuthLoading, setGoogleAuthLoading] = useState(false);
  const [isFacebookAuthLoading, setFacebookAuthLoading] = useState(false);
  const dispatch = useAppDispatch();
  const formRef = useRef<FormInstance>(null);
  const [formError, setFormError] = useState({});
  const [formValue, setFormValue] = useState<Record<string, any>>();
  const [visible, setVisible] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    setFormValue({ mail: 'san@santsg.com', password: 'B2CT3@m' });
  }, [])

  const handlePassChange = () => {
    setVisible(!visible);
  };
  const navigate = useNavigate();


  const login = async (email: string, password: string) => {

    try {
      setAuthLoading(true);
      //const response = await authLogin(email, password);
      let req = new B2CApiServiceModels.Module.User.UserLoginRequest(email, password);
      let res = await B2CApiServices.UserService.AuthService.Login(req);

      userDispatch(setUser(res.body?.user));
      navigate('/');
      //dispatch(setAuthentication(res as any));
      toast.success('Login is succeed!');
      setAuthLoading(false);
      // dispatch(loginUser(token));
      navigate('/');
    } catch (error: any) {
      setAuthLoading(false);
      toast.error(error.message || 'Failed');
    }
  };

  const handleSubmit = async () => {
    if (formRef?.current?.check()) {
      let req = formValue as B2CApiServiceModels.Module.User.UserLoginRequest;
      try {
        let res = await B2CApiServices.UserService.AuthService.Login(req);
        if (res.header.success) {
          userDispatch(setUser(res.body?.user));
          var siteMappings = res.body?.user.userMappings;
          var baseSite = _.find(siteMappings, function (s) { return s.webSite?.isBaseSite });
          if (baseSite == null)
            dispatch(getSiteCulture());
          navigate('/');
        }
        else {
          toast.error(res.header.messages?.at(0)?.message);
        }
      } catch (e: any) {
        toast.error("Server Connection Failed");
      }
    } else console.log(formValue);
  }

  setWindowClass('hold-transition login-page');

  return (
    <div className="login-box">
      <div className="card card-outline card-primary">
        <div className="card-header text-center">
          <Link to="/" className="h1">
            <b>B2C</b>
            <span>Panel</span>
          </Link>
        </div>
        <div className="card-body">
          <p className="login-box-msg">{t('login.label.signIn')}</p>
          <Form ref={formRef} formValue={formValue} onChange={setFormValue} onSubmit={handleSubmit} model={LoginScheme}>
            <div className="mb-3">

              <Form.Control
                name="mail"
                type="email"
                className='form-control'
                placeholder="Email"
              />
            </div>
            <div className="input-group mb-3">

              <Form.Control
                className='form-control'
                name="password"
                type={visible ? 'text' : 'password'}
                placeholder="Password"
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  {visible ?
                    <span onClick={handlePassChange} className="fas fa-eye"></span>
                    :
                    <span onClick={handlePassChange} className="fas fa-eye-slash"></span>
                  }
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-8">
                <PfCheckbox checked={false}>
                  {t('login.label.rememberMe')}
                </PfCheckbox>
              </div>
              <div className="col-4">
                <PfButton
                  block
                  itemType='submit'
                  loading={isAuthLoading}
                >
                  {t('login.button.signIn.label')}
                </PfButton>
              </div>
            </div>
          </Form>


          <p className="mb-1">
            <Link to="/forgot-password">
              {t('login.label.forgotPass')}
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
