import React, { Fragment, useEffect, useState } from 'react';
import { NavLink, useNavigate, useLocation, Location } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IRoute } from 'interfaces/IRoute';
import _ from 'lodash';
import { IMenuItem } from 'interfaces/IMenuItem';
import { useAppSelector } from 'store/hooks';
import { enmMenuItemDisplayRestriction } from 'models/enums/MenuItemDisplayRestriction';

export interface MenuWrapperProps {
  items: IMenuItem[];
}

const MenuWrapper = (props: MenuWrapperProps) => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeItem, setActiveItem] = useState<string>();
  const [selectedMenus, setSelectedMenus] = useState<string[]>([]);
  const [activeTreeMenus, setActiveTreeMenus] = useState<string[]>([]);
  const authUi = useAppSelector(s => s.auth);
  const [menuItems, setMenuItems] = useState<Array<IMenuItem>>();
  const manuelTrigger = useAppSelector(state => state.ui?.triggerPathChange);
  const activePath = useAppSelector(state => state.ui?.activePath);

  useEffect(() => {
    if (!menuItems) {
      var items = new Array<IMenuItem>();
      authUi?.webSite?.isBaseSite ? items = _.filter(props.items, function (i) { return i.displayRestriction == enmMenuItemDisplayRestriction.BaseSite || i.displayRestriction == enmMenuItemDisplayRestriction.Common }) :
        items = _.filter(props.items, function (i) { return i.displayRestriction != enmMenuItemDisplayRestriction.BaseSite });
      setMenuItems(items);
    }
    if (location.pathname != "/") {
      var activeTreeMenus: string[] = [];
      var activeMenuPath = "";
      _.each(props.items, function (item) {
        if (location.pathname == item.path) {
          activeMenuPath = item.path;
          activeTreeMenus.push(item.path);
        } else {
          if (item.children) {
            _.each(item.children, function (subItem) {
              if (location.pathname == subItem.path!) {
                activeMenuPath = subItem.path;
                activeTreeMenus.push(subItem.path, item.path!);
              } else {
                if (subItem.children) {
                  _.each(subItem.children, function (c) {
                    if (location.pathname == c.path!) {
                      activeMenuPath = c.path;
                      activeTreeMenus.push(c.path, subItem.path!, item.path!);
                    }
                  })
                }
              }
            })
          }
        }

      });
      setActiveItem(activeMenuPath);
      setActiveTreeMenus(activeTreeMenus);
    } else {
      setActiveItem("/");
    }
  }, [props, activePath]);

  const handleTreeItemClick = (paths: string[]) => {
    if (activeTreeMenus.length == paths.length) {
      var shiftedMenus = _.filter(paths, function (tr) { return tr != activeTreeMenus[0] });
      setActiveTreeMenus(shiftedMenus);
    } else {
      setActiveTreeMenus(paths);
    }
  }

  const handleNavigate = (path: string) => {
    setActiveItem(path);
    navigate(path);
  }

  return (
    <Fragment>
      {
        menuItems && menuItems.map((item: IMenuItem, index: number) => (
          item.isLink ?
            <li key={index} className={`nav-item`}>
              <a
                className={`nav-link`}
                role="link"
                target='_blank'
                href={item.path}
                style={{ cursor: 'pointer' }}
              >
                <i className={`${item.icon}`} />
                <p>{t(`menu.${item.label}`)}</p>
                {item.children && item.children.length > 0 ? <i className="right fas fa-angle-left" /> : null}
              </a>
            </li>
            :
            <li key={index} className={`nav-item${activeTreeMenus.includes(item.path!) && item.children && item.children.length > 0 ? ' menu-open' : ''}`}>
              <a
                className={`nav-link${activeItem == item.path ? ' active' : ''}`}
                role="link"
                onClick={() => item.children && item.children.length > 0 ? handleTreeItemClick([item.path!]) : handleNavigate(item.path!)}
                style={{ cursor: 'pointer' }}
              >
                <i className={`${item.icon}`} />
                <p>{t(`menu.${item.label}`)}</p>
                {item.children && item.children.length > 0 ? <i className="right fas fa-angle-left" /> : null}
              </a>
              {
                item.children && item.children.length > 0
                &&
                <ul key={item.label} className="nav nav-treeview">
                  {
                    item.children.map((subItem: IMenuItem, cIndex: number) => (
                      <li key={cIndex} className={`nav-item${activeTreeMenus.includes(subItem.path!) && subItem.children && subItem.children.length > 0 ? ' menu-open' : ''}`}>
                        {
                          <a
                            className={`nav-link ${activeItem == subItem.path! ? ' active' : ''}`}
                            role="link"
                            onClick={() => subItem.children && subItem.children.length > 0 ? handleTreeItemClick([subItem.path!, item.path!]) : handleNavigate(subItem.path!)}
                            style={{ cursor: 'pointer' }}
                          >
                            <i className={`${subItem.icon}`} />
                            <p>✧ {t(`menu.${subItem.label}`)}</p>
                            {subItem.children && subItem.children.length > 0 ? <i className="right fas fa-angle-left" /> : null}
                          </a>
                        }
                        {
                          subItem.children && subItem.children.length > 0
                          &&
                          <ul key={item.label} className="nav nav-treeview">
                            {
                              subItem.children.map((subChildItem: IMenuItem, sIndex: number) => (
                                <li key={sIndex} className={`nav-item${activeTreeMenus.includes(subChildItem.path!) && subChildItem.children && subChildItem.children.length > 0 ? ' menu-open' : ''}`}>
                                  <a className={`nav-link${activeItem == subChildItem.path ? ' active' : ''}`} onClick={() => handleNavigate(subChildItem.path!)}>
                                    <i className={`${subChildItem.icon}`} title={`${subChildItem.label}`} />
                                    <p>⌲ {t(`menu.${subChildItem.label}`)}</p>
                                  </a>
                                </li>
                              ))
                            }
                          </ul>
                        }
                      </li>
                    ))
                  }
                </ul>
              }
            </li>
        ))
      }
      <style>
        {`
          .nav-link{
            cursor:pointer;
          }
        `
        }

      </style>
    </Fragment>

  );
};

export default MenuWrapper;
