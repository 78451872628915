
// import {authSlice} from '@app/store/reducers/auth';
// import {uiSlice} from '@app/store/reducers/ui';
// import {createLogger} from 'redux-logger';

// const store = configureStore({
//   reducer: {
//     auth: authSlice.reducer,
//     ui: uiSlice.reducer
//   },
//   middleware: (getDefaultMiddleware) => [
//     ...getDefaultMiddleware().concat(createLogger())
//   ]
// });

// export default store;
// "use client"
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import UserSlice from "store/features/user/UserSlice";
import SiteCultureSlice from "store/features/siteCulture/SiteCultureSlice";
import uiSlice, { UiState } from "store/features/ui/UiSlice";
import { IUser } from "interfaces/user";
import { ISiteCulture } from "interfaces/siteculture";
import {B2CApiModels} from "@santsg/ui-component-core";
import SettingsSlice from "./features/settings/SettingsSlice";



const rootReducer = combineReducers({
    auth: UserSlice,
    cultureInfo: SiteCultureSlice,
    ui: uiSlice,
    settings: SettingsSlice
});

export const store = configureStore({
    reducer: rootReducer,
    //middleware:getDefaultMiddlerware().concat(logger)
});

export type RootState =
    Partial<{
        auth: IUser;
    }>
    &
    Partial<{
        cultureInfo: ISiteCulture
    }>
    &
    Partial<{
        ui: UiState
    }>
    &
    Partial<{
        settings: B2CApiModels.Module.SettingModel.ISettings
    }>


export type AppDispatch = typeof store.dispatch;
export default store;
