import { IMenuItem } from "interfaces/IMenuItem";
import { enmMenuItemDisplayRestriction } from "models/enums/MenuItemDisplayRestriction";
import { B2CApiModels } from "@santsg/ui-component-core";


export const menuItems: Array<IMenuItem> = [
    {
        path: '/',
        label: "dashboard",
        icon: '',
        children: null,
        displayRestriction: enmMenuItemDisplayRestriction.Common
    },
    {
        path: '/pages-menu',
        label: 'pages',
        icon: '',
        children: [
            {
                path: '/static-pages',
                label: 'staticPages',
                icon: '',
            },
            {
                path: '/dynamic-pages',
                label: 'dynamicPages',
                icon: '',
            }
        ]
    },
    {
        path: '/templates',
        label: 'templates',
        icon: '',
        children: null
    },
    {
        path: '/contents',
        label: 'contents',
        icon: '',
        children: null
    },
    {
        path: '/data-sources',
        label: 'dataSources',
        icon: '',
        children: [
            {
                path: '/data-source-list-groups',
                label: 'dataSourceListGroups',
                icon: '',
            },
            {
                path: '/data-source-form-templates',
                label: 'dataSourceFormTemplates',
                icon: '',
            }
        ]
    },
    {
        path: '/events',
        label: 'events',
        icon: '',
        children: null
    },
    {
        path: '/file-management',
        label: 'fileManagement',
        icon: '',
        children: [
            {
                path: '/images',
                label: 'images',
                icon: '',
            },
            {
                path: '/documents',
                label: 'documents',
                icon: '',
            }
        ]
    },
    {
        path: '/galleries',
        label: 'galleries',
        icon: '',
        children: null
    },
    {
        path: '/menus',
        label: 'menus',
        icon: '',
        children: null
    },
    {
        path: '/meta-settings',
        label: 'metaSettings',
        icon: '',
        children: null
    },
    {
        path: '/search-links',
        label: 'searchLinks',
        icon: '',
        children: null
    },
    {
        path: '/site-cultures',
        label: 'siteCultures',
        icon: '',
        children: null
    },
    {
        path: '/site-urls',
        label: 'siteUrls',
        icon: '',
        children: null
    },
    {
        path: '/location-parameters',
        label: 'locationParameters',
        icon: '',
        children: null
    },
    {
        path: '/component-menu',
        label: 'componentManagement',
        icon: '',
        children: [
            {
                path: '/components',
                label: 'components',
                icon: '',
            },
            {
                path: '/composit-components',
                label: 'compositComponents',
                icon: '',
            }
        ]
    },
    {
        path: '/settings-menu',
        label: 'settings',
        icon: '',
        children: [
            {
                path: '/receivers',
                label: 'receivers',
                icon: '',
            },
            {
                path: '/site-settings',
                label: 'siteSettings',
                icon: '',
                children: (new B2CApiModels.Module.SettingModel.Settings().getSettingsKeys())
                    .map(key => ({
                        path: `/site-settings?groupCode=${key}`,
                        label: `${key}`,
                        icon: '',
                        children: null
                    }))
            },
        ]
    },
    {
        path: '/policies',
        label: 'policies',
        icon: '',
    },
    {
        path: '/localizations',
        label: 'localizations',
        icon: '',
        children: null,
    },
    {
        path: '/logs',
        label: 'logs',
        icon: '',
        children: null
    },
    {
        path: '/users',
        label: 'users',
        icon: '',
        displayRestriction: enmMenuItemDisplayRestriction.Common,
    },
    {
        path: '/roles',
        label: 'roles',
        icon: '',
        displayRestriction: enmMenuItemDisplayRestriction.Common,
    },
    {
        path: '/web-sites',
        label: 'webSites',
        icon: '',
        children: null,
        displayRestriction: enmMenuItemDisplayRestriction.BaseSite,
    },
    {
        path: '/app-tasks',
        label: 'appTasks',
        icon: '',
        children: null
    },
    {
        path: '/app-files',
        label: 'appFiles',
        icon: '',
        children: null
    },
    {
        path: '/galleries',
        label: 'galleries',
        icon: '',
        children: null
    }
];
