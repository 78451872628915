import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Main from 'components/main/Main';
import PublicRoute from './PublicRoute';
import Login from 'pages/login/Login';
import ForgotPassword from 'pages/forgot-password/ForgotPassword';
import { allRoutes } from './RouteDefinitions';

export function AppRoutes() {
  return <Routes>
    <Route path='/' element={<Main />}>
      {
        allRoutes.filter(w => w.permissions && w.permissions.length > 0).map((m, i) => {
          return <Route
            key={i}
            path={m.path}
            element={
              <React.Suspense fallback={<>Loading...</>}>
                <PrivateRoute>
                  <m.component />
                </PrivateRoute>

              </React.Suspense>
            }></Route>
        })
      }

    </Route>
    <Route path="/login" element={<PublicRoute />}>
      <Route path="/login" element={<Login />} />
    </Route>
    <Route path="/forgot-password" element={<PublicRoute />}>
      <Route path="/forgot-password" element={<ForgotPassword />} />
    </Route>
  </Routes>
}