import { B2CApiServiceModels, B2CApiServices } from '@santsg/ui-component-core';
import { ExportDefaultComponents } from '@santsg/ui-component-mock';

export default async (editor) => {

    const fnGetSanComponents = async () => {
        let request = new B2CApiServiceModels.Module.Component.ComponentListRequest();
        let response = await B2CApiServices.ComponentService.List(request, false, true);
        return response.body.data
    }

    var components = await fnGetSanComponents();

    return ExportDefaultComponents.filter(w => w.componentProp).forEach((fc, fi) => {
        let componentSettings = components.find(c => c.type == fc.name) ? components.find(c => c.type == fc.name).settingsObject : null;
        editor.Components.addType(fc.name, {
            extend: "react-component",
            parentComponent: fc.parentComponent,
            groupCode: fc.groupCode,
            code: fc.code,
            model: {
                defaults: fc.componentProp(fc.component, componentSettings)
            },
            isComponent: (el) => el.tagName === fc.name.toString().toUpperCase()
        });
        if (fc.panelVisibility == null || fc.panelVisibility) {
            editor.BlockManager.add(fc.name, {

                label: `<div class='gjs-fonts ${fc.icon}'>${fc.name}</div>`,
                //category:`${fc.category.toString()}`,
                category: {
                    id: `${fc.category.toString().replaceAll(' ', '-')}`,
                    label: `${fc.category.toString()}`,
                    order: 0,
                    open: false
                },

                content: `<${fc.name} />`
            })
        }

    });
    // editor.Components.addType("OnlyHotelSearchComponent", {
    //     extend: "react-component",
    //     model: {
    //         defaults: SfCore.OnlyHotelSearch.OnlyHotelSearchPropsForEditor(OnlyHotelSearchComponent),
    //         init() {
    //             this.on('change:type', this.handlePropChange);
    //             // Listen to any attribute change
    //             this.on('change:attributes', this.handleAttrChange);
    //             // Listen to title attribute change
    //             this.on('change:attributes:required', this.handleTitleChange);
    //         },
    //         updated(property, value, prevValue) {

    //             console.log('Local hook: model.updated',
    //                 'property', property, 'value', value, 'prevValue', prevValue);
    //         },
    //         removed() {
    //             console.log('Local hook: model.removed');
    //         },
    //         handlePropChange() {
    //             const { someprop } = this.props();
    //             console.log('New value of someprop: ', someprop);
    //         },

    //         handleAttrChange() {
    //             console.log('Attributes updated: ', this.getAttributes());
    //         },

    //         handleTitleChange() {
    //             console.log('Attribute text updated: ', this.getAttributes().required);
    //         },

    //     },
    //     view: {
    //         init() {
    //             console.log('Local hook: view.init');
    //         },
    //         onRender() {
    //             console.log('Local hook: view.onRender');
    //         },
    //     },
    //     isComponent: (el) => el.tagName === "ONLYHOTELSEARCHCOMPONENT"
    // });

    // editor.BlockManager.add("OnlyHotelSearch", {
    //     label: "<div class='gjs-fonts gjs-f-b1'>Only Hotel Search</div>",
    //     category: "Search Blocks",
    //     content: "<OnlyHotelSearchComponent />"
    // });
    // editor.Components.addType("CompA", {
    //     extend: "react-component",
    //     model: {
    //         defaults: {
    //             component: '<div class="san">Sab</div>',
    //             stylable: true,
    //             resizable: true,
    //             editable: true,
    //             draggable: true,
    //             droppable: true,
    //             attributes: { class: 'comp-a' },
    //             traits(component) {
    //                 console.log(component);
    //                 const result = [];
    //                 if (component.get('draggable')) {
    //                     result.push('name');
    //                 } else {
    //                     result.push({
    //                         label: 'Type', // The label you will see in Settings
    //                         name: 'type', // The name of the attribute/property to use on component
    //                         options: [
    //                             { id: 'text', name: 'Text' },
    //                             { id: 'email', name: 'Email' },
    //                             { id: 'password', name: 'Password' },
    //                             { id: 'number', name: 'Number' },
    //                         ]
    //                     });
    //                 }

    //                 return result;
    //             }
    //         }
    //     },
    //     isComponent: (el) => el.tagName === "COMPA"
    // });

    // editor.BlockManager.add("CompA", {
    //     label: "<div class='gjs-fonts gjs-f-b1'>Comp 1</div>",
    //     category: "Search Blocks",
    //     content: "<CompA></CompA>"
    // });
    // editor.Components.addType("CompB", {
    //     extend: "react-component",
    //     model: {
    //         defaults: {
    //             component: CompB,
    //             stylable: true,
    //             resizable: true,
    //             editable: true,
    //             draggable: true,
    //             droppable: true,
    //             attributes: {
    //                 dateType: 1
    //             },
    //             traits: [
    //                 {
    //                     type: "number",
    //                     label: "DateType",
    //                     name: "dateType"
    //                 }
    //             ]
    //         }
    //     },
    //     isComponent: (el) => el.tagName === "COMPB"
    // });

    // editor.BlockManager.add("CompB", {
    //     label: "<div class='gjs-fonts gjs-f-b1'>Comp 2</div>",
    //     category: "Search Blocks",
    //     content: "<CompB />"
    // });
    // //editor.Components.addType("Container", {
    // //    extend: "react-component",
    // //    model: {
    // //        defaults: {
    // //            component: '<div class="row"></div>',
    // //            editable: true,
    // //            draggable: true,
    // //            droppable: true,
    // //        }
    // //    },
    // //    isComponent: (el) => el.tagName === "CONTAINER"
    // //});

    // //editor.BlockManager.add("row", {
    // //    label: "<div class='gjs-fonts gjs-f-b1'>Container</div>",
    // //    category: "Containers",
    // //    content: `<div class="row"></div>`
    // //});
};