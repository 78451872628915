import { Fragment, useEffect } from "react";
import { Button, Drawer, Modal } from 'rsuite';
import _ from 'lodash';
import { B2CUiEnums } from "@santsg/ui-component-core";
import { SanModalProps } from "models/types/san-elements/SanModalProps";
import { enmModalSize } from "models/enums/ModalSize";

function SanModal(props: SanModalProps) {
    useEffect(() => {
    }, [props]);

    return (
        <Fragment>
            {
                props.type == B2CUiEnums.enmModalShowType.Drawer
                &&
                <Drawer size={props.size ? props.size : enmModalSize.SM} backdrop={props.backdrop} open={props.open} onClose={() => props.onClose && props.onClose()}>
                    <Drawer.Header>
                        {
                            props.title
                            &&
                            <Drawer.Title>{props.title}</Drawer.Title>
                        }
                        {
                            (props.onClose || props.onConfirm)
                            &&
                            <Drawer.Actions>
                                {
                                    props.onClose && props.closeLabel
                                    &&
                                    <Button onClick={() => props.onClose && props.onClose()}>{props.closeLabel}</Button>
                                }
                                {
                                    props.onConfirm && props.confirmLabel
                                    &&
                                    <Button onClick={() => props.onConfirm && props.onConfirm()} appearance="primary">
                                        {props.confirmLabel}
                                    </Button>
                                }


                            </Drawer.Actions>
                        }
                    </Drawer.Header>
                    <Drawer.Body>
                        {
                            props.body
                            &&
                            <props.body {...props.bodyProps} />
                        }
                    </Drawer.Body>
                </Drawer>
            }
            {
                props.type == B2CUiEnums.enmModalShowType.Modal
                &&
                <Modal backdrop={props.backdrop} keyboard={false} size={props.size ? props.size : enmModalSize.SM} open={props.open} onClose={() => props.onClose ? props.onClose() : () => { }}>
                    {
                        props.title
                        &&
                        <Modal.Header closeButton={props.onClose ? true : false}>
                            <Modal.Title>{props.title}</Modal.Title>
                        </Modal.Header>
                    }
                    <Modal.Body className={props.additionalClass} style={{ overflow: "inherit", overflowY: "inherit", overflowX: "inherit" }}>
                        {
                            props.body
                            &&
                            <props.body {...props.bodyProps} />
                        }
                    </Modal.Body>
                    {
                        (props.onClose || props.onConfirm)
                        &&
                        <Modal.Footer>
                            {
                                props.onConfirm
                                &&
                                <Button onClick={() => props.onConfirm && props.onConfirm()} appearance="primary">
                                    {props.confirmLabel}
                                </Button>
                            }
                            {
                                props.onClose
                                &&
                                <Button onClick={() => props.onClose && props.onClose()} appearance="subtle">
                                    {props.closeLabel}
                                </Button>
                            }
                        </Modal.Footer>
                    }
                </Modal>
            }
        </Fragment>
    )
}

export default SanModal;