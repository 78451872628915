import React, { useState } from "react";
import { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonToolbar, Form } from "rsuite";
import { B2CApiEnums, B2CApiModels, B2CApiServiceModels, B2CApiServices, B2CUiHelpers } from "@santsg/ui-component-core";
import _ from 'lodash';
import { SanFormControl } from "components/common/SanFormControl";
import { ObjectValueController } from "@santsg/ui-common";
import { ValidationResult, getValidationResult } from "models/types/common/ValidationResult";
import { SiteUrlFormProps } from "models/types/site-url/SiteUrlFormProps";
import { AccepterType } from "models/enums/FormAccepterTypes";
import { SanDynamicObject } from "models/types/san-elements/SanDynamicObject";

const SiteUrlForm = (props: SiteUrlFormProps) => {
    const [request, setRequest] = React.useState<B2CApiServiceModels.Module.SiteUrl.SiteUrlCreateRequest
        | B2CApiServiceModels.Module.SiteUrl.SiteUrlUpdateRequest>({});
    const [t] = useTranslation();
    const [validationErrors, setValidationErrors] = useState<Array<ValidationResult>>([]);
    const pageTypes = B2CUiHelpers.EnumHelper.getEnumKeyValues(B2CApiEnums.enmPageType);
    const [urlParams, setUrlParams] = useState<B2CApiModels.Module.DataSource.mdlDataSourceListItem[]>();
    const [filteredUrlParams, setFilteredUrlParams] = useState<B2CApiModels.Module.DataSource.mdlDataSourceListItem[]>();
    const [parameterError, setParameterError] = useState<string>();
    const [showParameterError, setShowParameterError] = useState<boolean>();

    useEffect(() => {
        if (urlParams == null) {
            getUrlParams();
        }

        if (!props.siteUrl) {
            var request = new B2CApiServiceModels.Module.SiteUrl.SiteUrlCreateRequest();
            request.default = false;
            setRequest(request);
        } else {
            var pRequest = new B2CApiServiceModels.Module.SiteUrl.SiteUrlUpdateRequest();
            pRequest.id = props.siteUrl.id;
            pRequest.default = props.siteUrl.default;
            pRequest.pageType = props.siteUrl.pageType;
            pRequest.siteCultureId = props.siteUrl.siteCulture?.id;
            pRequest.urlPattern = props.siteUrl.urlPattern;
            setRequest(pRequest);
            prepareUrlParamsByPageType(props.siteUrl.pageType);
        }
    }, [props, urlParams])

    const handleSubmit = () => {
        setValidationErrors([]);
        if (checkValidation()) {
            save();
        }
    }


    async function handlePageTypeChangeSelect(result: SanDynamicObject) {
        var value = result["pageType"];
        setRequest({
            ...request,
            ["pageType"]: value
        });
        prepareUrlParamsByPageType(value);
    }

    async function getUrlParams() {
        var req = new B2CApiServiceModels.Module.DataSource.DataSourceListItem.DataSourceListItemListRequest();
        req.listGroupType = B2CApiEnums.enmDataSourceListGroupType.URLPARAMETERS;
        req.pageSize = 0;
        var response = await B2CApiServices.DataSourceService.DataSourceListItem.List(req, false, true);
        if (response && response.header.success && response.body)
            setUrlParams(response.body.data);
        else {
            setUrlParams([]);
        }
    }

    const prepareUrlParamsByPageType = (pageType: B2CApiEnums.enmPageType) => {
        var filteredResult = new Array<B2CApiModels.Module.DataSource.mdlDataSourceListItem>();
        if (urlParams != null) {
            switch (pageType) {
                case B2CApiEnums.enmPageType.HotelList:
                    filteredResult = _.filter(urlParams, function (u) { return u.listGroup?.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.PARAM_HOTEL_LIST });
                    break;
                case B2CApiEnums.enmPageType.HotelDetail:
                    filteredResult = _.filter(urlParams, function (u) { return u.listGroup?.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.PARAM_HOTEL_DETAIL });
                    break;
                case B2CApiEnums.enmPageType.PackageDetail:
                    filteredResult = _.filter(urlParams, function (u) { return u.listGroup?.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.PARAM_PACKAGE_DETAIL });
                    break;
                case B2CApiEnums.enmPageType.PackageList:
                    filteredResult = _.filter(urlParams, function (u) { return u.listGroup?.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.PARAM_PACKAGE_LIST });
                    break;
            }
        }
        setFilteredUrlParams(filteredResult);
    }

    //#region HELPERS
    const checkValidation = () => {
        var messages = new Array<ValidationResult>();
        var result = false;
        if (ObjectValueController.isNullOrUndefinedOrEmpty(request.default)) {
            messages.push(getValidationResult(t, "default"));
        }
        if (ObjectValueController.isNullOrUndefinedOrEmpty(request.pageType)) {
            messages.push(getValidationResult(t, "pageType"));
        }
        if (ObjectValueController.isNullOrUndefinedOrEmpty(request.id) && (props.siteUrl != null)) {
            messages.push(getValidationResult(t, "id"));
        }
        if (ObjectValueController.isNullOrUndefinedOrEmpty(request.urlPattern)) {
            messages.push(getValidationResult(t, "urlPattern"));
        }
        if (ObjectValueController.isNullOrUndefinedOrEmpty(request.siteCultureId)) {
            messages.push(getValidationResult(t, "siteCultureId"));
        }
        messages.length == 0 ? result = true : result = false;
        setValidationErrors(messages);

        return result;
    }

    const restoreParameterErrors = () => {
        setShowParameterError(false);
        setParameterError(undefined);
    }

    //#endregion

    //#region API CALLS
    async function save() {
        var response = props.siteUrl ? await B2CApiServices.SiteUrlService.Update(request as B2CApiServiceModels.Module.SiteUrl.SiteUrlUpdateRequest, false, true) :
            await B2CApiServices.SiteUrlService.Create(request as B2CApiServiceModels.Module.SiteUrl.SiteUrlCreateRequest, false, true);
        if (response && response.header.success && response.body) {
            props.successCallback && props.successCallback();
        }
        else {
            props.errorCallback ? props.errorCallback(response.header.messages) : console.log("failed");
        }
    }
    //#endregion

    return (
        <Fragment>
            <Form
                onChange={setRequest}>
                {
                    showParameterError
                    &&
                    <div>
                        <strong style={{ color: "red" }}>
                            {parameterError}
                        </strong>
                    </div>
                }

                <SanFormControl
                    model={request}
                    onChange={handlePageTypeChangeSelect}
                    name="pageType"
                    dataSource={pageTypes}
                    accepter={AccepterType.DROPDOWN}
                    placeholder={t("siteUrlPage.siteUrlForm.pageType")}
                    label={t("siteUrlPage.siteUrlForm.pageType")}
                    helperText={t("siteUrlPage.siteUrlForm.pageTypeInputInfo")}
                    errors={validationErrors}
                />

                {
                    filteredUrlParams && filteredUrlParams.length > 0
                    &&
                    <Fragment>
                        <strong>{t("siteUrlPage.siteUrlForm.parametersToUse")} </strong>
                        <ul>
                            {
                                filteredUrlParams.map((param: B2CApiModels.Module.DataSource.mdlDataSourceListItem, index: number) => (
                                    <li key={index}>
                                        {param.values}
                                    </li>
                                ))
                            }
                        </ul>
                    </Fragment>
                }

                <SanFormControl
                    model={request}
                    onChange={setRequest}
                    name="urlPattern"
                    label={t("siteUrlPage.siteUrlForm.urlPattern")}
                    type="text"
                    placeholder={t("siteUrlPage.siteUrlForm.urlPattern")}
                    helperText={t("siteUrlPage.siteUrlForm.urlPatternInputInfo")}
                    errors={validationErrors}
                />

                <SanFormControl
                    model={request}
                    onChange={setRequest}
                    name="siteCultureId"
                    dataSource={props.cultures}
                    accepter={AccepterType.DROPDOWN}
                    placeholder={t("siteUrlPage.siteUrlForm.culture")}
                    label={t("siteUrlPage.siteUrlForm.culture")}
                    helperText={t("siteUrlPage.siteUrlForm.cultureInputInfo")}
                    errors={validationErrors}
                />

                <SanFormControl
                    model={request}
                    onChange={setRequest}
                    name="default"
                    accepter={AccepterType.CHECKBOX}
                    label={t("siteUrlPage.siteUrlForm.default")}
                    placeholder={t("siteUrlPage.siteUrlForm.default")}
                    helperText={t("siteUrlPage.siteUrlForm.defaultInputInfo")}
                    errors={validationErrors}
                />

                <ButtonToolbar>
                    <Button appearance="primary" onClick={handleSubmit}>
                        {t("common.submit")}
                    </Button>
                </ButtonToolbar>
            </Form>
        </Fragment>
    )
}
export { SiteUrlForm };