import { useEffect, useRef, useState } from "react";
import { B2CApiServiceModels, B2CApiServices, B2CApiEnums, B2CUiModels, B2CUiEnums, B2CApiModels, B2CUiHelpers } from "@santsg/ui-component-core";
import { Module, Permission } from "models/enums/moduleAndPermission";
import SanAbility from "components/sanability/SanAbility";
import { enmActionCellType } from "models/enums/ActionCellType";
import { enmButtonAppearance } from "models/enums/ButtonAppearance";
import { enmButtonColor } from "models/enums/ButtonColor";
import { enmFilterType } from "models/enums/FilterType";
import { enmFilterInputShowType } from "models/enums/FilterInputShowType";
import SanPagination from "components/common/SanPagination";
import _ from 'lodash';
import { ObjectValueController } from "@santsg/ui-common";
import { useAppSelector } from "store/hooks";
import SanModal from "components/common/SanModal";
import { Stack } from "rsuite";
import SanPageTools from "components/common/SanPageTools";
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import SanTable from "components/common/SanTable";
import { SanColumnItem } from "models/types/san-elements/SanColumnItem";
import { SanActionWrapper } from "models/types/san-elements/SanActionWrapper";
import { SanFilterData } from "models/types/san-elements/SanFilterData";
import SanFilter from "components/common/SanFilter";
import { SanRemoveConfirmationProps } from "models/types/san-elements/SanRemoveConfirmationProps";
import SanRemoveConfirmation from "components/common/SanRemoveConfirmation";
import { SiteUrlFormProps } from "models/types/site-url/SiteUrlFormProps";
import { SiteUrlForm } from "./SiteUrlForm";
import { getIdNamePairAsArray } from "utils/functions/ObjectMapper";
import { SanDynamicObject } from "models/types/san-elements/SanDynamicObject";
import * as XLSX from 'xlsx';


function SiteUrlList(): JSX.Element {
    const settings = useAppSelector(state => state.settings);
    const [t] = useTranslation();
    const [listRequest, setListRequest] = useState<B2CApiServiceModels.Module.SiteUrl.SiteUrlListRequest>(new B2CApiServiceModels.Module.SiteUrl.SiteUrlListRequest());
    const [listResponse, setListResponse] = useState<B2CApiServiceModels.Module.SiteUrl.SiteUrlListResponse>();
    const [openEditModal, setOpenEditModal] = useState<boolean>(false);
    const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false);
    const [forceClearFilters, setForceClearFilters] = useState<boolean>(false);
    const [siteUrl, setSiteUrl] = useState<B2CApiModels.Module.SiteUrl.mdlSiteUrl>();
    const [removeSiteUrlId, setRemoveSiteUrlId] = useState<string>();
    const [cultures, setCultures] = useState<Array<B2CUiModels.mdlIdNameCodeValue>>();
    const siteCultures = useAppSelector(s => s.cultureInfo)?.cultures;

    const tableRef = useRef(null);

    useEffect(() => {
        if (cultures == null) {
            var pairs = getIdNamePairAsArray((siteCultures as SanDynamicObject[]), ["id", "name"]);
            setCultures(pairs);
        }

        if (listResponse == null)
            getSiteUrls();
    }, [listResponse]);


    //#region API CALLS
    async function getSiteUrls(request?: B2CApiServiceModels.Module.SiteUrl.SiteUrlListRequest) {
        let req = request ? request : listRequest;
        setListRequest(req);
        var response = await B2CApiServices.SiteUrlService.List(req, false, true);
        if (response && response.header.success && response.body)
            setListResponse(response.body);
        else {
            setListResponse(new B2CApiServiceModels.Module.SiteUrl.SiteUrlListResponse([]));
        }
        setForceClearFilters(false);
    }

    async function getSiteUrl(id: string) {
        var request = new B2CApiServiceModels.Module.SiteUrl.SiteUrlDetailRequest(id);
        var response = await B2CApiServices.SiteUrlService.Detail(request, false, true);
        if (response && response.header.success && response.body?.siteUrl) {
            setSiteUrl(response.body.siteUrl);
            setOpenEditModal(true);
        }
    }

    async function deleteSiteUrl() {
        if (removeSiteUrlId) {
            var response = await B2CApiServices.SiteUrlService.Delete(new B2CApiServiceModels.Module.SiteUrl.SiteUrlDeleteRequest(removeSiteUrlId), false, true);
            if (response && response.header.success && response.body && response.body.deleted) {
                toast.success(t("common.success"));
                getSiteUrls();
            }
            else {
                toast.error(response.header?.messages?.map(messageModel => messageModel.message).join('.'));
            }
        }
    }
    //#endregion

    //#region MODAL CONTROLS
    const handleEditModalClose = () => {
        setOpenEditModal(false);
    }

    const handleRemoveModalClose = () => {
        setOpenRemoveModal(false);
    }

    const handleRemoveModalConfirm = () => {
        deleteSiteUrl();
        setOpenRemoveModal(false);
    }
    //#endregion

    //#region EVENTS
    const handleAddNewClick = () => {
        setSiteUrl(undefined);
        setOpenEditModal(true);
    }

    const handleEditClick = (id: string) => {
        if (!ObjectValueController.isNullOrUndefinedOrEmpty(id)) {
            getSiteUrl(id);
        }
    };

    const handleRemoveClick = (id: string) => {
        setRemoveSiteUrlId(id);
        setOpenRemoveModal(true);
    }

    const handleFilterChange = (filterProp: B2CUiModels.mdlFilterProps) => {
        if (!ObjectValueController.isNullOrUndefinedOrEmpty(filterProp.key)) {
            var clonedRequest = _.cloneDeep(listRequest);
            clonedRequest = ({
                ...clonedRequest,
                [filterProp.key!]: filterProp.value
            });
            getSiteUrls(clonedRequest);
        }
    }

    const handleFilterClear = (key?: string) => {
        if (!ObjectValueController.isNullOrUndefinedOrEmpty(key)) {
            var clonedRequest = _.cloneDeep(listRequest);
            delete clonedRequest[key as keyof B2CApiServiceModels.Module.SiteUrl.SiteUrlListRequest];
            getSiteUrls(clonedRequest);
        } else {
            getSiteUrls(new B2CApiServiceModels.Module.SiteUrl.SiteUrlListRequest());
        }
    }

    const handlePagerChange = (pager?: B2CUiModels.mdlPagerProps) => {
        var req = _.cloneDeep(listRequest);
        req.pageNumber = pager?.pageNumber;
        req.pageSize = pager?.limit;
        getSiteUrls(req);
    }
    //#endregion

    //#region HELPERS
    const restorePageDefaults = () => {
        setForceClearFilters(true);
        setOpenEditModal(false);
    }

    const handleFormError = (messages: { message: string }[]) => {
        toast.error(messages.map(messageModel => messageModel.message).join('.'));
    }

    const handleFormSuccess = () => {
        restorePageDefaults();
        getSiteUrls();
        setOpenEditModal(false);
        toast.success(t("common.success"));
    }

    const handleExportToExcel = () => {
        const rowData = listResponse?.data ? listResponse?.data?.map(siteUrl => [B2CApiEnums.enmPageType[siteUrl.pageType], siteUrl.siteCulture?.name, siteUrl.urlPattern]) : [];
        if (rowData)
            B2CUiHelpers.ExportHelper.exportToExcel(XLSX, t("siteUrlPage.pageType"), [t("siteUrlPage.culture"), t("siteUrlPage.urlPattern")], rowData as string[][]);
    };

    const handleExportToPdf = () => {
        const content = tableRef.current;
        if (content) B2CUiHelpers.ExportHelper.exportToPdf((content as HTMLElement).outerHTML);
    };

    //#endregion

    //#region COMPONENT PROPS
    const tableColumns: SanColumnItem[] = [
        {
            key: "id",
            type: "string",
            label: "Id",
            visible: false
        },
        {
            key: "pageType",
            model: B2CApiEnums.enmPageType,
            type: "enum",
            label: "Page Type",
            visible: true
        },
        {
            key: "urlPattern",
            type: "string",
            label: "Pattern",
            visible: true
        },
        {
            key: "siteCulture.code",
            type: "string",
            label: "Culture",
            visible: true
        },
        {
            key: "default",
            type: Boolean,
            label: "Default",
            visible: true
        },
        {
            key: "modifyDate",
            type: Date,
            label: "Modify Date",
            visible: true
        }
    ];

    const tableActionWrapper: SanActionWrapper = {
        actionCellType: enmActionCellType.Row,
        actions: [
            {
                apperance: enmButtonAppearance.ButtonPrimary,
                color: enmButtonColor.Blue,
                label: "Edit",
                onClick: handleEditClick
            },
            {
                apperance: enmButtonAppearance.ButtonPrimary,
                color: enmButtonColor.Red,
                label: "Delete",
                onClick: handleRemoveClick
            }]
    };

    const filters: SanFilterData[] = [
        {
            type: enmFilterType.ENUMSELECT,
            dataKey: "pageType",
            label: t("siteUrlPage.byPageType"),
            model: B2CApiEnums.enmPageType
        },
        {
            type: enmFilterType.TEXT,
            dataKey: "urlPattern",
            label: t("siteUrlPage.byUrlPattern")
        },
        {
            type: enmFilterType.DATASELECT,
            dataKey: "siteCultureId",
            label: t("siteUrlPage.byCulture"),
            dataSource: siteCultures,
            valueKey: "name"
        }
    ];

    const siteUrlFormProps: SiteUrlFormProps = {
        errorCallback: handleFormError,
        successCallback: handleFormSuccess,
        siteUrl: siteUrl,
        cultures: cultures
    }
    //#endregion 

    return <SanAbility module={Module.SiteUrl} permissions={[Permission.Read]} errorState='ShowMessage'>
        {
            <>
                <div>
                    <SanPageTools
                        title={t("siteUrlPage.siteUrls")}
                        addNewFunction={handleAddNewClick}
                        addNewLabel={t("siteUrlPage.addNew")}
                        exportToExcelFunction={handleExportToExcel}
                        exportToExcelLabel={t("common.exportToExcel")}
                        exportToPdfFunction={handleExportToPdf}
                        exportToPdfLabel={t("common.exportToPdf")} />
                </div>
                <div>
                    <SanFilter forceClear={forceClearFilters} title={t("common.activeFilters")} onClearFilter={handleFilterClear} filters={filters} onChange={handleFilterChange} filterInputShowType={enmFilterInputShowType.SEPERATE} />
                </div>
                <div ref={tableRef}>
                    <SanTable data={listResponse?.data} tableColumns={tableColumns} tableActions={tableActionWrapper} />
                </div>
                <div>
                    <SanPagination totalCount={listResponse?.totalCount} resultCount={listResponse?.resultCount} activePage={listResponse?.pageNumber} activeLimit={listRequest.pageSize} onChange={handlePagerChange} />
                </div>
                <Stack>
                    <SanModal
                        backdrop={"static"}
                        type={settings?.panel.modalShowType}
                        open={openEditModal}
                        onClose={handleEditModalClose}
                        closeLabel="Close"
                        title={t("siteUrlPage.siteUrlForm.drawerTitle")}
                        body={SiteUrlForm}
                        bodyProps={siteUrlFormProps}
                    />
                </Stack>
                <Stack>
                    <SanModal
                        backdrop={"static"}
                        type={B2CUiEnums.enmModalShowType.Modal}
                        open={openRemoveModal}
                        onClose={handleRemoveModalClose}
                        closeLabel="Close"
                        bodyProps={{ callBack: handleRemoveModalConfirm, typingRequired: true } as SanRemoveConfirmationProps}
                        title={t("common.attentionRequired")}
                        body={SanRemoveConfirmation}
                    />
                </Stack>
            </>
        }
    </SanAbility>;
}

export default SiteUrlList;

