import { Checkbox } from "rsuite"
import _ from 'lodash';
import { ValueType } from "rsuite/esm/Checkbox";
import { Fragment, useEffect } from "react";
import { SanCheckboxInputProps } from "models/types/san-elements/SanCheckboxInputProps";

export const SanCheckboxInput = (props: SanCheckboxInputProps) => {
    useEffect(() => {
    }, [props])

    //#region EVENTS
    const handleOnChange = (value: ValueType | undefined, checked: boolean, event: any) => {
        props.onChange && props.onChange(checked);
    }
    //#endregion

    return (
        <Fragment>
            {
                <Checkbox
                    checked={props.value}
                    value={props.value}
                    disabled={props.readonly}
                    onChange={handleOnChange}
                />
            }
        </Fragment>

    )
}