import { ObjectValueController } from "@santsg/ui-common";
import {  B2CApiServiceModels } from "@santsg/ui-component-core";


export default function (editor, opt = {}) {
    const c = opt;
    let bm = editor.BlockManager;

    editor.DomComponents.addType('LayoutWrapper', {
        isComponent: el => el.tagName === 'LAYOUTWRAPPER',
        model: {
            defaults: {

                tagName: 'div',
                attributes: { layoutid: '' },
                traits: [
                    { label: 'Content Name', name: 'layoutid', type: 'contentautocomplete', changeProp: 1, default: '' },
                ],
                editable: true,
                draggable: true,
                droppable: true,
                removable: true
            },
            init() {
                this.on('change:attributes:layoutid', this.clearComponents);
            },
            clearComponents(e) {

                let attr = e.getAttributes();
                if (attr.layoutid != undefined && attr.layoutid != '') {
                    e.components().models = [];
                    e.replaceWith({ type: 'LayoutWrapper', attributes: { layoutid: attr.layoutid } });
                }
            },
        },
        view: {
            init() {
                let attrid = this.model.getAttributes().layoutid;
                if (!ObjectValueController.isNullOrUndefinedOrEmpty(attrid)) {
                    let req = new B2CApiServiceModels.Module.Layout.LayoutDetailRequest();
                    req.id = attrid;
                    let res = B2CApiServices.LayoutService.Detail(req, false, true);
                    this.model.components().models = [];
                    const cmpModel = this.model;
                    res.then(t => {
                        cmpModel.components().add(t.body.layout.components);
                        const updateAll = model => {
                            model.set({ editable: false, removable: false, selectable: false, highlightable: false });
                            model.get('components').each(model => updateAll(model));
                        }

                        cmpModel.components().forEach(element => {
                            updateAll(element);
                        });
                    });
                }
            }
        }
    })

    editor.DomComponents.addType('SanRow', {
        isComponent: el => el.tagName === 'SANROW',
        model: {
            defaults: {
                tagName: 'div',
                attributes: { class: 'row' },
                traits: {},
                draggable: '.container,.container-fluid,.column',
                droppable: '.col',
                removable: true
            },
        }
    })

    editor.DomComponents.addType('SanColumn', {
        isComponent: el => el.tagName === 'SANCOLUMN',
        model: {
            defaults: {
                tagName: 'div',
                attributes: { class: 'col column' },
                traits: {},
                draggable: true,//'.row',
                droppable: true,
                removable: false
            }
        }
    })

    editor.DomComponents.addType('SanContainer', {
        isComponent: el => el.tagName === 'SANCONTAINER',
        model: {
            defaults: {
                tagName: 'div',
                //propagate: ['editable', 'hoverable', 'selectable'],
                stylable: false,
                editable: false,
                draggable: 'body',
                droppable: '.row',
                traits: [{
                    label: 'fluid', name: 'fluid', type: 'checkbox', changeProp: 1, default: false
                }
                ],
                attributes: { class: 'container' },
                draggable: true,//'body,.row,.row *', // Can be dropped only inside `form` elements
                //droppable: '.row', // Can't drop other elements inside
            },
            updated(property, value, prevValue) {
                if (property == 'classes' && value.models.length == 0) {
                    this.addClass('container')
                }
            }
        },

        view: {
            init() {
                this.listenTo(this.model, 'change:fluid', this.handleChanges);
            },
            handleChanges(e) {
                this.render();
            },
            onRender({ el }) {
                let traits = this.model.getTraits();
                //this.model.set({editable:false});
                //this.model.set({propagate: ['editable', 'hoverable', 'selectable','removable']});
                let fluidAttr = traits.find(f => f.attributes.id == 'fluid').attributes;
                let isFluid = fluidAttr.value || fluidAttr.default;
                this.model.setAttributes({ 'fluid': isFluid });
                if (isFluid) {
                    this.model.setClass(['container-fluid']);
                } else {
                    this.model.setClass(['container']);
                }
            }
        }
    })
    editor.DomComponents.addType('SanDiv', {
        isComponent: el => el.tagName === 'SANDIV',
        model: {
            defaults: {
                tagName: 'div',
                //propagate: ['editable', 'hoverable', 'selectable'],
                stylable: false,
                editable: false,
                draggable: true,
                droppable: true,
                attributes: { class: 'sandiv' },
            }
        }
    })
    c.tabsBlock && bm.add(c.typeTabs, {
        media: `
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M22 9.3c0-.8-.5-1.3-1.3-1.3H3.4C2.5 8 2 8.5 2 9.3v7.4c0 .8.5 1.3 1.3 1.3h17.4c.8 0 1.3-.5 1.3-1.3V9.3zM21 17H3V9h18v8z" fill-rule="nonzero"/><rect x="3" y="5" width="4" height="2" rx=".5"/><rect x="8" y="5" width="4" height="2" rx=".5"/><rect x="13" y="5" width="4" height="2" rx=".5"/>
      </svg>
    `,
        label: 'Tabs',
        category: c.category,
        content: { type: c.typeTabs },
        ...c.tabsBlock
    });


    const getContainer = (colInfo) => {
        var colTypes = colInfo.split('_');
        var compStr = '';
        compStr += `<div data-gjs-type='SanRow'>`
        for (var il = 0; il < colTypes.length; il++) {
            compStr += `<div data-gjs-type='SanColumn' class="column col-md-${colTypes[il]}"></div>`;
        }
        compStr += `</div>`;
        return compStr;
    }
    bm.add('SanDiv', {
        label: 'Div',
        category: c.category,
        content: `<div data-gjs-type="SanDiv"></div>`
    })
    bm.add('Container', {
        label: 'Container',
        category: c.category,
        content: `<div data-gjs-type="SanContainer"></div>`
    })
    bm.add('Col-12', {
        label: 'Column:12',
        category: c.category,
        content: getContainer('12')

    })
    bm.add('Col-6-6', {
        label: 'Column:6-6',
        category: c.category,
        content: getContainer('6_6')
    })
    bm.add('Col-3-3-3-3', {
        label: 'Column:3-3-3-3',
        category: c.category,
        content: getContainer('3_3_3_3')
    })
    bm.add('Col-4-4-4', {
        label: 'Column:4-4-4',
        category: c.category,
        content: getContainer('4_4_4')
    })

    bm.add('Col-4-8', {
        label: 'Column:4-8',
        category: c.category,
        content: getContainer('4_8')
    })
    bm.add('Col-8-4', {
        label: 'Column:8-4',
        category: c.category,
        content: getContainer('8_4')
    })
    bm.add('ContentWrapper', {
        label: 'Content Wrapper',
        category: c.category,
        content: '<div data-gjs-type="LayoutWrapper" />'
    })


    // bm.add('text', {
    //     label: 'Text',
    //     category: c.category,
    //     attributes: { class: 'gjs-fonts gjs-f-text' },
    //     content: {
    //         type: 'text',
    //         content: 'Insert your text here',
    //         style: { padding: '10px' },
    //         activeOnRender: 1
    //     }
    // });

}