import styled from "styled-components";

const EditorDiv = styled.div`
  #.gjs-clm-tag.gjs-three-bg:first-child > .gjs-clm-tag-close{pointer-events:none;}
  .gjs-one-bg{
    background-color:var(--editor-background-color);
}
.gjs-mdl-dialog{background-color:#fff; min-height:300px;}
.select2-results__options {
  height: 180px;
  overflow: auto;
  list-style: none;
  padding-left: 0;
  width:90%;
} 
`;
export default EditorDiv
