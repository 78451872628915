import { Fragment, useEffect, useState } from "react";
import DangerIcon from '@rsuite/icons/Danger';
import { Button, Input, Stack } from "rsuite";
import { useTranslation } from "react-i18next";
import { SanRemoveConfirmationProps } from "models/types/san-elements/SanRemoveConfirmationProps";

const SanRemoveConfirmation = (props: SanRemoveConfirmationProps) => {
    const [removeConfirmationText, setRemoveConfirmationText] = useState<string>();
    const [errorText, setErrorText] = useState<string>();
    const confirmatinText = "delete";
    const [t] = useTranslation();

    useEffect(() => {

    }, [props])

    //#region EVENTS
    const handleRemoveConfirm = () => {
        if (props.typingRequired && props.callBack) {
            if (removeConfirmationText != confirmatinText) {
                setErrorText(t("common.deletePlaceholder"));
            }
            else {
                props.callBack();
            }
        }
        else if (props.callBack) {
            props.callBack();
        }
    }
    //#endregion

    return (
        <Fragment>
            <DangerIcon />
            <label>{t("removeConfirmation.infoText")}</label>
            <label>{t("removeConfirmation.undoneText")}</label>
            <h5>{t("removeConfirmation.confirmText")}</h5>
            {
                props.typingRequired
                &&
                <>
                    <Stack>
                        <Input placeholder="delete" onChange={setRemoveConfirmationText} />
                    </Stack>
                    <Stack>
                        <label>{errorText}</label>
                    </Stack>
                </>
            }
            <Stack>
                <Button onClick={handleRemoveConfirm} appearance="default" color="red">{t("common.confirm")}</Button>
            </Stack>
        </Fragment>
    )
}
export default SanRemoveConfirmation;