import { DateInput, DatePicker, MaskedInput } from "rsuite"
import _ from 'lodash';
import { useEffect, useState } from "react";
import moment from "moment";
import { SanDateInputProps } from "models/types/san-elements/SanDateInputProps";

export const SanDateInput = (props: SanDateInputProps) => {
    const [value, setValue] = useState<Date>();

    useEffect(() => {
        if (props.receiveFormat) {
            var convertedValue = moment(props.value, props.receiveFormat).toDate();
            setValue(convertedValue);
        }
        else {
            setValue(props.value);
        }
    }, [props])

    //#region EVENTS
    const handleOnChange = (value: Date | null, event: any) => {
        props.onChange && props.onChange(moment(value).utc(true).toDate());
    }
    //#endregion

    return (
        <DateInput
            value={value}
            placeholder={props.placeholder}
            disabled={props.readonly}
            format={props.format}
            onChange={handleOnChange}
        />
    )
}