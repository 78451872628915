import React, { useState } from "react";
import { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonToolbar, Form } from "rsuite";
import { B2CApiServiceModels, B2CApiServices } from "@santsg/ui-component-core";
import _ from 'lodash';
import { SanFormControl } from "components/common/SanFormControl";
import { ObjectValueController } from "@santsg/ui-common";
import { ValidationResult, getValidationResult } from "models/types/common/ValidationResult";
import { WebSiteFormProps } from "models/types/website/WebSiteFormProps";

const WebSiteForm = (props: WebSiteFormProps) => {
    const [request, setRequest] = React.useState<B2CApiServiceModels.Module.WebSite.WebSiteCreateRequest
        | B2CApiServiceModels.Module.WebSite.WebSiteUpdateRequest>({});
    const [t] = useTranslation();
    const [validationErrors, setValidationErrors] = useState<Array<ValidationResult>>([]);

    useEffect(() => {
        if (!props.webSite) {
            setRequest(new B2CApiServiceModels.Module.WebSite.WebSiteCreateRequest());
        } else {
            var pRequest = new B2CApiServiceModels.Module.WebSite.WebSiteUpdateRequest();
            pRequest.id = props.webSite.id;
            pRequest.domain = props.webSite.domain;
            pRequest.cmsDomain = props.webSite.cmsDomain;
            pRequest.name = props.webSite.name;
            pRequest.serviceUrl = props.webSite.serviceUrl;
            setRequest(pRequest);

        }
    }, [props])

    const handleSubmit = () => {
        setValidationErrors([]);
        if (checkValidation()) {
            saveWebSite();
        }
    }

    //#region HELPERS
    const checkValidation = () => {
        var messages = new Array<ValidationResult>();
        var result = false;
        if (ObjectValueController.isNullOrUndefinedOrEmpty(request.name)) {
            messages.push(getValidationResult(t, "name"));
        }
        if (ObjectValueController.isNullOrUndefinedOrEmpty(request.domain)) {
            messages.push(getValidationResult(t, "domain"));
        }
        if (ObjectValueController.isNullOrUndefinedOrEmpty(request.cmsDomain) && (props.webSite != null && props.webSite.isBaseSite)) {
            messages.push(getValidationResult(t, "cmsDomain"));
        }

        messages.length == 0 ? result = true : result = false;
        setValidationErrors(messages);

        return result;
    }

    //#endregion

    //#region API CALLS
    async function saveWebSite() {
        var response = props.webSite ? await B2CApiServices.WebSiteService.Update(request as B2CApiServiceModels.Module.WebSite.WebSiteUpdateRequest, false, true) :
            await B2CApiServices.WebSiteService.Create(request as B2CApiServiceModels.Module.WebSite.WebSiteCreateRequest, false, true);
        if (response && response.header.success && response.body) {
            props.successCallback && props.successCallback();
        }
        else {
            props.errorCallback ? props.errorCallback(response.header.messages) : console.log("failed");
        }
    }
    //#endregion

    return (
        <Fragment>
            <Form
                onChange={setRequest}>
                <SanFormControl
                    model={request}
                    onChange={setRequest}
                    name="name"
                    label={t("webSitePage.webSiteForm.name")}
                    type="text"
                    placeholder={t("webSitePage.webSiteForm.name")}
                    helperText={t("webSitePage.webSiteForm.nameInputInfo")}
                    errors={validationErrors}
                />

                <SanFormControl
                    model={request}
                    onChange={setRequest}
                    name="domain"
                    label={t("webSitePage.webSiteForm.domain")}
                    type="text"
                    placeholder={t("webSitePage.webSiteForm.domain")}
                    helperText={t("webSitePage.webSiteForm.domainInputInfo")}
                    errors={validationErrors}
                />

                {
                    props.webSite && props.webSite.isBaseSite
                    &&
                    <SanFormControl
                        model={request}
                        onChange={setRequest}
                        name="cmsDomain"
                        label={t("webSitePage.webSiteForm.cmsDomain")}
                        type="text"
                        placeholder={t("webSitePage.webSiteForm.cmsDomain")}
                        helperText={t("webSitePage.webSiteForm.cmsDomainInputInfo")}
                        errors={validationErrors}
                    />
                }

                {
                    props.webSite && props.webSite.isBaseSite
                    &&
                    <SanFormControl
                        model={request}
                        onChange={setRequest}
                        name="serviceUrl"
                        label={t("webSitePage.webSiteForm.serviceUrl")}
                        type="text"
                        placeholder={t("webSitePage.webSiteForm.serviceUrl")}
                        helperText={t("webSitePage.webSiteForm.serviceUrlInputInfo")}
                        errors={validationErrors}
                    />
                }

                <ButtonToolbar>
                    <Button appearance="primary" onClick={handleSubmit}>
                        {t("common.submit")}
                    </Button>
                </ButtonToolbar>
            </Form>
        </Fragment>
    )
}
export { WebSiteForm };