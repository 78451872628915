import ReactDOM from 'react-dom/client';
import React from 'react';
export default (editor) => {
    const domc = editor.DomComponents;
    const defType = domc.getType('default');
    const defModel = defType.model;
    const wrpChld = 'data-chld';
    var arrDomRender = [];

    domc.addType('react-component', {
        model: {
            toHTML(opts = {}) {
                return defModel.prototype.toHTML.call(this, {
                    ...opts,
                    tag: this.get('type')
                });
            }
        },
        view: {
            tagName: 'div',

            init() {
                const { model } = this;
                this.listenTo(model, 'change:attributes', this.render);
                this.listenTo(model.components(), 'add remove reset set', this.__upRender);
            },

            getChildrenContainer() {
                const { childrenContainer } = this;
                if (childrenContainer) return childrenContainer;

                this.childrenContainer = document.createElement('childc');

                return this.childrenContainer;
            },

            /**
             * We need this container to understand if the React component is able
             * to render children
             */
            // createReactChildWrap() {
            //     return React.createElement('span', { [wrpChld]: true });
            // },

            // createReactEl(cmp, props) {
            //     return React.createElement(cmp.props.component, props, this.createReactChildWrap());
            // },


            // mountReact(cmp, el) {
            //     //ReactDOM.hydrateRoot()
            //     var rootEl=ReactDOM.createRoot(el);
            //     rootEl.render(cmp);
            // },
            createReactChildWrap() {
                return React.createElement('span', { [wrpChld]: true });
            },

             createReactEl(cmp, props) {
                  return React.createElement(cmp, props, this.createReactChildWrap());
             },
            //  createReactEl(cmp, props) {
            //      return React.createElement(cmp.name,props,this.createReactChildWrap());
            //  },


            mountReact(cmp, el) {
                var existsRoot = arrDomRender.find(f => f.id == el.id);
                if (existsRoot == null) {
                    var rootEl = ReactDOM.createRoot(el);
                    arrDomRender.push({ "id": el.id, "rootDom": rootEl });
                    rootEl.render(cmp);
                } else
                    existsRoot.rootDom.render(cmp);

                // React.createElement()


                //ReactDOM.render(cmp,el);
                //ReactDOM.hydrateRoot(el,React.FC);
                //ReactDOM.hydrateRoot(el,this.fc(cmp.props.type));
                //React.createElement(cmp.props.componen,null,);
            },
            render() {
                const { model, el } = this;
                this.updateAttributes();
                this.renderChildren();
                var compModel = model.attributes;
                 const reactEl = React.createElement(compModel.component, {
                     ...compModel.attributes
                 });
                
                this.mountReact(reactEl, el);
                //this.createReactEl(reactEl,props.attributes);
                //console.log(reactEl);
                const chld = el.querySelector(`span[${wrpChld}]`);

                // If the container is found, the react component is able to render children
                if (chld) {
                    const chldCont = this.getChildrenContainer();
                    while (chldCont.firstChild) {
                        chld.appendChild(chldCont.firstChild);
                    }
                }

                return this;
            },

            __upRender() {
                clearTimeout(this._upr);
                this._upr = setTimeout(() => this.render());
            }
        }
    });
};
