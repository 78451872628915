import { SanTableProps } from "models/types/san-elements/SanTableProps";
import { useEffect } from "react";
import SanTreeTable from "./SanTreeTable";
import SanStandardTable from "./SanStandardTable";

function SanTable<T extends object>(props: SanTableProps<T>) {
    useEffect(() => {
    }, [props])

    return (
        <>
            {
                props.childSeperatorKey
                    ?
                    <SanTreeTable
                        childKey={props.childKey}
                        childModel={props.childModel}
                        childSeperatorKey={props.childSeperatorKey}
                        childTableActions={props.childTableActions}
                        childrenColumns={props.childrenColumns}
                        data={props.data}
                        tableActions={props.tableActions}
                        tableColumns={props.tableColumns}
                        useCollapse={props.useCollapse}
                        mainSelector={props.mainSelector}
                        mainModel={props.mainModel}
                    />
                    :
                    <SanStandardTable
                        data={props.data}
                        tableActions={props.tableActions}
                        tableColumns={props.tableColumns}
                    />
            }
        </>
    )
}

export default SanTable;