import { useEffect, useState } from "react";
import { B2CApiServiceModels, B2CApiServices, B2CApiEnums, B2CUiModels, B2CUiEnums, B2CApiModels } from "@santsg/ui-component-core";
import { Module, Permission } from "models/enums/moduleAndPermission";
import SanAbility from "components/sanability/SanAbility";
import { enmActionCellType } from "models/enums/ActionCellType";
import { enmButtonAppearance } from "models/enums/ButtonAppearance";
import { enmButtonColor } from "models/enums/ButtonColor";
import { enmFilterType } from "models/enums/FilterType";
import { enmFilterInputShowType } from "models/enums/FilterInputShowType";
import SanPagination from "components/common/SanPagination";
import _ from 'lodash';
import { ObjectValueController } from "@santsg/ui-common";
import { useAppSelector } from "store/hooks";
import SanModal from "components/common/SanModal";
import { Stack } from "rsuite";
import SanPageTools from "components/common/SanPageTools";
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import SanTable from "components/common/SanTable";
import { SanColumnItem } from "models/types/san-elements/SanColumnItem";
import { SanActionWrapper } from "models/types/san-elements/SanActionWrapper";
import { SanFilterData } from "models/types/san-elements/SanFilterData";
import SanFilter from "components/common/SanFilter";
import { SanRemoveConfirmationProps } from "models/types/san-elements/SanRemoveConfirmationProps";
import SanRemoveConfirmation from "components/common/SanRemoveConfirmation";
import { EventFormProps } from "models/types/event/EventFormProps";
import { EventForm } from "./EventForm";


function EventList(): JSX.Element {
    const settings = useAppSelector(state => state.settings);
    const [t] = useTranslation();
    const [listRequest, setListRequest] = useState<B2CApiServiceModels.Module.Event.EventListRequest>(new B2CApiServiceModels.Module.Event.EventListRequest());
    const [listResponse, setListResponse] = useState<B2CApiServiceModels.Module.Event.EventListResponse>();
    const [openEditModal, setOpenEditModal] = useState<boolean>(false);
    const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false);
    const [forceClearFilters, setForceClearFilters] = useState<boolean>(false);
    const [event, setEvent] = useState<B2CApiModels.Module.Event.mdlEvent>();
    const [removeEventId, setRemoveEventId] = useState<string>();

    useEffect(() => {
        if (listResponse == null)
            getEvents();
    }, [listResponse]);


    //#region API CALLS
    async function getEvents(request?: B2CApiServiceModels.Module.Event.EventListRequest) {
        let req = request ? request : listRequest;
        setListRequest(req);
        var response = await B2CApiServices.EventService.List(req, false, true);
        if (response && response.header.success && response.body)
            setListResponse(response.body);
        else {
            setListResponse(new B2CApiServiceModels.Module.Event.EventListResponse([]));
        }
        setForceClearFilters(false);
    }

    async function getEvent(id: string) {
        var request = new B2CApiServiceModels.Module.Event.EventDetailRequest();
        request.id = id;
        var response = await B2CApiServices.EventService.Detail(request, false, true);
        if (response && response.header.success && response.body?.event) {
            setEvent(response.body.event);
            setOpenEditModal(true);
        }
    }

    async function deleteEvent() {
        if (removeEventId) {
            var response = await B2CApiServices.EventService.Delete(new B2CApiServiceModels.Module.Event.EventDeleteRequest(removeEventId), false, true);
            if (response && response.header.success && response.body && response.body.deleted) {
                toast.success(t("common.success"));
                getEvents();
            }
            else {
                toast.error(response.header?.messages?.map(messageModel => messageModel.message).join('.'));
            }
        }
    }
    //#endregion

    //#region MODAL CONTROLS
    const handleEditModalClose = () => {
        setOpenEditModal(false);
    }

    const handleRemoveModalClose = () => {
        setOpenRemoveModal(false);
    }

    const handleRemoveModalConfirm = () => {
        deleteEvent();
        setOpenRemoveModal(false);
    }
    //#endregion

    //#region EVENTS
    const handleAddNewClick = () => {
        setEvent(undefined);
        setOpenEditModal(true);
    }

    const handleEditClick = (id: string) => {
        if (!ObjectValueController.isNullOrUndefinedOrEmpty(id)) {
            getEvent(id);
        }
    };

    const handleRemoveClick = (id: string) => {
        setRemoveEventId(id);
        setOpenRemoveModal(true);
    }

    const handleFilterChange = (filterProp: B2CUiModels.mdlFilterProps) => {
        if (!ObjectValueController.isNullOrUndefinedOrEmpty(filterProp.key)) {
            var clonedRequest = _.cloneDeep(listRequest);
            clonedRequest = ({
                ...clonedRequest,
                [filterProp.key!]: filterProp.value
            });
            getEvents(clonedRequest);
        }
    }

    const handleFilterClear = (key?: string) => {
        if (!ObjectValueController.isNullOrUndefinedOrEmpty(key)) {
            var clonedRequest = _.cloneDeep(listRequest);
            delete clonedRequest[key as keyof B2CApiServiceModels.Module.Event.EventListRequest];
            getEvents(clonedRequest);
        } else {
            getEvents(new B2CApiServiceModels.Module.Event.EventListRequest());
        }
    }

    const handlePagerChange = (pager?: B2CUiModels.mdlPagerProps) => {
        var req = _.cloneDeep(listRequest);
        req.pageNumber = pager?.pageNumber;
        req.pageSize = pager?.limit;
        getEvents(req);
    }
    //#endregion

    //#region HELPERS
    const restorePageDefaults = () => {
        setForceClearFilters(true);
        setOpenEditModal(false);
    }

    const handleFormError = (messages: { message: string }[]) => {
        toast.error(messages.map(messageModel => messageModel.message).join('.'));
    }

    const handleFormSuccess = () => {
        restorePageDefaults();
        getEvents();
        setOpenEditModal(false);
        toast.success(t("common.success"));
    }

    const handleExportToExcel = () => {
    }

    const handleExportToPdf = () => {
    }

    //#endregion

    //#region COMPONENT PROPS
    const tableColumns: SanColumnItem[] = [
        {
            key: "id",
            type: "string",
            label: "Id",
            visible: false
        },
        {
            key: "eventTriggerType",
            type: "enum",
            label: "Event Trigger Type",
            model: B2CApiEnums.enmEventTriggerType,
            visible: true
        },
        {
            key: "eventActionType",
            type: "enum",
            label: "Event Action Type",
            model: B2CApiEnums.enmEventActionType,
            visible: true
        },
        {
            key: "eventTargetType",
            type: "enum",
            label: "Event Target Type",
            model: B2CApiEnums.enmEventTargetType,
            visible: true
        },
        {
            key: "modifyDate",
            type: Date,
            label: "Modify Date",
            visible: true
        }
    ];

    const tableActionWrapper: SanActionWrapper = {
        actionCellType: enmActionCellType.Row,
        actions: [
            {
                apperance: enmButtonAppearance.ButtonPrimary,
                color: enmButtonColor.Blue,
                label: "Edit",
                onClick: handleEditClick
            },
            {
                apperance: enmButtonAppearance.ButtonPrimary,
                color: enmButtonColor.Red,
                label: "Delete",
                onClick: handleRemoveClick
            }]
    };

    const filters: SanFilterData[] = [
        {
            type: enmFilterType.ENUMSELECT,
            dataKey: "eventTriggerType",
            label: t("eventPage.byEventTriggerType"),
            model: B2CApiEnums.enmEventTriggerType
        },
        {
            type: enmFilterType.ENUMSELECT,
            dataKey: "eventActionType",
            label: t("eventPage.byEventActionType"),
            model: B2CApiEnums.enmEventActionType
        },
        {
            type: enmFilterType.ENUMSELECT,
            dataKey: "eventTargetType",
            label: t("eventPage.byEventTargetType"),
            model: B2CApiEnums.enmEventTargetType
        },
    ];

    const eventFormProps: EventFormProps = {
        errorCallback: handleFormError,
        successCallback: handleFormSuccess,
        event: event
    }
    //#endregion 

    return <SanAbility module={Module.Event} permissions={[Permission.Read]} errorState='ShowMessage'>
        {
            <>
                <div>
                    <SanPageTools
                        title={t("eventPage.events")}
                        addNewFunction={handleAddNewClick}
                        addNewLabel={t("eventPage.addNew")}
                        exportToExcelFunction={handleExportToExcel}
                        exportToExcelLabel={t("common.exportToExcel")}
                        exportToPdfFunction={handleExportToPdf}
                        exportToPdfLabel={t("common.exportToPdf")} />
                </div>
                <div>
                    <SanFilter forceClear={forceClearFilters} title={t("common.activeFilters")} onClearFilter={handleFilterClear} filters={filters} onChange={handleFilterChange} filterInputShowType={enmFilterInputShowType.SEPERATE} />
                </div>
                <div>
                    <SanTable data={listResponse?.data} tableColumns={tableColumns} tableActions={tableActionWrapper} />
                </div>
                <div>
                    <SanPagination totalCount={listResponse?.totalCount} resultCount={listResponse?.resultCount} activePage={listResponse?.pageNumber} activeLimit={listRequest.pageSize} onChange={handlePagerChange} />
                </div>
                <Stack>
                    <SanModal
                        backdrop={"static"}
                        type={settings?.panel.modalShowType}
                        open={openEditModal}
                        onClose={handleEditModalClose}
                        closeLabel="Close"
                        title={t("eventPage.eventForm.drawerTitle")}
                        body={EventForm}
                        bodyProps={eventFormProps}
                    />
                </Stack>
                <Stack>
                    <SanModal
                        backdrop={"static"}
                        type={B2CUiEnums.enmModalShowType.Modal}
                        open={openRemoveModal}
                        onClose={handleRemoveModalClose}
                        closeLabel="Close"
                        bodyProps={{ callBack: handleRemoveModalConfirm, typingRequired: true } as SanRemoveConfirmationProps}
                        title={t("common.attentionRequired")}
                        body={SanRemoveConfirmation}
                    />
                </Stack>
            </>
        }
    </SanAbility>;
}

export default EventList;

