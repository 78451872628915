import { IRouteV2 } from "interfaces/IRouteV2";
import { Module, Permission } from "models/enums/moduleAndPermission";
import EventList from "modules/event/EventList";
import ReceiverList from "modules/receiver/ReceiverList";
import React from "react";
import DataSourceListGroupList from "modules/datasource/DataSourceListGroupList";
import DataSourceFormTemplateList from "modules/datasource/DataSourceFormTemplateList";
import WebSiteList from "modules/website/WebSiteList";
import SiteUrlList from "modules/site-url/SiteUrlList";
import LocationParameterList from "modules/location-parameters/LocationParameterList";

const Dashboard = React.lazy(() => import("pages/Dashboard"));
const StaticPageDefinitionList = React.lazy(() => import("modules/page/static/StaticPageDefinitionList"));
const DynamicPageDefinitionList = React.lazy(() => import("modules/page/dynamic/DynamicPageDefinitionList"));
const UserList = React.lazy(() => import("modules/user/user/UserList"));
const RoleList = React.lazy(() => import("modules/user/role/RoleList"));
const PolicyList = React.lazy(() => import("modules/user/role-policy/PolicyList"));
const CompositeComponentList = React.lazy(() => import("modules/compositecomponent/CompositComponentList"));
const TemplateList = React.lazy(() => import("modules/template/TemplateList"));
const ComponentList = React.lazy(() => import("modules/component/ComponentList"));
const MenuList = React.lazy(() => import("modules/menu/MenuList"));
const LocalizationList = React.lazy(() => import("modules/localization/LocalizationList"));
const ContentList = React.lazy(() => import("modules/content/ContentDefinitionList"));
const SiteCultureList = React.lazy(() => import("modules/site-culture/SiteCultureList"));
const AppTaskList = React.lazy(() => import("modules/app-task/AppTaskList"));
const AppFileList = React.lazy(() => import("modules/app-file/AppFileList"));
const GalleryList = React.lazy(() => import("modules/gallery/GalleryList"));
const SiteSettingList = React.lazy(() => import("modules/site-setting/SiteSettingList"));

interface IRouteParam {
    name: string,
    value: string
}

const replaceUlrPattern = (path: string, prms: IRouteParam[]): string => {
    prms.forEach(p => {
        path = path.replaceAll(p.name, p.value);
    })
    return path;
}

export const allRoutes: Array<IRouteV2> = [
    {
        component: Dashboard,
        permissions: [Permission.Read],
        module: Module.Page,
        path: "/",
        getPath: () => "/",
    },
    {
        component: StaticPageDefinitionList,
        path: "/static-pages",
        permissions: [Permission.Read],
        module: Module.Page,
        getPath: () => "/static-pages",
    },
    {
        component: DynamicPageDefinitionList,
        path: "/dynamic-pages",
        permissions: [Permission.Read],
        module: Module.Page,
        getPath: () => "/dynamic-pages",
    },
    {
        component: UserList,
        path: "/users",
        permissions: [Permission.Read],
        module: Module.User,
        getPath: () => "/users",
    },
    {
        component: PolicyList,
        path: "/policies",
        permissions: [Permission.Read],
        module: Module.User,
        getPath: () => "/policies",
    },
    {
        component: RoleList,
        path: "/roles",
        permissions: [Permission.Read],
        module: Module.User,
        getPath: () => "/roles",
    },
    {
        component: EventList,
        path: "/events",
        permissions: [Permission.Read],
        module: Module.Event,
        getPath: () => "/events",
    },
    {
        component: ReceiverList,
        path: "/receivers",
        permissions: [Permission.Read],
        module: Module.Receiver,
        getPath: () => "/receivers",
    },
    {
        component: CompositeComponentList,
        path: "/composit-components",
        permissions: [Permission.Read],
        module: Module.CompositComponent,
        getPath: () => "/composit-components",
    },
    {
        component: TemplateList,
        path: "/templates",
        permissions: [Permission.Read],
        module: Module.Template,
        getPath: () => "/templates"
    },
    {
        component: ComponentList,
        path: "/components",
        permissions: [Permission.Read],
        module: Module.Component,
        getPath: () => "/components"
    },
    {
        component: DataSourceListGroupList,
        path: "/data-source-list-groups",
        permissions: [Permission.Read],
        module: Module.DataSource,
        getPath: () => "/data-source-list-groups"
    },
    {
        component: DataSourceFormTemplateList,
        path: "/data-source-form-templates",
        permissions: [Permission.Read],
        module: Module.DataSource,
        getPath: () => "/data-source-form-templates"
    },
    {
        component: ContentList,
        path: "/contents",
        permissions: [Permission.Read],
        module: Module.Content,
        getPath: () => "/contents"

    },
    {
        component: MenuList,
        path: "/menus",
        permissions: [Permission.Read],
        module: Module.Menu,
        getPath: () => "/menus"
    },
    {
        component: LocalizationList,
        path: "/localizations",
        permissions: [Permission.Read],
        getPath: () => "/localizations"
    },
    {
        component: SiteCultureList,
        path: "/site-cultures",
        permissions: [Permission.Read],
        module: Module.SiteCulture,
        getPath: () => "/site-cultures",
    },
    {
        component: SiteUrlList,
        path: "/site-urls",
        permissions: [Permission.Read],
        module: Module.SiteUrl,
        getPath: () => "/site-urls",
    },
    {
        component: WebSiteList,
        path: "/web-sites",
        permissions: [Permission.Read],
        module: Module.WebSite,
        getPath: () => "/web-sites",
    },
    {
        component: LocationParameterList,
        path: "/location-parameters",
        permissions: [Permission.Read],
        module: Module.LocationParameter,
        getPath: () => "/location-parameters",
    },
    {
        component: AppTaskList,
        path: "/app-tasks",
        permissions: [Permission.Read],
        module: Module.Task,
        getPath: () => "/app-tasks",
    },
    {
        component: AppFileList,
        path: "/app-files",
        permissions: [Permission.Read],
        module: Module.File,
        getPath: () => "/app-files",
    },
    {
        component: GalleryList,
        path: "/galleries",
        permissions: [Permission.Read],
        module: Module.Gallery,
        getPath: () => "/galleries"
    },
    {
        component: SiteSettingList,
        path: "/site-settings",
        permissions: [Permission.Read],
        module: Module.Setting,
        getPath: () => "/site-settings"
    },
]