import { Input, InputNumber } from "rsuite"
import _ from 'lodash';
import { SanNumberBoxInputProps } from "models/types/san-elements/SanNumberBoxInputProps";

export const SanNumberBoxInput = (props: SanNumberBoxInputProps) => {

    //#region EVENTS
    const handleOnChange = (value: string | number | null) => {
        props.onChange && props.onChange(value);
    }
    //#endregion

    return (
        <InputNumber
            disabled={props.readonly}
            value={props.value}
            placeholder={props.placeholder}
            onChange={handleOnChange}
            min={1}
        />
    )
}