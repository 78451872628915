import { ValidationResult, getValidationResult } from 'models/types/common/ValidationResult';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonToolbar, Form } from 'rsuite';
import { B2CApiEnums, B2CApiServiceModels, B2CApiServices, B2CUiHelpers } from '@santsg/ui-component-core';
import { SanFormControl } from 'components/common/SanFormControl';
import * as Common from "@santsg/ui-common"
import { CustomPageDefinitionFormProps } from 'models/types/page/custom/CustomPageDefinitionFormProps';
import { AccepterType } from 'models/enums/FormAccepterTypes';

const CustomPageDefinitionForm = (props: CustomPageDefinitionFormProps) => {
    const { t } = useTranslation();
    const [request, setRequest] = useState<B2CApiServiceModels.Module.Page.PageDefinitionCreateRequest | B2CApiServiceModels.Module.Page.PageDefinitionUpdateRequest>();
    const [validationErrors, setValidationErrors] = useState<Array<ValidationResult>>([]);
    const pageTypes = B2CUiHelpers.EnumHelper.getEnumKeyValues(B2CApiEnums.enmPageType);

    useEffect(() => {
        if (!props.pageDefinition) {
            var cRequest = new B2CApiServiceModels.Module.Page.PageDefinitionCreateRequest();
            cRequest.additionalId = props.additionalId;
            setRequest(cRequest);
        } else {
            var pRequest = new B2CApiServiceModels.Module.Page.PageDefinitionUpdateRequest();
            pRequest.id = props.pageDefinition.id;
            pRequest.name = props.pageDefinition.name;
            pRequest.pageType = props.pageDefinition.pageType
            pRequest.additionalId = props.pageDefinition.additionalId;
            setRequest(pRequest);
        }
    }, []);

    const checkValidation = () => {
        var messages = new Array<ValidationResult>();
        var result = false;
        if (Common.ObjectValueController.isNullOrUndefinedOrEmpty(request?.name)) {
            messages.push(getValidationResult(t, "name"));
        }
        if (Common.ObjectValueController.isNullOrUndefinedOrEmpty(request?.additionalId)) {
            messages.push(getValidationResult(t, "additionalId"));
        }
        if (Common.ObjectValueController.isNullOrUndefinedOrEmpty(request?.pageType)) {
            messages.push(getValidationResult(t, "pageType"));
        }
        if (!Common.ObjectValueController.isNullOrUndefinedOrEmpty(request?.name) && !Common.StringHelper.checkSupportedCharacters(request?.name!)) {
            messages.push(getValidationResult(t, "name", t("common.notSupportedCharacters")));
        }
        messages.length == 0 ? result = true : result = false;
        setValidationErrors(messages);
        return result;
    }
    const fnHandleSubmit = async () => {
        setValidationErrors([]);
        if (!request) return;
        if (!checkValidation()) return;
        var response = props.pageDefinition ? await B2CApiServices.PageService.DefinitionService.Update(request as B2CApiServiceModels.Module.Page.PageDefinitionUpdateRequest, false, true) :
            await B2CApiServices.PageService.DefinitionService.Create(request as B2CApiServiceModels.Module.Page.PageDefinitionCreateRequest, false, true);
        if (response && response.header.success && response.body) {
            props.successCallback && props.successCallback();
        }
        else {
            props.errorCallback ? props.errorCallback(response.header.messages) : console.log("failed");
        }
    };

    return (
        <Form>
            <SanFormControl
                model={request}
                onChange={setRequest}
                name="name"
                type='string'
                label={t("sitePage.pageForm.name")}
                placeholder={t("sitePage.pageForm.name")}
                helperText={t("sitePage.pageForm.nameInputInfo")}
                errors={validationErrors}
            />

            <SanFormControl
                model={request}
                onChange={setRequest}
                name="pageType"
                dataSource={pageTypes}
                accepter={AccepterType.DROPDOWN}
                placeholder={t("sitePage.pageForm.pageType")}
                label={t("sitePage.pageForm.pageType")}
                helperText={t("sitePage.pageForm.pageTypeInputInfo")}
                errors={validationErrors}
            />

            <ButtonToolbar>
                <Button appearance="primary" onClick={fnHandleSubmit}>
                    {t("common.submit")}
                </Button>
            </ButtonToolbar>
        </Form>
    );
};

export default CustomPageDefinitionForm;
