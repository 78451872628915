import {B2C} from '@santsg/ui-component-core';
export default (editor)=> {
    let bm = editor.BlockManager;
    editor.DomComponents.addType('LayoutPlaceHolder', {
        isComponent: el => el.tagName === 'LAYOUTPLACEHOLDER',
        model: {
            defaults: {
                tagName: 'div',
                classes:'container',
                stylable:false,
                editable:true,
                droppable:true,
                draggable: '.row *', // Can be dropped only inside `form` elements
                attributes: { class: 'container',fluid:'asda' },
                traits: [{
                    label: 'locationInfo', name: 'fluid', type: 'locationautocomplete'
                }
                ],
            }
            
        },
        
        view: {
            init() {
            },
            handleChanges(e) {
                this.render();
            },

            onRender({ el }) {
                //this.model.attributes.pagetype=
            }
        }
    })
    // editor.DomComponents.addType('SanTabPanel', {
    //     isComponent: el => el.tagName === 'SANTABPANEL',
    //     model: {
    //         defaults: {
    //             tagName: 'div',
    //             classes:'container',
    //             stylable:false,
    //             editable:true,
    //             droppable:true,
    //             draggable: '.row *', // Can be dropped only inside `form` elements
    //             attributes: { class: 'san-tab-panel' },
    //             traits: [
    //             ],
    //         }
            
    //     },
        
    //     view: {
    //         init() {
    //         },
    //         handleChanges(e) {
    //             this.render();
    //         },

    //         onRender({ el }) {
    //             //this.model.attributes.pagetype=
    //         }
    //     }
    // })
    bm.add('PageContainer', {
        label: 'Page Containers',
        category:{id:'Layout Placeholder',label:'Layout Placeholder',open:false},
        content: `<div data-gjs-type="LayoutPlaceHolder" data-gjs-pagetype="MasterPage" />`
    })
    // bm.add('SanTabPanel', {
    //     label: 'Tab Panel',
    //     category: 'Containers',
    //     content: `<div data-gjs-type="SanTabPanel" data-gjs-pagetype="MasterPage" />`
    // })

    // editor.Components.addType('SanHtml', {
    //     extend: 'react-component',
    //     model: {
    //       defaults: {
    //         component: B2CUiComponents.Common.SanHtml,
    //         stylable: true,
    //         resizable: true,
    //         editable: true,
    //         draggable: true,
    //         droppable: true,
    //         attributes: {
    //           htmlString:''
    //         },
    //         traits: []
    //       }
    //     },
    //     isComponent: (el) => el.tagName === 'SANHTML'
    //   });
    //   editor.BlockManager.add('SanHtml', {
    //     label: "<div class='gjs-fonts gjs-f-b1'>Wrapper</div>",
    //     category: 'Wrapper',
    //     content: '<SanHtml>Test</SanHtml>'
    //   });
    
}