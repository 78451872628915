import { useEffect, useState } from "react";
import { B2CApiServiceModels, B2CApiServices, B2CApiEnums, B2CUiModels, B2CUiEnums, B2CApiModels } from "@santsg/ui-component-core";
import { Module, Permission } from "models/enums/moduleAndPermission";
import SanAbility from "components/sanability/SanAbility";
import { enmActionCellType } from "models/enums/ActionCellType";
import { enmButtonAppearance } from "models/enums/ButtonAppearance";
import { enmButtonColor } from "models/enums/ButtonColor";
import { enmFilterType } from "models/enums/FilterType";
import { enmFilterInputShowType } from "models/enums/FilterInputShowType";
import SanPagination from "components/common/SanPagination";
import _ from 'lodash';
import { ObjectValueController } from "@santsg/ui-common";
import { useAppSelector } from "store/hooks";
import SanModal from "components/common/SanModal";
import { Stack } from "rsuite";
import SanPageTools from "components/common/SanPageTools";
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import SanTable from "components/common/SanTable";
import { SanColumnItem } from "models/types/san-elements/SanColumnItem";
import { SanActionWrapper } from "models/types/san-elements/SanActionWrapper";
import { SanFilterData } from "models/types/san-elements/SanFilterData";
import SanFilter from "components/common/SanFilter";
import { SanRemoveConfirmationProps } from "models/types/san-elements/SanRemoveConfirmationProps";
import SanRemoveConfirmation from "components/common/SanRemoveConfirmation";
import { DataSourceFormItemFormProps } from "models/types/datasource/DataSourceFormItemFormProps";
import { DataSourceFormItemForm } from "./DataSourceFormItemForm";
import { DataSourceFormValueListProps } from "models/types/datasource/DataSourceFormValueListProps";
import { DataSourceFormValueFormProps } from "models/types/datasource/DataSourceFormValueFormProps";
import { DataSourceFormValueForm } from "./DataSourceFormValueForm";


function DataSourceFormValueList(props: DataSourceFormValueListProps): JSX.Element {
    const settings = useAppSelector(state => state.settings);
    const [t] = useTranslation();
    const [listRequest, setListRequest] = useState<B2CApiServiceModels.Module.DataSource.DataSourceFormValue.DataSourceFormValueListRequest>(new B2CApiServiceModels.Module.DataSource.DataSourceFormValue.DataSourceFormValueListRequest());
    const [listResponse, setListResponse] = useState<B2CApiServiceModels.Module.DataSource.DataSourceFormValue.DataSourceFormValueListResponse>();
    const [openEditModal, setOpenEditModal] = useState<boolean>(false);
    const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false);
    const [forceClearFilters, setForceClearFilters] = useState<boolean>(false);
    const [dataSourceFormValue, setDataSourceFormValue] = useState<B2CApiModels.Module.DataSource.mdlDataSourceFormValue>();
    const [removeDataSourceId, setRemoveDataSourceId] = useState<string>();

    useEffect(() => {
        if (listResponse == null)
            getList();
    }, [listResponse]);


    //#region API CALLS
    async function getList(request?: B2CApiServiceModels.Module.DataSource.DataSourceFormValue.DataSourceFormValueListRequest) {
        let req = request ? request : listRequest;
        req.formTemplateId = props.dataSourceFormTemplate?.id;
        setListRequest(req);
        var response = await B2CApiServices.DataSourceService.DataSourceFormValue.List(req, false, true);
        if (response && response.header.success && response.body)
            setListResponse(response.body);
        else {
            setListResponse(new B2CApiServiceModels.Module.DataSource.DataSourceFormValue.DataSourceFormValueListResponse([]));
        }
        setForceClearFilters(false);
    }

    async function getDetail(id: string) {
        var request = new B2CApiServiceModels.Module.DataSource.DataSourceFormValue.DataSourceFormValueDetailRequest();
        request.id = id;
        var response = await B2CApiServices.DataSourceService.DataSourceFormValue.Detail(request, false, true);
        if (response && response.header.success && response.body?.dataSourceFormValue) {
            setDataSourceFormValue(response.body.dataSourceFormValue);
            setOpenEditModal(true);
        }
    }

    async function remove() {
        if (removeDataSourceId) {
            var response = await B2CApiServices.DataSourceService.DataSourceFormValue.Delete(new B2CApiServiceModels.Module.DataSource.DataSourceFormValue.DataSourceFormValueDeleteRequest(removeDataSourceId), false, true);
            if (response && response.header.success && response.body && response.body.deleted) {
                toast.success(t("common.success"));
                getList(new B2CApiServiceModels.Module.DataSource.DataSourceFormValue.DataSourceFormValueListRequest());
            }
            else {
                toast.error(response.header?.messages?.map(messageModel => messageModel.message).join('.'));
            }
        }
    }
    //#endregion

    //#region MODAL CONTROLS
    const handleEditModalClose = () => {
        setOpenEditModal(false);
    }

    const handleRemoveModalClose = () => {
        setOpenRemoveModal(false);
    }

    const handleRemoveModalConfirm = () => {
        remove();
        setOpenRemoveModal(false);
    }
    //#endregion

    //#region EVENTS
    const handleAddNewClick = () => {
        setDataSourceFormValue(undefined);
        setOpenEditModal(true);
    }

    const handleEditClick = (id: string) => {
        if (!ObjectValueController.isNullOrUndefinedOrEmpty(id)) {
            getDetail(id);
        }
    };

    const handleRemoveClick = (id: string) => {
        var requestedSource = _.find(listResponse?.data, function (r) { return r.id == id });
        if (requestedSource != null) {
            setRemoveDataSourceId(id);
            setOpenRemoveModal(true);
        }
    }

    const handlePagerChange = (pager?: B2CUiModels.mdlPagerProps) => {
        var req = _.cloneDeep(listRequest);
        req.pageNumber = pager?.pageNumber;
        req.pageSize = pager?.limit;
        getList(req);
    }
    //#endregion

    //#region HELPERS
    const restorePageDefaults = () => {
        setForceClearFilters(true);
        setOpenEditModal(false);
    }

    const handleFormError = (messages: { message: string }[]) => {
        toast.error(messages.map(messageModel => messageModel.message).join('.'));
    }

    const handleFormSuccess = () => {
        restorePageDefaults();
        getList();
        setOpenEditModal(false);
        toast.success(t("common.success"));
    }

    const handleExportToExcel = () => {
    }

    const handleExportToPdf = () => {
    }

    //#endregion

    //#region COMPONENT PROPS
    const tableColumns: SanColumnItem[] = [
        {
            key: "id",
            type: "string",
            label: "Id",
            visible: true
        }
    ];

    const tableActionWrapper: SanActionWrapper = {
        actionCellType: enmActionCellType.Row,
        actions: [
            {
                apperance: enmButtonAppearance.ButtonPrimary,
                color: enmButtonColor.Blue,
                label: "Edit",
                onClick: handleEditClick
            },
            {
                apperance: enmButtonAppearance.ButtonPrimary,
                color: enmButtonColor.Red,
                label: "Delete",
                onClick: handleRemoveClick
            }]
    };

    const dataSourceFormValueFormProps: DataSourceFormValueFormProps = {
        errorCallback: handleFormError,
        successCallback: handleFormSuccess,
        dataSourceFormTemplate: props.dataSourceFormTemplate,
        dataSourceFormValue: dataSourceFormValue
    }
    //#endregion 

    return <SanAbility module={Module.DataSource} permissions={[Permission.Read]} errorState='ShowMessage'>
        {
            <>
                <div>
                    <SanPageTools
                        title={props.inDrawer ? undefined : t("dataSourceFormValuePage.dataSourceFormValues")}
                        exportToExcelFunction={handleExportToExcel}
                        exportToExcelLabel={t("common.exportToExcel")}
                        exportToPdfFunction={handleExportToPdf}
                        exportToPdfLabel={t("common.exportToPdf")} />
                </div>
                <div>
                    <SanTable data={listResponse?.data} tableColumns={tableColumns} tableActions={tableActionWrapper} />
                </div>
                <div>
                    <SanPagination totalCount={listResponse?.totalCount} resultCount={listResponse?.resultCount} activePage={listResponse?.pageNumber} activeLimit={listRequest.pageSize} onChange={handlePagerChange} />
                </div>
                <Stack>
                    <SanModal
                        backdrop={"static"}
                        type={settings?.panel.modalShowType}
                        open={openEditModal}
                        onClose={handleEditModalClose}
                        closeLabel="Close"
                        title={t("dataSourceFormValuePage.dataSourceFormValueForm.drawerTitle")}
                        body={DataSourceFormValueForm}
                        bodyProps={dataSourceFormValueFormProps}
                    />
                </Stack>
                <Stack>
                    <SanModal
                        backdrop={"static"}
                        type={B2CUiEnums.enmModalShowType.Modal}
                        open={openRemoveModal}
                        onClose={handleRemoveModalClose}
                        closeLabel="Close"
                        bodyProps={{ callBack: handleRemoveModalConfirm, typingRequired: true } as SanRemoveConfirmationProps}
                        title={t("common.attentionRequired")}
                        body={SanRemoveConfirmation}
                    />
                </Stack>
            </>
        }
    </SanAbility>;
}

export default DataSourceFormValueList;

