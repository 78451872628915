export enum AccepterType {
    DROPDOWN = 1,
    CHECKBOXPICKER = 2,
    PHONEINPUT = 3,
    TEXTAREA = 4,
    DATEPICKER = 5,
    CHECKBOX = 6,
    AUTOCOMPLETE = 7,
    DATEINPUT = 8,
    JSONEDITOR = 9,
    NUMBERBOX = 10,
    UPLOADER = 11,
    LABEL = 12
}