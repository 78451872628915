import React, { useState, useEffect } from 'react';
import GrapesJS from 'grapesjs';
import 'grapesjs/dist/css/grapes.min.css';
import 'summernote/dist/summernote-lite.min.css';
import BaseReactComponents from './plugins/basereactcomponents';
import CustomTraits from './plugins/customtraits';
import SanComponents from './plugins/sancomponents';
import CompositComponents from './plugins/compositComponents';
import { useAppSelector } from 'store/hooks';
import BasicComponentPlg from './plugins';
import PageContainers from './plugins/pagecontainers';
import EditorDiv from './component.styled';
import GrapesJSButtons from './grapesJSButtons';

function GrapesjsEditor(props) {
    const { fncSaveEditorData, onlySettings = false, params } = props;
    const auth = useAppSelector(state => state.auth);
    const [editor, setEditor] = useState(null);
    const [editorData, setEditorData] = useState(null);
    const pathUrl = `${auth.user.targetWebSite ?? 'http://localhost:3001/'}assets/`;
    var plugins = [
        BasicComponentPlg,
        BaseReactComponents,
        CustomTraits,
        SanComponents,
        PageContainers,
        CompositComponents
    ];
    useEffect(() => {
        if (props.editorData && Array.isArray(props.editorData.components)) {
            createEditor();
        }
    }, [props.editorData]);

    useEffect(() => {
        if (editor && editorData && Array.isArray(editorData.components)) {
            editor.getWrapper().view?.$el.empty();
            editor.Components.clear();
            editor.setComponents(editorData.components);

            let ctrlCheckType = false;
            const checkType = (model, type) => {
                if (model.attributes.type === type) {
                    ctrlCheckType = model.get('components').length > 0;
                } else if (!ctrlCheckType) {
                    model.get('components').each(m => checkType(m, type));
                }
            };

            const updateAll = (model) => {
                if (model.attributes.type !== 'LayoutPlaceHolder') {
                    model.set({ editable: false, removable: false, selectable: false });
                    model.get('components').each(updateAll);
                } else {
                    model.set({ removable: false });
                }
            };

            editor.getComponents().forEach(element => {
                if (!ctrlCheckType) checkType(element, 'LayoutPlaceHolder');
            });

            if (ctrlCheckType) {
                editor.getComponents().forEach(updateAll);
            }
        }
    }, [editorData]);

    const createEditor = () => {
        const editorInstance = GrapesJS.init({
            protectedCss: '.layout-place-holder,.container,.container-fluid,.row,.column{min-height:75px;padding:10px;}.gjs-dashed .sandiv{min-height:75px; padding:5px;}',
            container: '#gjs',
            colorPicker: { appendTo: 'parent', offset: { top: 26, left: -166 } },
            storageManager: false,
            loadFromDom: false,
            cData: params,
            plugins: plugins,
            canvas: {
                styles: [
                    'https://cdn.jsdelivr.net/npm/bootstrap@5.2.1/dist/css/bootstrap.min.css',
                    `${window.location.origin}/calendar.css`,
                    `${window.location.origin}/tabpanel.css`,
                    `${pathUrl}/styles.min.css`
                ],
                scripts: [
                    'https://code.jquery.com/jquery-3.3.1.slim.min.js',
                    'https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.3/umd/popper.min.js',
                    'https://cdn.jsdelivr.net/npm/bootstrap@4.1.3/dist/js/bootstrap.min.js'
                ]
            },
        });
        setTimeout(() => {
            setEditor(editorInstance);
            setEditorData(props.editorData);
        }, 200);
    }

    return (
        <div>
            <GrapesJSButtons editor={editor} fncSaveEditorData={fncSaveEditorData} />
            <EditorDiv id="gjs" className={onlySettings ? 'only-settings' : ''} />
        </div>
    );
}

export default GrapesjsEditor;
