import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
//import * as Yup from 'yup';
//import {useFormik} from 'formik';
import { PfButton } from '@profabric/react-components';
import { setWindowClass } from 'utils/helpers';
import { Form, FormInstance, InputGroup } from 'rsuite';
import { B2CApiServices, B2CApiServiceModels } from '@santsg/ui-component-core';

const ForgotPassword = () => {
  const [t] = useTranslation();
  const formRef = useRef<FormInstance>(null);
  const [formValue, setFormValue] = useState<B2CApiServiceModels.Module.User.UserForgotPasswordRequest>({
    mail: 'san@santsg.com'
  });

  // const {handleChange, values, handleSubmit, touched, errors} = useFormik({
  //   initialValues: {
  //     email: ''
  //   },
  //   validationSchema: Yup.object({
  //     email: Yup.string().email('Invalid email address').required('Required')
  //   }),
  //   onSubmit: (values) => {
  //     toast.warn('Not yet functional');
  //     // eslint-disable-next-line no-console
  //     console.log('values', values);
  //   }
  // });

  setWindowClass('hold-transition login-page');
  const handleSubmit = async () => {
    if (formRef?.current?.check()) {
      let req = formValue as B2CApiServiceModels.Module.User.UserForgotPasswordRequest;
      let res = await B2CApiServices.UserService.AuthService.ForgotPassword(req);
      if (res.header.success) {
        //userDispatch(setUser(res.body?.user));
        //navigate('/');
      }
    }
    else
      console.log(formValue);
  }

  return (
    <div className="login-box">
      <div className="card card-outline card-primary">
        <div className="card-header text-center">
          <Link to="/" className="h1">
            <b>B2C</b>
            <span>Panel</span>
          </Link>
        </div>
        <div className="card-body">
          <p className="login-box-msg">
            {t('recover.forgotYourPassword')}
          </p>
          <Form onSubmit={handleSubmit}>
            <div className="mb-3">
              <InputGroup className="mb-3">
                <Form.Control
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Email"
                />

              </InputGroup>
            </div>
            <div className="row">
              <div className="col-12">
                <PfButton type="submit" block>
                  {/* @ts-ignore */}
                  {t<string>('recover.requestNewPassword')}
                </PfButton>
              </div>
            </div>
          </Form>
          <p className="mt-3 mb-1">
            <Link to="/login">{t('login.button.signIn.label')}</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
