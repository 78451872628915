import React, { Fragment, useEffect, useState } from "react";
import { Dropdown, Input, InputNumber, Stack, Button } from 'rsuite';
import _ from 'lodash';
import { nanoid } from "@reduxjs/toolkit";
import { enmFilterType } from "models/enums/FilterType";
import { enmFilterInputShowType } from "models/enums/FilterInputShowType";
import { B2CUiHelpers, B2CUiModels } from "@santsg/ui-component-core";
import { useTranslation } from "react-i18next";
import { SanFilterProps } from "models/types/san-elements/SanFilterProps";
import { SanFilterData } from "models/types/san-elements/SanFilterData";
import { SanDynamicObject } from "models/types/san-elements/SanDynamicObject";
import * as Common from '@santsg/ui-common'

function SanFilter(props: SanFilterProps) {
    const [activeFilterType, setActiveFilterType] = useState<number>(0);
    const [filterValues, setFilterValues] = useState<B2CUiModels.mdlFilterProps[]>();
    const [lastKey, setLastKey] = useState<string>("");
    const [t] = useTranslation();

    useEffect(() => {
        if (props.forceClear) {
            setFilterValues([]);
        }

        const timeout = setTimeout(() => {
            var filterValue = _.find(filterValues, function (fValues) { return fValues.key == lastKey });
            if (filterValue && filterValue.key)
                props.onChange && props.onChange(new B2CUiModels.mdlFilterProps(filterValue.key, filterValue.value));
        }, 200);
        return () => clearTimeout(timeout);
    }, [filterValues]);


    //#region EVENTS
    const handleFilterTypeChange = (eventKey: number) => {
        setActiveFilterType(eventKey);
        renderInputBySearchType(eventKey);
    }

    const handleTextTypeInputChange = (value: string, event: any) => {
        var key = event.currentTarget.id;
        var clonedFilters = _.cloneDeep(filterValues);
        if (clonedFilters == null) {
            clonedFilters = new Array<B2CUiModels.mdlFilterProps>();
            clonedFilters.push(new B2CUiModels.mdlFilterProps(key, value));
        } else {
            var found = _.find(clonedFilters, function (f) { return f.key == key });
            if (found) {
                var foundIndex = clonedFilters.indexOf(found);
                found.value = value;
                clonedFilters[foundIndex] = found;
            }
            else {
                clonedFilters.push(new B2CUiModels.mdlFilterProps(key, value));
            }
        }
        setLastKey(key);
        setFilterValues(clonedFilters);
    }

    const handleNumberTypeInputChange = (value: number | string | null) => {
    }

    const handleDropdownTypeInputChange = (eventKey: string, event: any, dataKey?: string, converterKey?: string) => {
        var key = event.currentTarget.getAttribute("data-key") ?? dataKey;
        if (converterKey)
            key = converterKey;

        var selectedFilter = null;

        var clonedFilters = _.cloneDeep(filterValues);
        if (String(eventKey) != String(0)) {
            if (clonedFilters == null || (clonedFilters != null && clonedFilters.length == 0)) {
                clonedFilters = new Array<B2CUiModels.mdlFilterProps>();
                if (converterKey) {
                    var filter = _.find(props.filters, function (f) {
                        return f.dataKey == dataKey;
                    });
                    if (filter) {
                        var dataSource = filter.dataSource;
                        var foundValue = _.find(dataSource, function (d) { return d["id"] == eventKey });
                        if (foundValue) {
                            selectedFilter = new B2CUiModels.mdlFilterProps(dataKey!, foundValue[converterKey]);
                            clonedFilters.push(selectedFilter);
                        }
                    }
                } else {
                    clonedFilters.push(new B2CUiModels.mdlFilterProps(key, eventKey));
                }
            } else {
                var found = _.find(clonedFilters, function (f) { return f.key == key });
                if (found) {
                    var foundIndex = clonedFilters.indexOf(found);
                    found.value = parseInt(eventKey);
                    clonedFilters[foundIndex] = found;
                }
                else {
                    clonedFilters.push(new B2CUiModels.mdlFilterProps(key, eventKey));
                }
            }
        }


        if (String(eventKey) == String(0)) {
            if (converterKey)
                key = dataKey;
            props.onClearFilter && props.onClearFilter(key);
            clonedFilters = _.filter(clonedFilters, filter => filter.key != key);
        }
        else {
            if (selectedFilter != null)
                props.onChange && props.onChange(selectedFilter);
            else
                props.onChange && props.onChange(new B2CUiModels.mdlFilterProps(key, (parseInt(eventKey))));
        }
        setFilterValues(clonedFilters);
    }

    const handleClearAll = () => {
        setFilterValues([]);
        props.onClearFilter && props.onClearFilter();
    }
    //#endregion

    //#region RENDERERS
    const renderInputBySearchType = (eventKey?: number) => {
        var activeType = eventKey ? props.filters[eventKey] : props.filters[activeFilterType];
        switch (activeType.type) {
            case enmFilterType.DYNAMIC:
            case enmFilterType.TEXT:
                return (
                    <Input placeholder={t("common.typeHere")} size="md" autoFocus value={_.find(filterValues, function (fValues) { return fValues.key == activeType.dataKey })?.value} id={activeType.dataKey} onChange={handleTextTypeInputChange} />
                );
            case enmFilterType.NUMBER:
                return (
                    <InputNumber size="md" autoFocus placeholder={t("common.typeHere")} onChange={handleNumberTypeInputChange} />
                );
            case enmFilterType.ENUMSELECT:
                var enumKeyValuePair = B2CUiHelpers.EnumHelper.getEnumKeyValues(activeType.model);
                return (
                    <Dropdown title={getDropdownText(activeType, enumKeyValuePair)} activeKey={_.find(filterValues, function (fValues) { return fValues.key == activeType.dataKey })?.value} trigger={"click"} onSelect={handleDropdownTypeInputChange}>
                        {
                            enumKeyValuePair.map((pair: B2CUiModels.mdlIdNameCodeValue, index: number) => (
                                <Fragment key={index}>
                                    <Dropdown.Item active={getSelectedDropdownValue(pair)} eventKey={pair.id} data-key={activeType.dataKey}>{pair.name!.replace(/([a-z])([A-Z])/g, '$1 $2')}</Dropdown.Item>
                                </Fragment>
                            ))
                        }

                    </Dropdown>
                );
        }
    }

    const renderAllInputsByFilterType = () => {
        const renderInputs: any = [];
        _.each(props.filters, function (filter) {
            switch (filter.type) {
                case enmFilterType.DYNAMIC:
                case enmFilterType.TEXT:
                    renderInputs.push(
                        <Stack direction="row" alignItems="flex-end" spacing={14} key={nanoid()}>
                            <label>{filter.label}</label>
                            <Input placeholder={t("common.typeHere")} size="md" autoFocus={lastKey == filter.dataKey} value={_.find(filterValues, function (fValues) { return fValues.key == filter.dataKey })?.value} id={filter.dataKey} onChange={handleTextTypeInputChange} />
                        </Stack>
                    )
                    break;
                case enmFilterType.NUMBER:
                    renderInputs.push(
                        <Stack direction="row" alignItems="flex-end" spacing={14} key={nanoid()}>
                            <label>{filter.label}</label>
                            <InputNumber size="md" placeholder={t("common.typeHere")} autoFocus value={_.find(filterValues, function (fValues) { return fValues.key == filter.dataKey })?.value} id={filter.dataKey} onChange={handleNumberTypeInputChange} />
                        </Stack>)
                    break;
                case enmFilterType.ENUMSELECT:
                    var enumKeyValuePair = B2CUiHelpers.EnumHelper.getEnumKeyValues(filter.model);
                    renderInputs.push(
                        <Stack direction="row" alignItems="flex-end" spacing={14} key={nanoid()}>
                            <label>{filter.label}</label>
                            <Dropdown title={getDropdownText(filter, enumKeyValuePair)} activeKey={_.find(filterValues, function (fValues) { return fValues.key == filter.dataKey })?.value} trigger={"click"} onSelect={handleDropdownTypeInputChange}>
                                <Dropdown.Item data-key={filter.dataKey} eventKey={0}>{t("common.choose")}</Dropdown.Item>
                                {
                                    enumKeyValuePair.map((pair: B2CUiModels.mdlIdNameCodeValue, index: number) => (
                                        <Fragment key={index}>
                                            <Dropdown.Item active={getSelectedDropdownValue(pair)} data-key={filter.dataKey} eventKey={pair.id}>{pair.name!.replace(/([a-z])([A-Z])/g, '$1 $2')}</Dropdown.Item>
                                        </Fragment>
                                    ))
                                }

                            </Dropdown>
                        </Stack >
                    )
                    break;
                case enmFilterType.DATASELECT:
                    var convertedList = convertDataSourceToIdNamePair(filter.dataSource, filter.valueKey);
                    renderInputs.push(
                        <Stack direction="row" alignItems="flex-end" spacing={14} key={nanoid()}>
                            <label>{filter.label}</label>
                            <Dropdown title={getDropdownText(filter, convertedList)} activeKey={getSelectedKey(filter)} trigger={"click"} onSelect={(eventKey: string, event: any) => handleDropdownTypeInputChange(eventKey, event, filter.dataKey, filter.converterKey)} >
                                <Dropdown.Item data-key={filter.dataKey} eventKey={0}>{t("common.choose")}</Dropdown.Item>
                                {
                                    convertedList.map((pair: B2CUiModels.mdlIdNameCodeValue, index: number) => (
                                        <Fragment key={index}>
                                            <Dropdown.Item active={getSelectedDropdownValue(pair, filter)} data-key={filter.dataKey} eventKey={pair.id}>{pair.name!.replace(/([a-z])([A-Z])/g, '$1 $2')}</Dropdown.Item>
                                        </Fragment>
                                    ))
                                }

                            </Dropdown>
                        </Stack >
                    )
            }
        })
        return (
            <Stack direction="row" spacing={15}>
                {renderInputs}
            </Stack>
        )
    }
    //#endregion

    const getSelectedKey = (filter: SanFilterData) => {
        if (filter.converterKey) {
            var foundFilterValue = _.find(filterValues, function (v) { return v.key == filter.dataKey });
            if (foundFilterValue) {
                var foundSource = _.find(filter.dataSource, function (d) { return d[filter.converterKey!] == foundFilterValue?.value });
                if (foundSource) {
                    return foundSource["id"];
                }
            }
        } else {
            return _.find(filterValues, function (fValues) { return fValues.key == filter.dataKey })?.value;
        }
    }


    const convertDataSourceToIdNamePair = (dataList?: SanDynamicObject[], valueKey?: string) => {
        var pair = new Array<B2CUiModels.mdlIdNameCodeValue>();
        _.each(dataList, function (d) {
            var i = new B2CUiModels.mdlIdNameCodeValue();
            i.id = d["id"];
            i.name = d[valueKey!];
            pair.push(i);
        })
        return pair;
    }

    //#region HELPERS
    const getDropdownText = (activeFilter: SanFilterData, enumKeyValuePair: B2CUiModels.mdlIdNameCodeValue[]) => {
        var text = t("common.choose");
        if (filterValues != null) {
            var foundActiveFilter = _.find(filterValues, function (filter) { return filter.key == activeFilter.dataKey });
            if (foundActiveFilter && enumKeyValuePair != null) {
                var foundPair = null;
                if (activeFilter.converterKey) {
                    foundPair = _.find(enumKeyValuePair, function (p) { return p != null && p.name == foundActiveFilter?.value });
                } else {
                    foundPair = _.find(enumKeyValuePair, function (p) { return p != null && p.id == foundActiveFilter?.value });
                }
                if (foundPair && foundPair.name)
                    text = foundPair.name;
            }
        }
        return text.replace(/([a-z])([A-Z])/g, '$1 $2');
    }

    const getSelectedDropdownValue = (pair: B2CUiModels.mdlIdNameCodeValue, filter?: SanFilterData) => {
        if (filter && filter.converterKey) {
            var foundFilterValue = _.find(filterValues, function (v) { return v.key == filter.dataKey });
            if (foundFilterValue) {
                var foundSource = _.find(filter.dataSource, function (d) { return d[filter.converterKey!] == foundFilterValue?.value });
                if (foundSource) {
                    return foundSource["id"] == pair.id;
                }
            }
            return false;
        }
        else {
            var found = _.find(filterValues, function (fValues) { return fValues.key == pair.id });
            if (found)
                return found.value == pair.name;
            else
                return false;
        }

    }
    //#endregion

    return (
        <Fragment key={nanoid()}>
            {
                props.filters
                &&
                <Stack spacing={6} direction="column">

                    {
                        props.title
                        &&
                        <h5>{props.title}</h5>

                    }
                    {
                        props.filterInputShowType == enmFilterInputShowType.DROPDOWN
                        &&
                        <>
                            <Dropdown title={t("common.searchBy")} trigger={"click"} onSelect={handleFilterTypeChange} activeKey={activeFilterType}>
                                {
                                    props.filters.map((filter: SanFilterData, index: number) => (
                                        <Fragment key={index}>
                                            <Dropdown.Item active={activeFilterType == index} eventKey={index}>{filter.label}</Dropdown.Item>
                                        </Fragment>
                                    ))
                                }
                            </Dropdown>
                            <Fragment key={nanoid()}>
                                {
                                    renderInputBySearchType()
                                }
                            </Fragment>
                        </>

                    }
                    {
                        props.filterInputShowType == enmFilterInputShowType.SEPERATE
                        &&
                        <Stack>
                            {
                                renderAllInputsByFilterType()
                            }
                        </Stack>
                    }

                    {
                        filterValues && filterValues.length > 0 && filterValues.some((w) => w.value !== "")
                        &&
                        <Stack>
                            <Button color="red" appearance={"link"} onClick={() => handleClearAll()}>
                                {t("common.clearAllFilters")}
                            </Button>
                        </Stack>
                    }
                </Stack>
            }
        </Fragment>
    )
}

export default SanFilter;