import { Input } from "rsuite"
import _ from 'lodash';
import { SanTextAreaInputProps } from "models/types/san-elements/SanTextAreaInputProps";

export const SanTextAreaInput = (props: SanTextAreaInputProps) => {

    //#region EVENTS
    const handleOnChange = (value: string) => {
        props.onChange && props.onChange(value);
    }
    //#endregion

    return (
        <Input
            as={"textarea"}
            rows={props.rows}
            disabled={props.readonly}
            value={props.value}
            placeholder={props.placeholder}
            onChange={handleOnChange}
        />
    )
}