import { ISiteCulture } from 'interfaces/siteculture';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { CookieManager } from '@santsg/ui-common';
import { B2CApiServiceModels,B2CApiServices,B2CApiModels} from '@santsg/ui-component-core';

export const getSiteCulture = createAsyncThunk('siteCulture/list', async () => {
    const req = new B2CApiServiceModels.Module.SiteCulture.SiteCultureListRequest();
    req.status = true;
    const res = await B2CApiServices.SiteCultureService.List(req, false, true);
    return {
        cultures: res.body?.data
    }
})

const defaultCulture: B2CApiModels.Module.SiteCultureModel.mdlSiteCulture = {
    id: '',
    name: 'English',
    code: 'en-US',
    lcid: 1033,
    default: true

}
const initialState: ISiteCulture = {
    cultures: [],
    selected: defaultCulture
}

export const siteCultureSlice = createSlice({
    name: 'siteCulture',
    initialState: initialState,
    reducers: {
        selectSiteCulture: (state, action) => {
            if (action.payload === null) {
                state.selected = defaultCulture
            } else {
                state.selected = action.payload
            }
            CookieManager.set('siteCultureId', JSON.stringify(state.selected?.id.replaceAll('-', '.')), 0);
        },
        setSiteCultures: (state, action) => {
            if (action.payload != null) {
                state.cultures = action.payload
            }
        }
    },
    extraReducers: builder => {
        builder.addCase(getSiteCulture.fulfilled, (state, action) => {
            if (action.payload.cultures != null)
                state.cultures = action.payload.cultures
            state.selected = state.cultures?.find(f => f.default);
            CookieManager.set('siteCultureId', JSON.stringify(state.selected?.id.replaceAll('-', '.')), 0);
        })
    }
})

export const { selectSiteCulture, setSiteCultures } = siteCultureSlice.actions

export default siteCultureSlice.reducer