
export class ValidationResult {
    id?: string;
    message?: string;
    constructor(pId: string, pMessage: string) {
        this.id = pId;
        this.message = pMessage;
    }
}

export function getValidationResult(fnTranslate: Function, id: string, message?: string) {
    return new ValidationResult(id, fnTranslate(message ?? "This field is required"));
}