import { useEffect, useRef, useState } from "react";
import { B2CApiServiceModels, B2CApiServices, B2CUiModels, B2CUiEnums, B2CApiModels, B2CApiEnums, B2CUiHelpers } from "@santsg/ui-component-core";
import { Module, Permission } from "models/enums/moduleAndPermission";
import SanAbility from "components/sanability/SanAbility";
import { enmActionCellType } from "models/enums/ActionCellType";
import { enmButtonAppearance } from "models/enums/ButtonAppearance";
import { enmButtonColor } from "models/enums/ButtonColor";
import { enmFilterType } from "models/enums/FilterType";
import { enmFilterInputShowType } from "models/enums/FilterInputShowType";
import SanPagination from "components/common/SanPagination";
import _ from 'lodash';
import { ObjectValueController } from "@santsg/ui-common";
import { useAppSelector } from "store/hooks";
import SanModal from "components/common/SanModal";
import { Stack } from "rsuite";
import SanPageTools from "components/common/SanPageTools";
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import SanTable from "components/common/SanTable";
import { SanColumnItem } from "models/types/san-elements/SanColumnItem";
import { SanActionWrapper } from "models/types/san-elements/SanActionWrapper";
import { SanFilterData } from "models/types/san-elements/SanFilterData";
import SanFilter from "components/common/SanFilter";
import { SanRemoveConfirmationProps } from "models/types/san-elements/SanRemoveConfirmationProps";
import SanRemoveConfirmation from "components/common/SanRemoveConfirmation";
import { CustomPageListProps } from "models/types/page/custom/CustomPageListProps";
import { CustomPageFormProps } from "models/types/page/custom/CustomPageFormProps";
import CustomPageForm from "./CustomPageForm";
import { enmModalSize } from "models/enums/ModalSize";
import CustomPageLayoutForm from "./CustomPageLayoutForm";
import { CustomPageLayoutFormProps } from "models/types/page/custom/CustomPageLayoutFormProps";
import * as XLSX from 'xlsx';


function CustomPageList(props: CustomPageListProps): JSX.Element {
    const settings = useAppSelector(state => state.settings);
    const [t] = useTranslation();
    const [listRequest, setListRequest] = useState<B2CApiServiceModels.Module.Page.PageListRequest>(new B2CApiServiceModels.Module.Page.PageListRequest());
    const [listResponse, setListResponse] = useState<B2CApiServiceModels.Module.Page.PageListResponse>();
    const [openEditModal, setOpenEditModal] = useState<boolean>(false);
    const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false);
    const [openLayoutModal, setOpenLayoutModal] = useState<boolean>(false);
    const [forceClearFilters, setForceClearFilters] = useState<boolean>(false);
    const [page, setPage] = useState<B2CApiModels.Module.PageModels.mdlPage>();
    const [removePageId, setRemovePageId] = useState<string>();
    const [layoutFormPageId, setLayoutFormPageId] = useState<string>();
    const [siteCultures, setSiteCultures] = useState<B2CApiModels.Module.SiteCultureModel.mdlSiteCulture[]>();
    const tableRef = useRef(null);

    useEffect(() => {
        if (listResponse == null)
            getPages();
        if (siteCultures == null)
            getSiteCultures();
    }, [listResponse]);


    //#region API CALLS
    async function getPages(request?: B2CApiServiceModels.Module.Page.PageListRequest) {
        let req = request ? request : listRequest;
        req.pageDefinitionId = props.pageDefinition?.id;
        setListRequest(req);
        var response = await B2CApiServices.PageService.PageService.List(req, false, true);
        if (response && response.header.success && response.body)
            setListResponse(response.body);
        else {
            setListResponse(new B2CApiServiceModels.Module.Page.PageListResponse([]));
        }
        setForceClearFilters(false);
    }

    async function getSiteCultures() {
        var req = new B2CApiServiceModels.Module.SiteCulture.SiteCultureListRequest();
        req.pageSize = 0;
        var response = await B2CApiServices.SiteCultureService.List(req, false, true);
        if (response && response.header.success && response.body)
            setSiteCultures(response.body.data);
        else {
            setSiteCultures([]);
        }
    }

    async function getPage(id: string) {
        var request = new B2CApiServiceModels.Module.Page.PageDetailRequest();
        request.id = id;
        var response = await B2CApiServices.PageService.PageService.Detail(request, false, true);
        if (response && response.header.success && response.body?.page) {
            return response.body.page;
        }
    }

    async function deletePage() {
        if (removePageId) {
            var response = await B2CApiServices.PageService.PageService.Delete(new B2CApiServiceModels.Module.Page.PageDeleteRequest(removePageId), false, true);
            if (response && response.header.success && response.body && response.body.deleted) {
                toast.success(t("common.success"));
                getPages();
            }
            else {
                toast.error(response.header?.messages?.map(messageModel => messageModel.message).join('.'));
            }
        }
    }
    //#endregion

    //#region MODAL CONTROLS
    const handleEditModalClose = () => {
        setOpenEditModal(false);
    }

    const handleRemoveModalClose = () => {
        setOpenRemoveModal(false);
    }

    const handleLayoutModalClose = () => {
        setOpenLayoutModal(false);
    }

    const handleRemoveModalConfirm = () => {
        deletePage();
        setOpenRemoveModal(false);
    }
    //#endregion

    //#region EVENTS
    const handleAddNewClick = () => {
        setPage(undefined);
        setOpenEditModal(true);
    }

    const handleEditClick = async (id: string) => {
        if (!ObjectValueController.isNullOrUndefinedOrEmpty(id)) {
            var page = await getPage(id);
            if (page) {
                setPage(page);
                setOpenEditModal(true);
            }
        }
    };

    const handleRemoveClick = (id: string) => {
        setRemovePageId(id);
        setOpenRemoveModal(true);
    }

    const handleFilterChange = (filterProp: B2CUiModels.mdlFilterProps) => {
        if (!ObjectValueController.isNullOrUndefinedOrEmpty(filterProp.key)) {
            var clonedRequest = _.cloneDeep(listRequest);
            clonedRequest = ({
                ...clonedRequest,
                [filterProp.key!]: filterProp.value
            });
            getPages(clonedRequest);
        }
    }

    const handleFilterClear = (key?: string) => {
        if (!ObjectValueController.isNullOrUndefinedOrEmpty(key)) {
            var clonedRequest = _.cloneDeep(listRequest);
            delete clonedRequest[key as keyof B2CApiServiceModels.Module.Page.PageListRequest];
            getPages(clonedRequest);
        } else {
            getPages(new B2CApiServiceModels.Module.Page.PageListRequest());
        }
    }

    const handlePagerChange = (pager?: B2CUiModels.mdlPagerProps) => {
        var req = _.cloneDeep(listRequest);
        req.pageNumber = pager?.pageNumber;
        req.pageSize = pager?.limit;
        getPages(req);
    }
    //#endregion

    //#region HELPERS
    const restorePageDefaults = () => {
        setForceClearFilters(true);
        setOpenEditModal(false);
    }

    const handleFormError = (messages: { message: string }[]) => {
        toast.error(messages.map(messageModel => messageModel.message).join('.'));
    }

    const handleFormSuccess = () => {
        restorePageDefaults();
        getPages();
        setOpenEditModal(false);
        toast.success(t("common.success"));
    }

    const handleExportToExcel = () => {
        const rowData = listResponse?.data ? listResponse?.data?.map(page => [page.name, B2CApiEnums.enmPageType[page.pageType!]]) : [];
        if (rowData)
            B2CUiHelpers.ExportHelper.exportToExcel(XLSX, t("sitePage.definitions"), [t("sitePage.name"), t("sitePage.pageType")], rowData as string[][]);
    };

    const handleExportToPdf = () => {
        const content = tableRef.current;
        if (content) B2CUiHelpers.ExportHelper.exportToPdf((content as HTMLElement).outerHTML);
    };

    const handleLayoutClick = (id: string) => {
        setLayoutFormPageId(id);
        setOpenLayoutModal(true);
    };

    //#endregion

    //#region COMPONENT PROPS
    const tableColumns: SanColumnItem[] = [
        {
            key: "id",
            type: "string",
            visible: false
        },
        {
            key: "culture.name",
            type: "string",
            label: `${t("customPageListPage.culture")}`,
            visible: true

        },
        {
            key: "name",
            type: "string",
            label: `${t("customPageListPage.name")}`,
            visible: true
        },
        {
            key: "pageType",
            type: "enum",
            label: `${t("customPageListPage.pageType")}`,
            model: B2CApiEnums.enmPageType,
            visible: true
        },
    ];


    const tableActionWrapper: SanActionWrapper = {
        actionCellType: enmActionCellType.Row,
        actions: [
            {
                apperance: enmButtonAppearance.ButtonPrimary,
                color: enmButtonColor.Blue,
                label: "Edit",
                onClick: handleEditClick
            },
            {
                apperance: enmButtonAppearance.ButtonPrimary,
                color: enmButtonColor.Yellow,
                label: t("customPageListPage.editLayout"),
                onClick: handleLayoutClick
            },
            {
                apperance: enmButtonAppearance.ButtonPrimary,
                color: enmButtonColor.Red,
                label: t("customPageListPage.delete"),
                onClick: handleRemoveClick
            }]
    };

    const filters: SanFilterData[] = [
        {
            type: enmFilterType.TEXT,
            dataKey: "name",
            label: t("customPageListPage.byName")
        },
        {
            type: enmFilterType.DATASELECT,
            dataKey: "siteCultureId",
            label: t("customPageListPage.byCulture"),
            dataSource: siteCultures,
            valueKey: "name"
        },
        {
            type: enmFilterType.ENUMSELECT,
            dataKey: "pageType",
            label: t("customPageListPage.byPageType"),
            model: B2CApiEnums.enmPageType
        },
    ];

    const customPageFormProps: CustomPageFormProps = {
        errorCallback: handleFormError,
        successCallback: handleFormSuccess,
        page: page,
        pageDefinition: props.pageDefinition
    }

    const customPageLayoutFormProps: CustomPageLayoutFormProps = {
        errorCallback: handleFormError,
        successCallback: handleFormSuccess,
        pageId: layoutFormPageId
    }
    //#endregion 

    return <SanAbility module={Module.Page} permissions={[Permission.Read]} errorState='ShowMessage'>
        {
            <>
                <div>
                    <SanPageTools
                        title={t("customPageListPage.customPages")}
                        addNewFunction={handleAddNewClick}
                        addNewLabel={t("customPageListPage.addNew")}
                        exportToExcelFunction={handleExportToExcel}
                        exportToExcelLabel={t("common.exportToExcel")}
                        exportToPdfFunction={handleExportToPdf}
                        exportToPdfLabel={t("common.exportToPdf")} />
                </div>
                <div>
                    <SanFilter forceClear={forceClearFilters} title={t("common.activeFilters")} onClearFilter={handleFilterClear} filters={filters} onChange={handleFilterChange} filterInputShowType={enmFilterInputShowType.SEPERATE} />
                </div>
                <div ref={tableRef}>
                    <SanTable data={listResponse?.data} tableColumns={tableColumns} tableActions={tableActionWrapper} />
                </div>
                <div>
                    <SanPagination totalCount={listResponse?.totalCount} resultCount={listResponse?.resultCount} activePage={listResponse?.pageNumber} activeLimit={listRequest.pageSize} onChange={handlePagerChange} />
                </div>
                <Stack>
                    <SanModal
                        backdrop={"static"}
                        type={settings?.panel.modalShowType}
                        open={openEditModal}
                        onClose={handleEditModalClose}
                        closeLabel="Close"
                        title={t("customPageListPage.customPageForm.drawerTitle")}
                        body={CustomPageForm}
                        bodyProps={customPageFormProps}
                    />
                </Stack>
                <Stack>
                    <SanModal
                        backdrop={"static"}
                        type={B2CUiEnums.enmModalShowType.Modal}
                        open={openRemoveModal}
                        onClose={handleRemoveModalClose}
                        closeLabel="Close"
                        bodyProps={{ callBack: handleRemoveModalConfirm, typingRequired: true } as SanRemoveConfirmationProps}
                        title={t("common.attentionRequired")}
                        body={SanRemoveConfirmation}
                    />
                </Stack>
                <Stack>
                    <SanModal
                        backdrop={"static"}
                        type={B2CUiEnums.enmModalShowType.Drawer}
                        size={enmModalSize.FULL}
                        open={openLayoutModal}
                        onClose={handleLayoutModalClose}
                        closeLabel="Close"
                        title={t("customPageListPage.customPageLayout.drawerTitle")}
                        body={CustomPageLayoutForm}
                        bodyProps={customPageLayoutFormProps}
                    />
                </Stack>
            </>
        }
    </SanAbility>;
}

export default CustomPageList;

