import { MaskedInput } from "rsuite"
import _ from 'lodash';
import { SanPhoneInputProps } from "models/types/san-elements/SanPhoneInputProps";

export const SanPhoneInput = (props: SanPhoneInputProps) => {

    //#region EVENTS
    const handleOnChange = (value: string) => {
        props.onChange && props.onChange(value.replaceAll("_", "").replaceAll("-", "").replaceAll(" ", "").replaceAll("(", "").replaceAll(")", ""));
    }
    //#endregion

    return (
        <MaskedInput
            value={props.value}
            mask={['+', /[1-9]/, /\d/, ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
            guide={props.guide}
            disabled={props.readonly}
            showMask={props.showMask}
            keepCharPositions={props.keepCharPositions}
            placeholder={props.placeholder}
            placeholderChar={props.placeholderChar}
            onChange={handleOnChange}
        />
    )
}