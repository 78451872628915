import { AccepterType } from 'models/enums/FormAccepterTypes';
import { ValidationResult, getValidationResult } from 'models/types/common/ValidationResult';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonToolbar, Form } from 'rsuite';
import { useAppSelector } from 'store/hooks';
import * as Common from '@santsg/ui-common';
import { B2CApiModels, B2CApiServiceModels, B2CApiServices, B2CUiModels } from '@santsg/ui-component-core';
import { SanFormControl } from 'components/common/SanFormControl';
import _ from 'lodash'
import { CustomPageFormProps } from 'models/types/page/custom/CustomPageFormProps';
import { SanDynamicObject } from 'models/types/san-elements/SanDynamicObject';
import { getIdNamePairAsArray } from 'utils/functions/ObjectMapper';
import { toast } from 'react-toastify';

const CustomPageForm = (props: CustomPageFormProps) => {
    const cultures = useAppSelector(s => s.cultureInfo)?.cultures;
    const cultureOptions = cultures?.map(culture => ({ name: culture.name, id: culture.id }));
    const { t } = useTranslation();
    const [request, setRequest] = useState<B2CApiServiceModels.Module.Page.PageCreateRequest | B2CApiServiceModels.Module.Page.PageUpdateRequest>();
    const [validationErrors, setValidationErrors] = useState<Array<ValidationResult>>([]);
    const [pages, setPages] = useState<Array<B2CUiModels.mdlIdNameCodeValue>>();
    const [copyLayoutId, setCopyLayoutId] = useState<SanDynamicObject>({ id: "0" });
    const [pagesResponse, setPagesResponse] = useState<Array<B2CApiModels.Module.PageModels.mdlPage>>();

    useEffect(() => {
        if (!props.page) {
            var cRequest = new B2CApiServiceModels.Module.Page.PageCreateRequest();
            cRequest.pageDefinitionId = props?.pageDefinition?.id;
            cRequest.pageType = props.pageDefinition?.pageType;
            setRequest(cRequest);
        } else {
            var pRequest = new B2CApiServiceModels.Module.Page.PageUpdateRequest();
            pRequest.id = props.page.id;
            pRequest.name = props.page.name;
            pRequest.pageType = props.page.pageType;
            pRequest.siteCultureId = props.page.siteCultureId;
            setRequest(pRequest);
            getPagesByPageType(props.page.siteCultureId!);
        }
    }, []);

    const checkValidation = () => {
        var messages = new Array<ValidationResult>();
        var result = false;
        if (Common.ObjectValueController.isNullOrUndefinedOrEmpty(request?.name)) {
            messages.push(getValidationResult(t, "name"));
        }
        if (!Common.ObjectValueController.isNullOrUndefinedOrEmpty(request?.name) && !Common.StringHelper.checkSupportedCharacters(request?.name!)) {
            messages.push(getValidationResult(t, "name", t("common.notSupportedCharacters")));
        }
        messages.length == 0 ? result = true : result = false;
        setValidationErrors(messages);
        return result;
    }

    const handleSubmit = async () => {
        setValidationErrors([]);
        if (checkValidation()) {
            if (!props.page) {
                var createdLayoutId = await createLayout();
                if (createdLayoutId != undefined) {
                    await createPage(createdLayoutId);
                }
            }
            else {
                await updatePage();
            }
        }
    };

    const handleSiteCultureChange = async (value: SanDynamicObject) => {
        var cultureId = value["siteCultureId"];
        setRequest({
            ...request,
            ["siteCultureId"]: cultureId
        });
        await getPagesByPageType(cultureId);
    }

    const getPagesByPageType = async (cultureId: string) => {
        var request = new B2CApiServiceModels.Module.Page.PageListRequest();
        request.pageType = props.pageDefinition?.pageType;
        request.siteCultureId = cultureId;
        var response = await B2CApiServices.PageService.PageService.List(request, false, true);
        if (response && response.header && response.header.success) {
            setPagesResponse(response.body?.data);
            var pages = new Array<B2CApiModels.Module.PageModels.mdlPage>();
            var emptyPage = new B2CApiModels.Module.PageModels.mdlPage();
            emptyPage.id = "0";
            emptyPage.name = "Start From Scratch";
            pages.push(emptyPage);
            pages = pages.concat(response.body!.data!);
            var pairs = getIdNamePairAsArray((pages as SanDynamicObject[]), ["id", "name"]);
            setPages(pairs);
        }
    }

    const createLayout = async () => {
        var layoutId: string | undefined = "0";
        if (copyLayoutId && copyLayoutId.id != "0") {
            var foundPage = _.find(pagesResponse, function (p) { return p.id == copyLayoutId.id });
            if (foundPage) {
                var layoutCloneRequest = new B2CApiServiceModels.Module.Layout.LayoutCloneRequest(foundPage.layoutId!);
                var layoutCloneResponse = await B2CApiServices.LayoutService.Clone(layoutCloneRequest, false, true);
                if (layoutCloneResponse.header && layoutCloneResponse.header.success) {
                    layoutId = layoutCloneResponse.body?.layout.id;
                } else {
                    toast.error(_.first(layoutCloneResponse.header.messages)?.message);
                    layoutId = undefined;
                }
            }
        }
        else {
            var layoutCreateRequest = new B2CApiServiceModels.Module.Layout.LayoutCreateRequest();
            var layoutCreateResponse = await B2CApiServices.LayoutService.Create(layoutCreateRequest, false, true);
            if (layoutCreateResponse.header && layoutCreateResponse.header.success) {
                layoutId = layoutCreateResponse.body?.layout.id;
            } else {
                toast.error(_.first(layoutCreateResponse.header.messages)?.message);
                layoutId = undefined;
            }
        }
        return layoutId;
    }

    const createPage = async (layoutId: string) => {
        var pageCreateRequest = new B2CApiServiceModels.Module.Page.PageCreateRequest();
        pageCreateRequest.pageDefinitionId = props.pageDefinition!.id;
        pageCreateRequest.name = request!.name;
        pageCreateRequest.isActive = true;
        pageCreateRequest.slugUrl = request?.name?.toLowerCase();
        pageCreateRequest.pageType = props.pageDefinition?.pageType;
        pageCreateRequest.siteCultureId = request?.siteCultureId;
        pageCreateRequest.layoutId = layoutId;
        var pageCreateResponse = await B2CApiServices.PageService.PageService.Create(pageCreateRequest, false, true);
        if (pageCreateResponse.body && pageCreateResponse.header.success) {
            props.successCallback && props.successCallback(pageCreateResponse.header.success);
        } else {
            if (pageCreateResponse.header.messages != null) {
                toast.error(pageCreateResponse.header.messages.at(0)?.message);
            }
        }
    }

    const updatePage = async () => {
        var response = await B2CApiServices.PageService.PageService.Update(request as B2CApiServiceModels.Module.Page.PageUpdateRequest, false, true);
        if (response.body && response.header.success) {
            props.successCallback && props.successCallback(response.header.success);
        } else {
            if (response.header.messages != null) {
                toast.error(response.header.messages.at(0)?.message);
            }
        }
    }

    return (
        <>
            {
                request &&
                <Form>
                    <SanFormControl
                        model={request}
                        onChange={setRequest}
                        name="name"
                        type='string'
                        label={t("customPageListPage.customPageForm.name")}
                        placeholder={t("customPageListPage.customPageForm.name")}
                        helperText={t("customPageListPage.customPageForm.nameInputInfo")}
                        errors={validationErrors}
                    />

                    {
                        !props.page &&
                        <SanFormControl
                            model={request}
                            onChange={handleSiteCultureChange}
                            name="siteCultureId"
                            label={t("customPageListPage.customPageForm.culture")}
                            dataSource={cultureOptions}
                            accepter={AccepterType.DROPDOWN}
                            placeholder={t("customPageListPage.customPageForm.culture")}
                            helperText={t("customPageListPage.customPageForm.cultureInputInfo")}
                        />
                    }

                    {
                        props.page &&
                        <SanFormControl
                            model={request}
                            onChange={setRequest}
                            name="siteCultureId"
                            label={t("customPageListPage.customPageForm.culture")}
                            dataSource={cultureOptions}
                            accepter={AccepterType.DROPDOWN}
                            placeholder={t("customPageListPage.customPageForm.culture")}
                            helperText={t("customPageListPage.customPageForm.cultureInputInfo")}
                            readonly={true}
                        />
                    }


                    {
                        !props.page && request.pageType
                        &&
                        <SanFormControl
                            model={copyLayoutId}
                            onChange={setCopyLayoutId}
                            name="id"
                            label={t("customPageListPage.customPageForm.copyLayout")}
                            dataSource={pages}
                            accepter={AccepterType.DROPDOWN}
                            placeholder={t("customPageListPage.customPageForm.copyLayout")}
                            helperText={t("customPageListPage.customPageForm.copyLayoutInputInfo")}
                        />
                    }



                    <ButtonToolbar>
                        <Button appearance="primary" onClick={handleSubmit}>
                            {t("common.submit")}
                        </Button>
                    </ButtonToolbar>
                </Form>
            }
        </>
    );
};

export default CustomPageForm;
