import { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation, useNavigation } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { allRoutes } from './RouteDefinitions';
type PrivateModuleProps = {
  children: React.ReactNode; // 👈️ type children
};

const PrivateRoute = (props: PrivateModuleProps) => {
  const auth = useAppSelector(state => state.auth);
  const [authorizedModule, setAuthorizedModule] = useState(false);
  const { pathname } = useLocation();
  const checkAccessbility = () => {
    var menuItemInfo = allRoutes.find(w => pathname.includes(w.path.replace(":id", "")));
    var userAuthorization = auth?.authorizedModules.find(w => w.module == menuItemInfo?.module && menuItemInfo.permissions?.some(s => w.permissions.includes(s)));
    setAuthorizedModule(userAuthorization != null);
  }
  useEffect(() => {
    checkAccessbility();
  }, [pathname])
  return auth?.user && authorizedModule ? <>{props.children}</> : !auth?.user ? <Navigate to="/login" /> : <>Wrong path!!!</>;
};

export default PrivateRoute;
