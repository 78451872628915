import { SanFormControl } from "components/common/SanFormControl";
import GrapesjsEditor from "components/editor/component";
import { AccepterType } from "models/enums/FormAccepterTypes";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Panel } from "rsuite";
import { B2CApiEnums, B2CApiModels, B2CApiServiceModels, B2CApiServices, B2CUiModels } from "@santsg/ui-component-core";
import _ from 'lodash'
import { toast } from "react-toastify";
import { CustomPageLayoutFormProps } from "../../../models/types/page/custom/CustomPageLayoutFormProps";
import { enmDropdownMode } from "models/enums/DropdownMode";


export default function CustomPageLayoutForm(props: CustomPageLayoutFormProps) {
    const [editorData, setEditorData] = useState({ components: '', componentCss: '' });
    const [sitePage, setSitePage] = useState<B2CApiModels.Module.PageModels.mdlPage>();
    const [templateList, setTemplateList] = useState<B2CApiModels.Module.Template.mdlTemplate[]>();
    const { t } = useTranslation();
    const [template, setTemplate] = useState(new B2CApiModels.Module.Template.mdlTemplate(enmDropdownMode.Choose, "", B2CApiEnums.enmPageType.Content, "", false));

    useEffect(() => {
        if (!sitePage) {
            getData();
            getTemplates();
        }
    }, [sitePage]);

    const getData = async (pTemplate?: B2CApiModels.Module.Template.mdlTemplate) => {
        if (props.pageId) {
            if (pTemplate) setTemplate(pTemplate);

            const request = new B2CApiServiceModels.Module.Page.PageDetailRequest();
            request.id = props.pageId;
            if (pTemplate?.id == enmDropdownMode.Choose)
                request.resetTemplate = true;
            else {
                request.templateId = pTemplate?.id
            }
            const response = await B2CApiServices.PageService.PageService.Detail(request, false, true);

            if (response.body?.page) {
                const page = response.body.page;
                setSitePage(page);
                setTemplate(templateList?.find(temp => temp.id == page.templateId) ?? new B2CApiModels.Module.Template.mdlTemplate(page.templateId!, "", B2CApiEnums.enmPageType.Content, "", false));
                setEditorData({ components: page.components ?? '', componentCss: page.componentCss ?? '' });
            }
        }
    };

    const getTemplates = async () => {
        let templateListRequest = new B2CApiServiceModels.Module.Template.TemplateListRequest();
        templateListRequest.pageSize = 0;
        let response = await B2CApiServices.TemplateService.List(templateListRequest, false, true);
        if (response && response.body && response.body.data) {
            setTemplateList(response.body.data);
        }
    }
    const saveEditorData = async (jsonData: string, cssData: string) => {
        if (sitePage) {
            let layout = new B2CApiServiceModels.Module.Layout.LayoutUpdateRequest(sitePage.layoutId, jsonData, cssData);
            await B2CApiServices.LayoutService.Update(layout, false, true);
            if (template?.id != enmDropdownMode.Choose)
                sitePage.templateId = template?.id;
            const response = await B2CApiServices.PageService.PageService.Update(sitePage, false, true);
            if (response.header.success && response.header?.messages?.at(0)?.message) {
                toast.success(response.header?.messages?.at(0)?.message);
            }
        }
    };

    const templateOptions = () => [
        { name: t("common.choose"), id: enmDropdownMode.Choose },
        ...templateList?.map(temp => ({ name: temp.name, id: temp.id })) ?? []
    ];

    return (
        <Panel eventKey="layout">
            <Form>
                <Form.Group>
                    <SanFormControl
                        model={template}
                        onChange={getData}
                        name="id"
                        label={t("template.templates")}
                        accepter={AccepterType.DROPDOWN}
                        dataSource={templateOptions()}
                        placeholder={t("template.selectTemplate")}
                    />
                </Form.Group>
                <Form.Group>
                    <GrapesjsEditor editorData={editorData} fncSaveEditorData={saveEditorData} />
                </Form.Group>
            </Form>
        </Panel>
    );
}