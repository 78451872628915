import React, { useState } from "react";
import { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonToolbar, Form } from "rsuite";
import { B2CApiEnums, B2CApiServiceModels, B2CApiServices, B2CUiHelpers, B2CUiModels } from "@santsg/ui-component-core";
import _ from 'lodash';
import { SanFormControl } from "components/common/SanFormControl";
import { ObjectValueController } from "@santsg/ui-common";
import { ValidationResult, getValidationResult } from "models/types/common/ValidationResult";
import { AccepterType } from "models/enums/FormAccepterTypes";
import { ReceiverFormProps } from "models/types/receiver/ReceiverFormProps";
import { getIdNamePairAsArray } from "utils/functions/ObjectMapper";
import { SanDynamicObject } from "models/types/san-elements/SanDynamicObject";

const ReceiverForm = (props: ReceiverFormProps) => {
    const [request, setRequest] = React.useState<B2CApiServiceModels.Module.Receiver.ReceiverCreateRequest
        | B2CApiServiceModels.Module.Receiver.ReceiverUpdateRequest>({});
    const [t] = useTranslation();
    const receiverTypes = B2CUiHelpers.EnumHelper.getEnumKeyValues(B2CApiEnums.enmReceiverType);
    const eventTypes = B2CUiHelpers.EnumHelper.getEnumKeyValues(B2CApiEnums.enmEventType);
    const [validationErrors, setValidationErrors] = useState<Array<ValidationResult>>([]);
    const [users, setUsers] = useState<Array<B2CUiModels.mdlIdNameCodeValue>>();


    useEffect(() => {
        if (users == null) {
            var userListRequest = new B2CApiServiceModels.Module.User.UserListRequest();
            userListRequest.userType = B2CApiEnums.enmUserType.Panel;
            userListRequest.pageSize = 0;
            B2CApiServices.UserService.AppUserService.List(userListRequest, false, true).then((response) => {
                if (response.header && response.header.success) {
                    var userPairs = getIdNamePairAsArray((response.body?.data as SanDynamicObject[]), ["id", "mail-userInfo.firstName-userInfo.lastName"], true, "-", "|");
                    setUsers(userPairs);
                }
            })
        }

        if (!props.receiver) {
            setRequest(new B2CApiServiceModels.Module.Receiver.ReceiverCreateRequest());
        } else {
            var pRequest = new B2CApiServiceModels.Module.Receiver.ReceiverUpdateRequest();
            pRequest.id = props.receiver.id;
            pRequest.receiverType = props.receiver.receiverType;
            pRequest.eventType = props.receiver.eventType;
            pRequest.name = props.receiver.name;
            pRequest.to = props.receiver.to;
            pRequest.cc = props.receiver.cc;
            pRequest.bcc = props.receiver.bcc;
            setRequest(pRequest);
        }
    }, [props])

    //#region EVENTS
    const handleSubmit = () => {
        setValidationErrors([]);
        if (checkValidation()) {
            saveReceiver();
        }
    }
    //#endregion

    //#region HELPERS
    const checkValidation = () => {
        var messages = new Array<ValidationResult>();
        var result = false;
        if (ObjectValueController.isNullOrUndefinedOrEmpty(request.receiverType)) {
            messages.push(getValidationResult(t, "receiverType"));
        }
        if (ObjectValueController.isNullOrUndefinedOrEmpty(request.name)) {
            messages.push(getValidationResult(t, "name"));
        }
        if (ObjectValueController.isNullOrUndefinedOrEmpty(request.eventType)) {
            messages.push(getValidationResult(t, "eventType"));
        }
        if (ObjectValueController.isNullOrUndefinedOrEmpty(request.to)) {
            messages.push(getValidationResult(t, "to"));
        }

        messages.length == 0 ? result = true : result = false;
        setValidationErrors(messages);

        return result;
    }
    //#endregion

    //#region API CALLS
    async function saveReceiver() {
        var response = props.receiver ? await B2CApiServices.ReceiverService.Update(request as B2CApiServiceModels.Module.Receiver.ReceiverUpdateRequest, false, true) :
            await B2CApiServices.ReceiverService.Create(request as B2CApiServiceModels.Module.Receiver.ReceiverCreateRequest, false, true);
        if (response && response.header.success && response.body) {
            props.successCallback && props.successCallback();
        }
        else {
            props.errorCallback ? props.errorCallback(response.header.messages) : console.log("failed");
        }
    }
    //#endregion

    return (
        <Fragment>
            <Form
                onChange={setRequest}>
                <SanFormControl
                    model={request}
                    onChange={setRequest}
                    name="receiverType"
                    label={t("receiverPage.receiverForm.receiverType")}
                    accepter={AccepterType.DROPDOWN}
                    dataSource={receiverTypes}
                    placeholder={t("receiverPage.receiverForm.receiverType")}
                    helperText={t("receiverPage.receiverForm.receiverTypeInputInfo")}
                    errors={validationErrors}
                />

                <SanFormControl
                    model={request}
                    onChange={setRequest}
                    name="eventType"
                    label={t("receiverPage.receiverForm.eventType")}
                    accepter={AccepterType.DROPDOWN}
                    dataSource={eventTypes}
                    placeholder={t("receiverPage.receiverForm.eventType")}
                    helperText={t("receiverPage.receiverForm.eventTypeInputInfo")}
                    errors={validationErrors}
                />

                <SanFormControl
                    model={request}
                    onChange={setRequest}
                    name="name"
                    label={t("receiverPage.receiverForm.name")}
                    type="text"
                    placeholder={t("receiverPage.receiverForm.name")}
                    helperText={t("receiverPage.receiverForm.nameInputInfo")}
                    errors={validationErrors}
                />

                {
                    request.receiverType == B2CApiEnums.enmReceiverType.Mail
                    &&
                    <SanFormControl
                        model={request}
                        onChange={setRequest}
                        name="to"
                        label={t("receiverPage.receiverForm.to")}
                        type="text"
                        placeholder={t("receiverPage.receiverForm.to")}
                        helperText={t("receiverPage.receiverForm.toInputInfo")}
                        errors={validationErrors}
                    />
                }
                {
                    request.receiverType == B2CApiEnums.enmReceiverType.Sms
                    &&
                    <SanFormControl
                        model={request}
                        onChange={setRequest}
                        name="to"
                        label={t("receiverPage.receiverForm.to")}
                        accepter={AccepterType.PHONEINPUT}
                        showMask={true}
                        placeholderChar={"_"}
                        keepCharPositions={false}
                        placeholder={t("receiverPage.receiverForm.to")}
                        helperText={t("receiverPage.receiverForm.toInputInfo")}
                        errors={validationErrors}
                    />
                }
                {
                    request.receiverType == B2CApiEnums.enmReceiverType.Notification
                    &&
                    <SanFormControl
                        model={request}
                        onChange={setRequest}
                        name="to"
                        dataSource={users}
                        accepter={AccepterType.DROPDOWN}
                        placeholder={t("receiverPage.receiverForm.to")}
                        label={t("receiverPage.receiverForm.to")}
                        helperText={t("receiverPage.receiverForm.toInputInfo")}
                        errors={validationErrors}
                    />
                }


                {
                    request.receiverType == B2CApiEnums.enmReceiverType.Mail
                    &&
                    <Fragment>
                        <SanFormControl
                            model={request}
                            onChange={setRequest}
                            name="cc"
                            label={t("receiverPage.receiverForm.cc")}
                            type="text"
                            placeholder={t("receiverPage.receiverForm.cc")}
                            helperText={t("receiverPage.receiverForm.ccInputInfo")}
                            errors={validationErrors}
                        />

                        <SanFormControl
                            model={request}
                            onChange={setRequest}
                            name="bcc"
                            label={t("receiverPage.receiverForm.bcc")}
                            type="text"
                            placeholder={t("receiverPage.receiverForm.bcc")}
                            helperText={t("receiverPage.receiverForm.bccInputInfo")}
                            errors={validationErrors}
                        />
                    </Fragment>
                }


                <ButtonToolbar>
                    <Button appearance="primary" onClick={handleSubmit}>
                        {t("common.submit")}
                    </Button>
                </ButtonToolbar>
            </Form>
        </Fragment>
    )
}
export { ReceiverForm };