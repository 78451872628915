import React, { useState } from "react";
import { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonToolbar, Form } from "rsuite";
import { B2CApiEnums, B2CApiServiceModels, B2CApiServices, B2CUiHelpers } from "@santsg/ui-component-core";
import _ from 'lodash';
import { SanFormControl } from "components/common/SanFormControl";
import { ValidationResult, getValidationResult } from "models/types/common/ValidationResult";
import { AccepterType } from "models/enums/FormAccepterTypes";
import { ObjectValueController } from "@santsg/ui-common";
import { DataSourceFormTemplateFormProps } from "models/types/datasource/DataSourceFormTemplateFormProps";

const DataSourceFormTemplateForm = (props: DataSourceFormTemplateFormProps) => {
    const [request, setRequest] = React.useState<B2CApiServiceModels.Module.DataSource.DataSourceFormTemplate.DataSourceFormTemplateCreateRequest
        | B2CApiServiceModels.Module.DataSource.DataSourceFormTemplate.DataSourceFormTemplateUpdateRequest>({});
    const [t] = useTranslation();
    const submitActions = B2CUiHelpers.EnumHelper.getEnumKeyValues(B2CApiEnums.enmEventActionType);
    const alignments = B2CUiHelpers.EnumHelper.getEnumKeyValues(B2CApiEnums.enmDataSourceAlignment);
    const [validationErrors, setValidationErrors] = useState<Array<ValidationResult>>([]);

    useEffect(() => {
        if (!props.dataSourceFormTemplate) {
            var request = new B2CApiServiceModels.Module.DataSource.DataSourceFormTemplate.DataSourceFormTemplateCreateRequest();
            request.submitAction = B2CApiEnums.enmEventActionType.Save;
            request.removable = true;
            request.alignment = B2CApiEnums.enmDataSourceAlignment.PERROW;
            request.editable = true;
            setRequest(request);
        } else {
            var pRequest = new B2CApiServiceModels.Module.DataSource.DataSourceFormTemplate.DataSourceFormTemplateUpdateRequest();
            pRequest.id = props.dataSourceFormTemplate.id;
            pRequest.name = props.dataSourceFormTemplate.name;
            pRequest.editable = props.dataSourceFormTemplate.editable;
            pRequest.alignment = props.dataSourceFormTemplate.alignment;
            pRequest.removable = props.dataSourceFormTemplate.removable;
            pRequest.submitAction = props.dataSourceFormTemplate.submitAction;
            setRequest(pRequest);
        }
    }, [props])

    //#region EVENTS
    const handleSubmit = () => {
        setValidationErrors([]);
        if (checkValidation()) {
            saveDataSource();
        }
    }

    //#endregion

    //#region HELPERS
    const checkValidation = () => {
        var messages = new Array<ValidationResult>();
        var result = false;
        if (ObjectValueController.isNullOrUndefinedOrEmpty(request.name)) {
            messages.push(getValidationResult(t, "name"));
        }
        if (ObjectValueController.isNullOrUndefinedOrEmpty(request.submitAction)) {
            messages.push(getValidationResult(t, "submitAction"));
        }
        if (ObjectValueController.isNullOrUndefinedOrEmpty(request.alignment)) {
            messages.push(getValidationResult(t, "alignment"));
        }

        messages.length == 0 ? result = true : result = false;
        setValidationErrors(messages);

        return result;
    }
    //#endregion

    //#region API CALLS
    async function saveDataSource() {
        var response = props.dataSourceFormTemplate ? await B2CApiServices.DataSourceService.DataSourceFormTemplate.Update(request as B2CApiServiceModels.Module.DataSource.DataSourceFormTemplate.DataSourceFormTemplateUpdateRequest, false, true) :
            await B2CApiServices.DataSourceService.DataSourceFormTemplate.Create(request as B2CApiServiceModels.Module.DataSource.DataSourceFormTemplate.DataSourceFormTemplateCreateRequest, false, true);
        if (response && response.header.success && response.body) {
            props.successCallback && props.successCallback();
        }
        else {
            props.errorCallback ? props.errorCallback(response.header.messages) : console.log("failed");
        }
    }
    //#endregion

    return (
        <Fragment>
            <Form
                onChange={setRequest}>

                <SanFormControl
                    model={request}
                    onChange={setRequest}
                    name="name"
                    label={t("dataSourceFormTemplatePage.dataSourceFormTemplateForm.name")}
                    type="text"
                    placeholder={t("dataSourceFormTemplatePage.dataSourceFormTemplateForm.name")}
                    helperText={t("dataSourceFormTemplatePage.dataSourceFormTemplateForm.nameInputInfo")}
                    errors={validationErrors}
                />

                <SanFormControl
                    model={request}
                    onChange={setRequest}
                    name="submitAction"
                    label={t("dataSourceFormTemplatePage.dataSourceFormTemplateForm.submitAction")}
                    accepter={AccepterType.DROPDOWN}
                    dataSource={submitActions}
                    placeholder={t("dataSourceFormTemplatePage.dataSourceFormTemplateForm.submitAction")}
                    helperText={t("dataSourceFormTemplatePage.dataSourceFormTemplateForm.submitActionInputInfo")}
                    errors={validationErrors}
                />

                <SanFormControl
                    model={request}
                    onChange={setRequest}
                    name="alignment"
                    label={t("dataSourceFormTemplatePage.dataSourceFormTemplateForm.alignment")}
                    accepter={AccepterType.DROPDOWN}
                    dataSource={alignments}
                    placeholder={t("dataSourceFormTemplatePage.dataSourceFormTemplateForm.alignment")}
                    helperText={t("dataSourceFormTemplatePage.dataSourceFormTemplateForm.alignmentInputInfo")}
                    errors={validationErrors}
                />

                <SanFormControl
                    model={request}
                    onChange={setRequest}
                    name="editable"
                    label={t("dataSourceFormTemplatePage.dataSourceFormTemplateForm.editable")}
                    accepter={AccepterType.DROPDOWN}
                    dataSource={B2CUiHelpers.EnumHelper.getTrueFalseAsPair([t("dataSourceFormTemplatePage.dataSourceFormTemplateForm.editable"), t("dataSourceFormTemplatePage.dataSourceFormTemplateForm.notEditable")])}
                    placeholder={t("dataSourceFormTemplatePage.dataSourceFormTemplateForm.editable")}
                    helperText={t("dataSourceFormTemplatePage.dataSourceFormTemplateForm.editableInputInfo")}
                    errors={validationErrors}
                />

                <SanFormControl
                    model={request}
                    onChange={setRequest}
                    name="removable"
                    readonly={!request.removable}
                    label={t("dataSourceFormTemplatePage.dataSourceFormTemplateForm.removable")}
                    accepter={AccepterType.DROPDOWN}
                    dataSource={B2CUiHelpers.EnumHelper.getTrueFalseAsPair([t("dataSourceFormTemplatePage.dataSourceFormTemplateForm.removable"), t("dataSourceFormTemplatePage.dataSourceFormTemplateForm.notRemovable")])}
                    placeholder={t("dataSourceFormTemplatePage.dataSourceFormTemplateForm.removable")}
                    helperText={t("dataSourceFormTemplatePage.dataSourceFormTemplateForm.removableInputInfo")}
                    errors={validationErrors}
                />

                <ButtonToolbar>
                    <Button appearance="primary" onClick={handleSubmit}>
                        {t("common.submit")}
                    </Button>
                </ButtonToolbar>
            </Form>
        </Fragment>
    )
}
export { DataSourceFormTemplateForm };