import React, { useState } from "react";
import { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonToolbar, Form } from "rsuite";
import { B2CApiEnums, B2CApiServiceModels, B2CApiServices, B2CUiEnums, B2CUiHelpers, B2CUiModels, TVApiEnums, TVApiModels, TVApiServiceModels, TVApiServices } from "@santsg/ui-component-core";
import _ from 'lodash';
import { SanFormControl } from "components/common/SanFormControl";
import { ValidationResult, getValidationResult } from "models/types/common/ValidationResult";
import { AccepterType } from "models/enums/FormAccepterTypes";
import { DataSourceListItemFormProps } from "models/types/datasource/DataSourceListItemFormProps";
import { SanDynamicObject } from "models/types/san-elements/SanDynamicObject";
import { enmAutoCompleteWorkType } from "models/enums/AutoCompleteWorkType";
import { ObjectValueController } from "@santsg/ui-common";

const DataSourceListItemForm = (props: DataSourceListItemFormProps) => {
    const [request, setRequest] = React.useState<B2CApiServiceModels.Module.DataSource.DataSourceListItem.DataSourceListItemCreateRequest
        | B2CApiServiceModels.Module.DataSource.DataSourceListItem.DataSourceListItemUpdateRequest>({});
    const [t] = useTranslation();
    const [validationErrors, setValidationErrors] = useState<Array<ValidationResult>>([]);

    const [autoCompleteArrivalDataSource, setAutoCompleteArrivalDataSource] = useState<B2CUiModels.mdlIdNameCodeValue[]>();
    const [autoCompleteDepartureDataSource, setAutoCompleteDepartureDataSource] = useState<B2CUiModels.mdlIdNameCodeValue[]>();
    const [onlyHotelValues, setOnlyHotelValues] = useState<B2CUiModels.mdlCategorizedHotelItem>(new B2CUiModels.mdlCategorizedHotelItem());
    const [dateValues, setDateValues] = useState<B2CUiModels.mdlCategorizedDatePickerItem>(new B2CUiModels.mdlCategorizedDatePickerItem());
    const [flightValues, setFlightValues] = useState<B2CUiModels.mdlCategorizedFlightItem>(new B2CUiModels.mdlCategorizedFlightItem());
    const [dynamicValues, setDynamicValues] = useState<B2CUiModels.mdlCategorizedDynamicItem>(new B2CUiModels.mdlCategorizedDynamicItem());
    const [excursionValues, setExcursionValues] = useState<B2CUiModels.mdlCategorizedExcursionItem>(new B2CUiModels.mdlCategorizedExcursionItem());
    const [holpackValues, setHolpackValues] = useState<B2CUiModels.mdlCategorizedHolidayPackageItem>(new B2CUiModels.mdlCategorizedHolidayPackageItem());
    const [transferValues, setTransferValues] = useState<B2CUiModels.mdlCategorizedTransferItem>(new B2CUiModels.mdlCategorizedTransferItem());
    const [tourValues, setTourValues] = useState<B2CUiModels.mdlCategorizedTourItem>(new B2CUiModels.mdlCategorizedTourItem());
    const [excursions, setExcursions] = useState<Array<B2CUiModels.mdlIdNameCodeValue>>(new Array<B2CUiModels.mdlIdNameCodeValue>());
    const [tours, setTours] = useState<Array<TVApiModels.mdlAutoCompleteItem>>(new Array<TVApiModels.mdlAutoCompleteItem>());
    const [tourList, setTourList] = useState<Array<B2CUiModels.mdlIdNameCodeValue>>(new Array<B2CUiModels.mdlIdNameCodeValue>());
    const [departures, setDepartures] = useState<Array<B2CUiModels.mdlIdNameCodeValue>>(new Array<B2CUiModels.mdlIdNameCodeValue>());
    const [arrivals, setArrivals] = useState<Array<B2CUiModels.mdlIdNameCodeValue>>(new Array<B2CUiModels.mdlIdNameCodeValue>());
    const [multiFlightValues, setMultiFlightValues] = useState<B2CUiModels.mdlCategorizedMultiFlightItem>(
        {
            imageUrl: undefined,
            routes: [
                {
                    arrival: undefined, departure: undefined, imageUrl: undefined
                },
                {
                    arrival: undefined, departure: undefined, imageUrl: undefined
                },
                {
                    arrival: undefined, departure: undefined, imageUrl: undefined
                }]
        });
    const [replacerValue, setReplacerValue] = useState<string>("");
    const [urlParamValue, setUrlParamValue] = useState<string>("");
    const [imageCategory, setImageCategory] = useState<string>("");

    const tourSearchTypes = B2CUiHelpers.EnumHelper.getEnumKeyValues(B2CUiEnums.enmTourSearchType);
    useEffect(() => {
        if (!props.dataSourceListItem) {
            var request = new B2CApiServiceModels.Module.DataSource.DataSourceListItem.DataSourceListItemCreateRequest();
            request.removable = true;
            request.editable = true;
            setRequest(request);
        }
        else {
            var pRequest = new B2CApiServiceModels.Module.DataSource.DataSourceListItem.DataSourceListItemUpdateRequest();
            pRequest.id = props.dataSourceListItem.id;
            pRequest.groupId = props.dataSourceListItem.groupId;
            pRequest.name = props.dataSourceListItem.name;
            pRequest.editable = props.dataSourceListItem.editable;
            pRequest.removable = props.dataSourceListItem.removable;

            switch (props.dataSourceListGroup?.group) {
                case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUHOTEL:
                    if (props.dataSourceListItem && props.dataSourceListItem.values) {
                        var obj = JSON.parse(props.dataSourceListItem.values);
                        if (props.dataSourceListGroup.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_ARRIVAL) {
                            setOnlyHotelValues(obj);
                        }
                        if (props.dataSourceListGroup.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_CHECKIN)
                            setDateValues(obj);
                    }
                    break;
                case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUFLIGHT:
                    if (props.dataSourceListItem && props.dataSourceListItem.values) {
                        var obj = JSON.parse(props.dataSourceListItem.values);
                        if (props.dataSourceListGroup.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_ONEWAYFLIGHT ||
                            props.dataSourceListGroup.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_ROUNDTRIPFLIGHT
                        ) {
                            setFlightValues(obj);
                        }
                        else if (props.dataSourceListGroup.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_MULTIFLIGHT) {
                            setMultiFlightValues(obj);
                        }
                        if (props.dataSourceListGroup.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_CHECKIN)
                            setDateValues(obj);
                    }
                    break;
                case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUDYNAMIC:
                    if (props.dataSourceListItem && props.dataSourceListItem.values) {
                        var obj = JSON.parse(props.dataSourceListItem.values);
                        if (props.dataSourceListGroup.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_DEPARTURE
                            || props.dataSourceListGroup.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_ARRIVAL) {
                            setDynamicValues(obj);
                        }
                        if (props.dataSourceListGroup.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_CHECKIN)
                            setDateValues(obj);
                    }
                    break;
                case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUEXCURSION:
                    if (props.dataSourceListItem && props.dataSourceListItem.values) {
                        var obj = JSON.parse(props.dataSourceListItem.values);
                        if (props.dataSourceListGroup.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_EXCURSION) {
                            setExcursionValues(obj);
                        }
                        if (props.dataSourceListGroup.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_CHECKIN)
                            setDateValues(obj);
                    }
                    break;
                case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUHOLIDAYPACKAGE:
                    if (props.dataSourceListItem && props.dataSourceListItem.values) {
                        var obj = JSON.parse(props.dataSourceListItem.values);
                        if (props.dataSourceListGroup.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_PACKAGE) {
                            setHolpackValues(obj);
                        }
                        if (props.dataSourceListGroup.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_CHECKIN)
                            setDateValues(obj);
                    }
                    break;
                case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUTRANSFER:
                    if (props.dataSourceListItem && props.dataSourceListItem.values) {
                        var obj = JSON.parse(props.dataSourceListItem.values);
                        if (props.dataSourceListGroup.groupSection != B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_CHECKIN) {
                            setTransferValues(obj);
                        }
                        else setDateValues(obj);
                    }
                    break;
                case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUTOUR:
                    if (props.dataSourceListItem && props.dataSourceListItem.values) {
                        var obj = JSON.parse(props.dataSourceListItem.values);
                        if (props.dataSourceListGroup.groupSection != B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_CHECKIN) {
                            setTourValues(obj);
                        }
                        else setDateValues(obj);
                    }
                    break;
                case B2CApiEnums.enmDataSourceListGroupType.MAILTEMPLATES:
                    if (props.dataSourceListItem && props.dataSourceListItem.values) {
                        setReplacerValue(props.dataSourceListItem.values);
                    }
                    break;
                case B2CApiEnums.enmDataSourceListGroupType.URLPARAMETERS:
                    if (props.dataSourceListItem && props.dataSourceListItem.values) {
                        setUrlParamValue(props.dataSourceListItem.values);
                    }
                    break;
                case B2CApiEnums.enmDataSourceListGroupType.IMAGECATEGORIES:
                    if (props.dataSourceListItem && props.dataSourceListItem.values) {
                        setImageCategory(props.dataSourceListItem.values);
                    }
                    break;
            }
            setRequest(pRequest);
        }
        prepareDefaultOptions();
    }, [props]);

    const prepareDefaultOptions = async () => {
        if (props.dataSourceListGroup && props.dataSourceListGroup.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_EXCURSION) {
            var excursionResult = await getArrivalAutoComplete(TVApiEnums.enmProductType.Excursion);
            var formattedExcursions = new Array<B2CUiModels.mdlIdNameCodeValue>();
            _.each(excursionResult, function (item) {
                switch (item.type) {
                    case TVApiEnums.enmAutoCompleteResponseType.Excursion:
                        var dropDownItem = new B2CUiModels.mdlIdNameCodeValue();
                        dropDownItem.id = item.excursion?.id;
                        dropDownItem.value = JSON.stringify(item);
                        dropDownItem.name = item.excursion?.name;
                        formattedExcursions.push(dropDownItem);
                        break;
                }
            })
            setExcursions(formattedExcursions);
        }
        if (props.dataSourceListGroup && props.dataSourceListGroup.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_PACKAGE && departures.length == 0) {
            var departuresResponse = await getDepartures(TVApiEnums.enmProductType.HolidayPackage);
            var formattedDepartures = new Array<B2CUiModels.mdlIdNameCodeValue>();
            _.each(departuresResponse, function (item) {
                var dropDownItem = new B2CUiModels.mdlIdNameCodeValue();
                dropDownItem.id = item.id;
                dropDownItem.value = JSON.stringify(item);
                dropDownItem.name = item.name;
                formattedDepartures.push(dropDownItem);
            });
            setDepartures(formattedDepartures);
            if (props.dataSourceListItem != null && props.dataSourceListItem.values) {
                var value = JSON.parse(props.dataSourceListItem.values) as B2CUiModels.mdlCategorizedHolidayPackageItem;
                if (value.departure) {
                    var arrivalResult = await getArrivals(value.departure, TVApiEnums.enmProductType.HolidayPackage);
                    var formattedArrivals = new Array<B2CUiModels.mdlIdNameCodeValue>();
                    _.each(arrivalResult, function (item) {
                        var dropDownItem = new B2CUiModels.mdlIdNameCodeValue();
                        dropDownItem.id = item.id;
                        dropDownItem.value = JSON.stringify(item);
                        dropDownItem.name = item.name;
                        formattedArrivals.push(dropDownItem);
                    })
                    setArrivals(formattedArrivals);
                }
            }
        }
        if (props.dataSourceListGroup && (props.dataSourceListGroup.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_ONEWAYTRANSFER || props.dataSourceListGroup.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_ROUNDTRIPTRANSFER) && departures.length == 0) {
            var departuresResponse = await getDepartures(TVApiEnums.enmProductType.Transfer);
            var formattedDepartures = new Array<B2CUiModels.mdlIdNameCodeValue>();
            _.each(departuresResponse, function (item) {
                var dropDownItem = new B2CUiModels.mdlIdNameCodeValue();
                dropDownItem.id = item.id;
                dropDownItem.value = JSON.stringify(item);
                dropDownItem.name = item.name;
                formattedDepartures.push(dropDownItem);
            });
            setDepartures(formattedDepartures);
            if (props.dataSourceListItem != null && props.dataSourceListItem.values) {
                var value = JSON.parse(props.dataSourceListItem.values) as B2CUiModels.mdlCategorizedTransferItem;
                if (value.departure) {
                    var arrivalResult = await getArrivals(value.departure, TVApiEnums.enmProductType.Transfer);
                    var formattedArrivals = new Array<B2CUiModels.mdlIdNameCodeValue>();
                    _.each(arrivalResult, function (item) {
                        var dropDownItem = new B2CUiModels.mdlIdNameCodeValue();
                        dropDownItem.id = item.id;
                        dropDownItem.value = JSON.stringify(item);
                        dropDownItem.name = item.name;
                        formattedArrivals.push(dropDownItem);
                    })
                    setArrivals(formattedArrivals);
                }
            }
        }
        if (props.dataSourceListGroup && props.dataSourceListGroup.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_TOUR) {
            var tourResult = await getArrivalAutoComplete(TVApiEnums.enmProductType.Tour);
            if (tourResult) {
                setTours(tourResult);
            }
            if (props.dataSourceListItem) {
                filterToursBySelectedType((JSON.parse(props.dataSourceListItem.values!) as B2CUiModels.mdlCategorizedTourItem).type!, tourResult!);
            }
        }
    };

    //#region EVENTS
    const handleSubmit = () => {
        setValidationErrors([]);
        if (checkValidation()) {
            save();
        }
    };

    const handleArrivalAutoCompleteChange = async (searchText: string) => {
        var productType = TVApiEnums.enmProductType.Hotel;
        if (props.dataSourceListGroup?.group) {
            switch (props.dataSourceListGroup.group) {
                case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUDYNAMIC:
                    productType = TVApiEnums.enmProductType.Dynamic;
                    break;
                case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUFLIGHT:
                    productType = TVApiEnums.enmProductType.Flight;
                    break;
                default:
                    productType = TVApiEnums.enmProductType.Hotel;
            }
        }
        var result = await getArrivalAutoComplete(productType, searchText);
        if (result) {
            var orderType = productType == TVApiEnums.enmProductType.Hotel ? B2CUiEnums.enmAutoCompleteOrder.onlyLocation : B2CUiEnums.enmAutoCompleteOrder.locationHotelContent;
            var orderedList = B2CUiHelpers.AutoCompleteHelper.convertDataToDropDownItemArray(result, productType, orderType);
            var labelValuePairs = new Array<B2CUiModels.mdlIdNameCodeValue>();
            _.each(orderedList, function (ol) {
                var pair = new B2CUiModels.mdlIdNameCodeValue();
                pair.name = ol.displayText;
                pair.value = ol.value;
                labelValuePairs.push(pair);
            });
            setAutoCompleteArrivalDataSource(labelValuePairs);
        }
    };

    const handleDepartureAutoCompleteChange = async (searchText: string) => {
        var productType = props.dataSourceListGroup?.group == B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUFLIGHT ? TVApiEnums.enmProductType.Flight : TVApiEnums.enmProductType.Dynamic;
        var result = await getDepartureAutoComplete(searchText, productType);
        if (result) {
            var orderType = B2CUiEnums.enmAutoCompleteOrder.locationHotelContent;
            var orderedList = B2CUiHelpers.AutoCompleteHelper.convertDataToDropDownItemArray(result, TVApiEnums.enmProductType.Flight, B2CUiEnums.enmAutoCompleteOrder.onlyLocation);
            var labelValuePairs = new Array<B2CUiModels.mdlIdNameCodeValue>();
            _.each(orderedList, function (ol) {
                var pair = new B2CUiModels.mdlIdNameCodeValue();
                pair.name = ol.displayText;
                pair.value = ol.value;
                labelValuePairs.push(pair);
            });
            setAutoCompleteDepartureDataSource(labelValuePairs);
        }
    };

    const handleOnlyHotelArrivalSelect = (result: B2CUiModels.mdlIdNameCodeValue) => {
        var arrivalResult = result.value as TVApiModels.mdlAutoCompleteItem;
        setOnlyHotelValues({ ...onlyHotelValues, ["value"]: arrivalResult });
    };

    const handleFlightDepartureSelect = (result: B2CUiModels.mdlIdNameCodeValue, name?: string) => {
        var departureResult = result.value as TVApiModels.mdlAutoCompleteItem;
        if (props.dataSourceListGroup?.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_DEPARTURE) {
            setDynamicValues({ ...dynamicValues, ["value"]: departureResult })
        }
        else if (props.dataSourceListGroup?.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_ONEWAYFLIGHT ||
            props.dataSourceListGroup?.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_ROUNDTRIPFLIGHT
        ) {
            setFlightValues({ ...flightValues, ["departure"]: departureResult })
        }
        else if (props.dataSourceListGroup?.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_MULTIFLIGHT) {
            var objectKey = name;
            var index = objectKey?.split("_")[1];
            var type = objectKey?.split("_")[0];
            var clone = _.cloneDeep(multiFlightValues);
            if (index && type && clone && clone.routes) {
                var requested = clone.routes[parseInt(index)];
                requested.departure = result.value as TVApiModels.mdlAutoCompleteItem;
                clone.routes[parseInt(index)] = requested;
                setMultiFlightValues(clone);
            }
        }
    };

    const handleFlightArrivalSelect = (result: B2CUiModels.mdlIdNameCodeValue, name?: string) => {
        var arrivalResult = result.value as TVApiModels.mdlAutoCompleteItem;
        if (props.dataSourceListGroup?.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_DEPARTURE) {
            setDynamicValues({ ...dynamicValues, ["value"]: arrivalResult })
        }
        else if (props.dataSourceListGroup?.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_ONEWAYFLIGHT ||
            props.dataSourceListGroup?.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_ROUNDTRIPFLIGHT
        ) {
            setFlightValues({ ...flightValues, ["arrival"]: arrivalResult })
        }
        else if (props.dataSourceListGroup?.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_MULTIFLIGHT) {
            if (name) {
                var objectKey = name;
                var index = objectKey?.split("_")[1];
                var type = objectKey?.split("_")[0];
                var clone = _.cloneDeep(multiFlightValues);
                if (index && type && clone && clone.routes) {
                    var requested = clone.routes[parseInt(index)];
                    requested.arrival = result.value as TVApiModels.mdlAutoCompleteItem;
                    clone.routes[parseInt(index)] = requested;
                    setMultiFlightValues(clone);
                }
            }
        }
    };

    const handleExcursionSelect = (result: SanDynamicObject) => {
        var parsedResult = JSON.parse(result.value);
        setExcursionValues({
            ...excursionValues,
            ["value"]: parsedResult
        });
    }

    async function handleHolpackDepartureSelect(result: SanDynamicObject) {
        var parsedResult = JSON.parse(result.departure);

        var arrivalResponse = await getArrivals(parsedResult as TVApiModels.mdlLocation, TVApiEnums.enmProductType.HolidayPackage);
        var formattedArrivals = new Array<B2CUiModels.mdlIdNameCodeValue>();
        _.each(arrivalResponse, function (item) {
            var dropDownItem = new B2CUiModels.mdlIdNameCodeValue();
            dropDownItem.id = item.id;
            dropDownItem.value = JSON.stringify(item);
            dropDownItem.name = item.name;
            formattedArrivals.push(dropDownItem);
        })
        setHolpackValues({
            ...holpackValues,
            ["departure"]: parsedResult,
            ["arrival"]: undefined
        });
        setArrivals(formattedArrivals);
    }

    function handleHolpackArrivalSelect(result: SanDynamicObject) {
        var parsedResult = JSON.parse(result.arrival);
        setHolpackValues({
            ...holpackValues,
            ["arrival"]: parsedResult
        });
    }

    async function handleTransferDepartureSelect(result: SanDynamicObject) {
        var parsedResult = JSON.parse(result.departure);

        var arrivalResponse = await getArrivals(parsedResult as TVApiModels.mdlLocation, TVApiEnums.enmProductType.Transfer);
        var formattedArrivals = new Array<B2CUiModels.mdlIdNameCodeValue>();
        _.each(arrivalResponse, function (item) {
            var dropDownItem = new B2CUiModels.mdlIdNameCodeValue();
            dropDownItem.id = item.id;
            dropDownItem.value = JSON.stringify(item);
            dropDownItem.name = item.name;
            formattedArrivals.push(dropDownItem);
        })
        setTransferValues({
            ...transferValues,
            ["departure"]: parsedResult,
            ["arrival"]: undefined
        });
        setArrivals(formattedArrivals);
    }

    function handleTransferArrivalSelect(result: SanDynamicObject) {
        var parsedResult = JSON.parse(result.arrival);
        setTransferValues({
            ...transferValues,
            ["arrival"]: parsedResult
        });
    }

    function handleTourTypeSelect(result: SanDynamicObject) {
        var type = result["type"] as B2CUiEnums.enmTourSearchType;
        setTourValues({ ...tourValues, ["type"]: type })
        filterToursBySelectedType(type);
    }

    const filterToursBySelectedType = (type: B2CUiEnums.enmTourSearchType, tours?: Array<TVApiModels.mdlAutoCompleteItem>) => {
        var filteredResult = new Array<TVApiModels.mdlAutoCompleteItem>();
        var pTours = tours;
        if (!pTours)
            pTours = tours;
        switch (type) {
            case B2CUiEnums.enmTourSearchType.ByLocation:
                var locationArray = filterToursForLocation(pTours);
                filteredResult = _.filter(locationArray, function (l) { return l.type != 8 });
                break;
            case B2CUiEnums.enmTourSearchType.ByCategory:
                filteredResult = _.filter(pTours, function (tour) { return tour.type == 7 });
                break;
            case B2CUiEnums.enmTourSearchType.ByTour:
                filteredResult = _.filter(pTours, function (tour) { return tour.type == 11 });
                break;
        }
        setTourList(convertAutoCompleteToIdCodeValuePair(filteredResult));
    }

    const convertAutoCompleteToIdCodeValuePair = (data: Array<TVApiModels.mdlAutoCompleteItem>) => {
        var convertedList = new Array<B2CUiModels.mdlIdNameCodeValue>();
        _.each(data, function (d) {
            if (d.type == 11) {
                var tourItem = new B2CUiModels.mdlIdNameCodeValue();
                tourItem.id = JSON.stringify(d);
                tourItem.value = JSON.stringify(d);
                tourItem.name = d.tour?.name;
                tourItem.code = B2CUiEnums.enmTourSearchType.ByTour.toString();
                convertedList.push(tourItem);
            }
            else if (d.type == 7) {
                var categoryItem = new B2CUiModels.mdlIdNameCodeValue();
                categoryItem.id = JSON.stringify(d);
                categoryItem.value = JSON.stringify(d);
                categoryItem.name = d.category?.name;
                categoryItem.code = B2CUiEnums.enmTourSearchType.ByCategory.toString();
                convertedList.push(categoryItem);
            }
            else if (d.type == 1) {
                var cityItem = new B2CUiModels.mdlIdNameCodeValue();
                cityItem.id = JSON.stringify(d);
                cityItem.value = JSON.stringify(d);
                cityItem.name = d.city?.name;
                cityItem.code = B2CUiEnums.enmTourSearchType.ByLocation.toString();
                convertedList.push(cityItem);
            }
        })
        return convertedList;
    }

    const filterToursForLocation = (items?: TVApiModels.mdlAutoCompleteItem[]) => {
        var data = new Array<TVApiModels.mdlAutoCompleteItem>();
        var countryList = _.filter(items, function (item) { return item.type == 8 });
        _.each(countryList, function (country) {
            data.push(country);
            var cityList = _.filter(items, function (tour) { return tour.type != 8 && tour.type != 7 && 'city' in tour && tour?.country?.id == country?.country?.id && tour.type == 1 });
            _.each(cityList, function (city) {
                data.push(city);
                var townList = _.filter(items, function (tour) { return (tour?.type == 4 || tour?.type == 2) && 'town' in tour && tour?.city?.id == city?.city?.id });
                townList = _.uniqBy(townList, 'town.id');
                data = data.concat(townList);
            })
        })
        return data;
    }

    function handleTourSelect(result: SanDynamicObject) {
        var parsedResult = result as B2CUiModels.mdlCategorizedTourItem;
        setTourValues({
            ...tourValues,
            value: parsedResult.value
        });
    }

    //#endregion

    //#region HELPERS
    const getOrderedLocations = (locations: TVApiModels.mdlLocation[]) => {
        let result: TVApiModels.mdlLocation[] = [];
        if (locations == undefined) return result;

        let countries = _.filter(locations, (location) => { return location.type == TVApiEnums.enmLocationType.Country });
        let cities = _.filter(locations, (location) => { return location.type == TVApiEnums.enmLocationType.City });
        let towns = _.filter(locations, (location) => { return location.type == TVApiEnums.enmLocationType.Town });
        let villages = _.filter(locations, (location) => { return location.type == TVApiEnums.enmLocationType.Village });
        let airports = _.filter(locations, (location) => { return location.type == TVApiEnums.enmLocationType.Airport });

        countries.forEach((country) => {
            result.push(country);
            cities.forEach((city) => {
                if (city.parentId == country.id) {
                    result.push(city);
                    towns.forEach((town) => {
                        if (town.parentId == city.id) {
                            result.push(town);
                            villages.forEach((village) => {
                                if (village.parentId == town.id) {
                                    result.push(village);

                                }
                            });

                        }
                        airports.forEach((airport) => {
                            if (airport.parentId == city.id)
                                result.push(airport)
                        })
                    });
                }
            });
        });
        return result;
    }

    const checkValidation = () => {
        var messages = new Array<ValidationResult>();
        var result = false;

        if (ObjectValueController.isNullOrUndefinedOrEmpty(request.name)) {
            messages.push(getValidationResult(t, "name"));
        }

        if (props.dataSourceListGroup?.group != B2CApiEnums.enmDataSourceListGroupType.NONE) {

            if (props.dataSourceListGroup?.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_CHECKIN) {
                if (dateValues == null) {
                    messages.push(getValidationResult(t, "from"));
                    messages.push(getValidationResult(t, "to"));
                }
                else {
                    if (ObjectValueController.isNullOrUndefinedOrEmpty(dateValues?.from))
                        messages.push(getValidationResult(t, "from"));
                    if (ObjectValueController.isNullOrUndefinedOrEmpty(dateValues?.to))
                        messages.push(getValidationResult(t, "to"));
                    if (ObjectValueController.isNullOrUndefinedOrEmpty(dateValues?.text))
                        messages.push(getValidationResult(t, "text"));
                }
            }
            switch (props.dataSourceListGroup?.group) {
                case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUHOTEL:
                    if (props.dataSourceListGroup.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_ARRIVAL && onlyHotelValues == null)
                        messages.push(getValidationResult(t, "value"));
                    if (props.dataSourceListGroup.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_ARRIVAL && onlyHotelValues != null) {
                        if (ObjectValueController.isNullOrUndefinedOrEmpty(onlyHotelValues.value))
                            messages.push(getValidationResult(t, "value"));
                    }
                    break;
                case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUDYNAMIC:
                    if (props.dataSourceListGroup.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_ARRIVAL && dynamicValues == null)
                        messages.push(getValidationResult(t, "value"));
                    if (props.dataSourceListGroup.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_ARRIVAL && dynamicValues != null) {
                        if (ObjectValueController.isNullOrUndefinedOrEmpty(dynamicValues.value))
                            messages.push(getValidationResult(t, "value"));
                    }
                    break;
                case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUFLIGHT:
                    if (props.dataSourceListGroup.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_ONEWAYFLIGHT && flightValues == null) {
                        messages.push(getValidationResult(t, "departure"));
                        messages.push(getValidationResult(t, "arrival"));
                    }
                    if (props.dataSourceListGroup.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_ONEWAYFLIGHT && flightValues != null) {
                        if (ObjectValueController.isNullOrUndefinedOrEmpty(flightValues.departure))
                            messages.push(getValidationResult(t, "departure"));
                        if (ObjectValueController.isNullOrUndefinedOrEmpty(flightValues.arrival))
                            messages.push(getValidationResult(t, "arrival"));
                    }
                    break;
                case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUEXCURSION:
                    if (props.dataSourceListGroup.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_EXCURSION && excursionValues == null) {
                        messages.push(getValidationResult(t, "value"));
                    }
                    if (props.dataSourceListGroup.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_EXCURSION && excursionValues != null) {
                        if (ObjectValueController.isNullOrUndefinedOrEmpty(excursionValues.value))
                            messages.push(getValidationResult(t, "value"));
                    }
                    break;
                case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUHOLIDAYPACKAGE:
                    if (props.dataSourceListGroup.groupSection != B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_CHECKIN && holpackValues == null) {
                        messages.push(getValidationResult(t, "departure"));
                        messages.push(getValidationResult(t, "arrival"));
                    }
                    if (props.dataSourceListGroup.groupSection != B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_CHECKIN && holpackValues != null) {
                        if (ObjectValueController.isNullOrUndefinedOrEmpty(holpackValues.departure))
                            messages.push(getValidationResult(t, "departure"));
                        if (ObjectValueController.isNullOrUndefinedOrEmpty(holpackValues.arrival))
                            messages.push(getValidationResult(t, "arrival"));
                    }
                    break;
                case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUTRANSFER:
                    if (props.dataSourceListGroup.groupSection != B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_CHECKIN && transferValues == null) {
                        messages.push(getValidationResult(t, "departure"));
                        messages.push(getValidationResult(t, "arrival"));
                    }
                    if (props.dataSourceListGroup.groupSection != B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_CHECKIN && transferValues != null) {
                        if (ObjectValueController.isNullOrUndefinedOrEmpty(transferValues.departure))
                            messages.push(getValidationResult(t, "departure"));
                        if (ObjectValueController.isNullOrUndefinedOrEmpty(transferValues.arrival))
                            messages.push(getValidationResult(t, "arrival"));
                    }
                    break;
                case B2CApiEnums.enmDataSourceListGroupType.URLPARAMETERS:
                    if (ObjectValueController.isNullOrUndefinedOrEmpty(urlParamValue)) {
                        messages.push(getValidationResult(t, "values"));
                    }
                    break;
                case B2CApiEnums.enmDataSourceListGroupType.IMAGECATEGORIES:
                    if (ObjectValueController.isNullOrUndefinedOrEmpty(imageCategory)) {
                        messages.push(getValidationResult(t, "values"));
                    }
                    break;
            }
        }
        messages.length == 0 ? result = true : result = false;
        setValidationErrors(messages);

        return result;
    };

    const getModel = (type?: B2CApiEnums.enmDataSourceListItemGroupSectionType, index?: number) => {
        if (props.dataSourceListGroup) {
            switch (props.dataSourceListGroup?.group) {
                case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUHOTEL:
                    if (props.dataSourceListGroup.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_ARRIVAL)
                        return onlyHotelValues;
                    else
                        return dateValues;
                case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUDYNAMIC:
                    if (props.dataSourceListGroup.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_CHECKIN)
                        return dateValues;
                    else
                        return dynamicValues;
                case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUFLIGHT:
                    if (props.dataSourceListGroup.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_CHECKIN) {
                        return dateValues;
                    }
                    else {
                        if (type && multiFlightValues && multiFlightValues.routes && index != null) {
                            return multiFlightValues.routes[index];
                        }
                        else {
                            return flightValues;
                        }
                    }
                case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUEXCURSION:
                    if (props.dataSourceListGroup.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_EXCURSION)
                        return excursionValues;
                    else
                        return dateValues;
                case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUHOLIDAYPACKAGE:
                    if (props.dataSourceListGroup.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_PACKAGE)
                        return holpackValues;
                    else
                        return dateValues;
                case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUTRANSFER:
                    if (props.dataSourceListGroup.groupSection != B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_CHECKIN)
                        return transferValues;
                    else
                        return dateValues;
                case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUTOUR:
                    if (props.dataSourceListGroup.groupSection != B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_CHECKIN)
                        return tourValues;
                    else
                        return dateValues;
                case B2CApiEnums.enmDataSourceListGroupType.MAILTEMPLATES:
                    return replacerValue;
                default:
                    return dateValues;
            }
        } else {
            return dateValues;
        }

    };

    const getStateSetter = () => {
        if (props.dataSourceListGroup) {
            switch (props.dataSourceListGroup?.group) {
                case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUHOTEL:
                    if (props.dataSourceListGroup.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_ARRIVAL)
                        return setOnlyHotelValues;
                    else
                        return setDateValues;
                case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUDYNAMIC:
                    if (props.dataSourceListGroup.groupSection != B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_CHECKIN)
                        return setDynamicValues;
                    else
                        return setDateValues;
                case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUFLIGHT:
                    if (props.dataSourceListGroup.groupSection != B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_CHECKIN)
                        return setFlightValues;
                    else
                        return setDateValues;
                case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUEXCURSION:
                    if (props.dataSourceListGroup.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_EXCURSION)
                        return setExcursionValues;
                    else
                        return setDateValues;
                case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUHOLIDAYPACKAGE:
                    if (props.dataSourceListGroup.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_PACKAGE)
                        return setHolpackValues;
                    else
                        return setDateValues;
                case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUTRANSFER:
                    if (props.dataSourceListGroup.groupSection != B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_CHECKIN)
                        return setTransferValues;
                    else
                        return setDateValues;
                case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUTOUR:
                    if (props.dataSourceListGroup.groupSection != B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_CHECKIN)
                        return setTourValues;
                    else
                        return setDateValues;
                default:
                    return setDateValues;
            }
        } else {
            return setDateValues;
        }

    };

    const getDateInputs = () => {
        if (props.dataSourceListGroup?.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_CHECKIN) {
            return (
                <Fragment key={"date-inputs"}>
                    <SanFormControl
                        model={dateValues as SanDynamicObject}
                        onChange={setDateValues}
                        name="text"
                        label={t("dataSourceListItemPage.dataSourceListItemForm.text")}
                        type="text"
                        placeholder={t("dataSourceListItemPage.dataSourceListItemForm.text")}
                        helperText={t("dataSourceListItemPage.dataSourceListItemForm.textInputInfo")}
                        errors={validationErrors}
                    />

                    <SanFormControl
                        model={dateValues as SanDynamicObject}
                        onChange={setDateValues}
                        name="from"
                        receiveFormat={"YYYY-MM-DD"}
                        label={t("dataSourceListItemPage.dataSourceListItemForm.fromDate")}
                        accepter={AccepterType.DATEPICKER}
                        format={"dd.MM.yyyy"}
                        placeholder={t("dataSourceListItemPage.dataSourceListItemForm.fromDate")}
                        helperText={t("dataSourceListItemPage.dataSourceListItemForm.fromDateInputInfo")}
                        errors={validationErrors}
                    />

                    <SanFormControl
                        model={dateValues as SanDynamicObject}
                        onChange={setDateValues}
                        name="to"
                        receiveFormat={"YYYY-MM-DD"}
                        label={t("dataSourceListItemPage.dataSourceListItemForm.toDate")}
                        accepter={AccepterType.DATEPICKER}
                        format={"dd.MM.yyyy"}
                        placeholder={t("dataSourceListItemPage.dataSourceListItemForm.toDate")}
                        helperText={t("dataSourceListItemPage.dataSourceListItemForm.toDateInputInfo")}
                        errors={validationErrors}
                    />

                    <SanFormControl
                        model={dateValues as SanDynamicObject}
                        onChange={setDateValues}
                        name="description"
                        label={t("dataSourceListItemPage.dataSourceListItemForm.description")}
                        type="text"
                        placeholder={t("dataSourceListItemPage.dataSourceListItemForm.description")}
                        helperText={t("dataSourceListItemPage.dataSourceListItemForm.descriptionInputInfo")}
                        errors={validationErrors}
                    />
                </Fragment>
            );
        }
        else
            return [];
    };

    const getCommonInputs = () => {
        if (props.dataSourceListGroup?.group != B2CApiEnums.enmDataSourceListGroupType.NONE && props.dataSourceListGroup?.groupSection != null) {
            return (
                <Fragment key={"common-image-url"}>
                    <SanFormControl
                        model={getModel() as SanDynamicObject}
                        onChange={getStateSetter()}
                        name="imageUrl"
                        label={t("dataSourceListItemPage.dataSourceListItemForm.imageUrl")}
                        type="text"
                        placeholder={t("dataSourceListItemPage.dataSourceListItemForm.imageUrl")}
                        helperText={t("dataSourceListItemPage.dataSourceListItemForm.imageUrlInputInfo")}
                        errors={validationErrors}
                    />
                </Fragment>
            );
        }
        return [];
    };

    const getOnlyHotelInputs = () => {
        if (props.dataSourceListGroup?.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_ARRIVAL) {
            return (
                <Fragment key={"only-hotel-arrival-location"}>
                    <SanFormControl
                        model={onlyHotelValues as SanDynamicObject}
                        onSelect={handleOnlyHotelArrivalSelect}
                        autoCompleteWorkType={enmAutoCompleteWorkType.Location}
                        name="value"
                        label={t("dataSourceListItemPage.dataSourceListItemForm.arrivalLocation")}
                        accepter={AccepterType.AUTOCOMPLETE}
                        onSearch={handleArrivalAutoCompleteChange}
                        dataSource={autoCompleteArrivalDataSource}
                        placeholder={t("dataSourceListItemPage.dataSourceListItemForm.arrivalLocationPlaceHolder")}
                        helperText={t("dataSourceListItemPage.dataSourceListItemForm.arrivalLocationInputInfo")}
                        errors={validationErrors}
                    />
                </Fragment>
            );
        } else return [];
    };

    const getObjectKey = (route: TVApiEnums.enmFlightRouteType) => {
        if (props.dataSourceListGroup) {
            switch (props.dataSourceListGroup?.group) {
                case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUFLIGHT:
                    return route == TVApiEnums.enmFlightRouteType.Outbound ? "departure" : "arrival";
                case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUDYNAMIC:
                    return "value";
                default:
                    return "";
            }
        }
        else return "";
    };

    const getDynamicInputs = () => {
        if (props.dataSourceListGroup?.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_ARRIVAL) {
            return (
                <SanFormControl
                    model={getModel() as SanDynamicObject}
                    onSelect={handleFlightArrivalSelect}
                    autoCompleteWorkType={enmAutoCompleteWorkType.All}
                    name={getObjectKey(TVApiEnums.enmFlightRouteType.Return)}
                    label={t("dataSourceListItemPage.dataSourceListItemForm.arrivalLocation")}
                    accepter={AccepterType.AUTOCOMPLETE}
                    onSearch={handleArrivalAutoCompleteChange}
                    dataSource={autoCompleteArrivalDataSource}
                    placeholder={t("dataSourceListItemPage.dataSourceListItemForm.arrivalLocationPlaceHolder")}
                    helperText={t("dataSourceListItemPage.dataSourceListItemForm.arrivalLocationInputInfo")}
                    errors={validationErrors}
                />
            )
        } else {
            return (
                <SanFormControl
                    model={getModel() as SanDynamicObject}
                    onSelect={handleFlightDepartureSelect}
                    autoCompleteWorkType={enmAutoCompleteWorkType.Airport}
                    name={getObjectKey(TVApiEnums.enmFlightRouteType.Outbound)}
                    label={t("dataSourceListItemPage.dataSourceListItemForm.departureLocation")}
                    accepter={AccepterType.AUTOCOMPLETE}
                    onSearch={handleDepartureAutoCompleteChange}
                    dataSource={autoCompleteDepartureDataSource}
                    placeholder={t("dataSourceListItemPage.dataSourceListItemForm.departureLocationPlaceHolder")}
                    helperText={t("dataSourceListItemPage.dataSourceListItemForm.departureLocationInputInfo")}
                    errors={validationErrors}
                />
            )
        }
    }

    const getFlightInputs = () => {
        if (props.dataSourceListGroup?.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_ONEWAYFLIGHT
            || props.dataSourceListGroup?.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_ROUNDTRIPFLIGHT) {
            return (
                <Fragment key={"flight-departure-location"}>
                    <SanFormControl
                        model={getModel() as SanDynamicObject}
                        onSelect={handleFlightDepartureSelect}
                        autoCompleteWorkType={enmAutoCompleteWorkType.Airport}
                        name={getObjectKey(TVApiEnums.enmFlightRouteType.Outbound)}
                        label={t("dataSourceListItemPage.dataSourceListItemForm.departureLocation")}
                        accepter={AccepterType.AUTOCOMPLETE}
                        onSearch={handleDepartureAutoCompleteChange}
                        dataSource={autoCompleteDepartureDataSource}
                        placeholder={t("dataSourceListItemPage.dataSourceListItemForm.departureLocationPlaceHolder")}
                        helperText={t("dataSourceListItemPage.dataSourceListItemForm.departureLocationInputInfo")}
                        errors={validationErrors}
                    />

                    <SanFormControl
                        model={getModel() as SanDynamicObject}
                        onSelect={handleFlightArrivalSelect}
                        autoCompleteWorkType={enmAutoCompleteWorkType.Airport}
                        name={getObjectKey(TVApiEnums.enmFlightRouteType.Return)}
                        label={t("dataSourceListItemPage.dataSourceListItemForm.arrivalLocation")}
                        accepter={AccepterType.AUTOCOMPLETE}
                        onSearch={handleArrivalAutoCompleteChange}
                        dataSource={autoCompleteArrivalDataSource}
                        placeholder={t("dataSourceListItemPage.dataSourceListItemForm.arrivalLocationPlaceHolder")}
                        helperText={t("dataSourceListItemPage.dataSourceListItemForm.arrivalLocationInputInfo")}
                        errors={validationErrors}
                    />
                </Fragment>
            );
        }
        else {
            return (
                <Fragment key={"multi-flight-location"}>
                    <div className="multi-menu-container">
                        <div className="first-locations">
                            <SanFormControl
                                model={getModel(B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_MULTIFLIGHT, 0) as SanDynamicObject}
                                onSelect={(e: B2CUiModels.mdlIdNameCodeValue) => handleFlightDepartureSelect(e, "departure_0")}
                                autoCompleteWorkType={enmAutoCompleteWorkType.Airport}
                                name={getObjectKey(TVApiEnums.enmFlightRouteType.Outbound)}
                                label={t("dataSourceListItemPage.dataSourceListItemForm.firstFlightDeparture")}
                                accepter={AccepterType.AUTOCOMPLETE}
                                onSearch={handleDepartureAutoCompleteChange}
                                dataSource={autoCompleteDepartureDataSource}
                                placeholder={t("dataSourceListItemPage.dataSourceListItemForm.firstFlightDeparturePlaceHolder")}
                                helperText={t("dataSourceListItemPage.dataSourceListItemForm.firstFlightDepartureInputInfo")}
                                errors={validationErrors}
                            />

                            <SanFormControl
                                model={getModel(B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_MULTIFLIGHT, 0) as SanDynamicObject}
                                onSelect={(e: B2CUiModels.mdlIdNameCodeValue) => handleFlightArrivalSelect(e, "arrival_0")}
                                autoCompleteWorkType={enmAutoCompleteWorkType.Airport}
                                name={getObjectKey(TVApiEnums.enmFlightRouteType.Return)}
                                label={t("dataSourceListItemPage.dataSourceListItemForm.firstFlightArrival")}
                                accepter={AccepterType.AUTOCOMPLETE}
                                onSearch={handleArrivalAutoCompleteChange}
                                dataSource={autoCompleteArrivalDataSource}
                                placeholder={t("dataSourceListItemPage.dataSourceListItemForm.firstFlightArrivalPlaceHolder")}
                                helperText={t("dataSourceListItemPage.dataSourceListItemForm.firstFlightArrivalInputInfo")}
                                errors={validationErrors}
                            />
                        </div>
                        <div className="second-locations">
                            <SanFormControl
                                model={getModel(B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_MULTIFLIGHT, 1) as SanDynamicObject}
                                onSelect={(e: B2CUiModels.mdlIdNameCodeValue) => handleFlightDepartureSelect(e, "departure_1")}
                                autoCompleteWorkType={enmAutoCompleteWorkType.Airport}
                                name={getObjectKey(TVApiEnums.enmFlightRouteType.Outbound)}
                                label={t("dataSourceListItemPage.dataSourceListItemForm.secondFlightDeparture")}
                                accepter={AccepterType.AUTOCOMPLETE}
                                onSearch={handleDepartureAutoCompleteChange}
                                dataSource={autoCompleteDepartureDataSource}
                                placeholder={t("dataSourceListItemPage.dataSourceListItemForm.secondFlightDeparturePlaceHolder")}
                                helperText={t("dataSourceListItemPage.dataSourceListItemForm.secondFlightDepartureInputInfo")}
                                errors={validationErrors}
                            />

                            <SanFormControl
                                model={getModel(B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_MULTIFLIGHT, 1) as SanDynamicObject}
                                onSelect={(e: B2CUiModels.mdlIdNameCodeValue) => handleFlightArrivalSelect(e, "arrival_1")}
                                autoCompleteWorkType={enmAutoCompleteWorkType.Airport}
                                name={getObjectKey(TVApiEnums.enmFlightRouteType.Return)}
                                label={t("dataSourceListItemPage.dataSourceListItemForm.secondFlightArrival")}
                                accepter={AccepterType.AUTOCOMPLETE}
                                onSearch={handleArrivalAutoCompleteChange}
                                dataSource={autoCompleteArrivalDataSource}
                                placeholder={t("dataSourceListItemPage.dataSourceListItemForm.secondFlightArrivalPlaceHolder")}
                                helperText={t("dataSourceListItemPage.dataSourceListItemForm.secondFlightArrivalInputInfo")}
                                errors={validationErrors}
                            />
                        </div>
                        <div className="third-locations">
                            <SanFormControl
                                model={getModel(B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_MULTIFLIGHT, 2) as SanDynamicObject}
                                onSelect={(e: B2CUiModels.mdlIdNameCodeValue) => handleFlightDepartureSelect(e, "departure_2")}
                                autoCompleteWorkType={enmAutoCompleteWorkType.Airport}
                                name={getObjectKey(TVApiEnums.enmFlightRouteType.Outbound)}
                                label={t("dataSourceListItemPage.dataSourceListItemForm.thirdFlightDeparture")}
                                accepter={AccepterType.AUTOCOMPLETE}
                                onSearch={handleDepartureAutoCompleteChange}
                                dataSource={autoCompleteDepartureDataSource}
                                placeholder={t("dataSourceListItemPage.dataSourceListItemForm.thirdFlightDeparturePlaceHolder")}
                                helperText={t("dataSourceListItemPage.dataSourceListItemForm.thirdFlightDepartureInputInfo")}
                                errors={validationErrors}
                            />

                            <SanFormControl
                                model={getModel(B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_MULTIFLIGHT, 2) as SanDynamicObject}
                                onSelect={(e: B2CUiModels.mdlIdNameCodeValue) => handleFlightArrivalSelect(e, "arrival_2")}
                                autoCompleteWorkType={enmAutoCompleteWorkType.Airport}
                                name={getObjectKey(TVApiEnums.enmFlightRouteType.Return)}
                                label={t("dataSourceListItemPage.dataSourceListItemForm.thirdFlightArrival")}
                                accepter={AccepterType.AUTOCOMPLETE}
                                onSearch={handleArrivalAutoCompleteChange}
                                dataSource={autoCompleteArrivalDataSource}
                                placeholder={t("dataSourceListItemPage.dataSourceListItemForm.thirdFlightArrivalPlaceHolder")}
                                helperText={t("dataSourceListItemPage.dataSourceListItemForm.thirdFlightArrivalInputInfo")}
                                errors={validationErrors}
                            />
                        </div>
                    </div>
                </Fragment>
            );
        }
    };

    const getExcursionInputs = () => {
        return (
            <Fragment key={"excursion-inputs"}>
                <SanFormControl
                    model={getModel() as SanDynamicObject}
                    onChange={handleExcursionSelect}
                    name={"value"}
                    useCustomSelector={true}
                    customSelectorKey={"excursion.id"}
                    dataSource={excursions}
                    accepter={AccepterType.DROPDOWN}
                    placeholder={t("dataSourceListItemPage.dataSourceListItemForm.excursion")}
                    label={t("dataSourceListItemPage.dataSourceListItemForm.excursion")}
                    helperText={t("dataSourceListItemPage.dataSourceListItemForm.excursionInputInfo")}
                    errors={validationErrors}
                />
            </Fragment>
        )
    }

    const getHolpackInputs = () => {
        return (
            <Fragment key={"holpack-inputs"}>
                <SanFormControl
                    model={getModel() as SanDynamicObject}
                    onChange={handleHolpackDepartureSelect}
                    name={"departure"}
                    useCustomSelector={true}
                    customSelectorKey={"id"}
                    dataSource={departures}
                    accepter={AccepterType.DROPDOWN}
                    placeholder={t("dataSourceListItemPage.dataSourceListItemForm.departureLocation")}
                    label={t("dataSourceListItemPage.dataSourceListItemForm.departureLocation")}
                    helperText={t("dataSourceListItemPage.dataSourceListItemForm.departureLocationInputInfo")}
                    errors={validationErrors}
                />

                <SanFormControl
                    model={getModel() as SanDynamicObject}
                    onChange={handleHolpackArrivalSelect}
                    name={"arrival"}
                    useCustomSelector={true}
                    customSelectorKey={"id"}
                    dataSource={arrivals}
                    accepter={AccepterType.DROPDOWN}
                    placeholder={t("dataSourceListItemPage.dataSourceListItemForm.arrivalLocation")}
                    label={t("dataSourceListItemPage.dataSourceListItemForm.arrivalLocation")}
                    helperText={t("dataSourceListItemPage.dataSourceListItemForm.arrivalLocation")}
                    errors={validationErrors}
                />
            </Fragment>
        )
    }

    const getTourInputs = () => {
        return (
            <Fragment key={"tour-inputs"}>
                <SanFormControl
                    model={getModel() as SanDynamicObject}
                    onChange={handleTourTypeSelect}
                    name={"type"}
                    dataSource={tourSearchTypes}
                    accepter={AccepterType.DROPDOWN}
                    placeholder={t("dataSourceListItemPage.dataSourceListItemForm.tourType")}
                    label={t("dataSourceListItemPage.dataSourceListItemForm.tourType")}
                    helperText={t("dataSourceListItemPage.dataSourceListItemForm.tourTypeInputInfo")}
                    errors={validationErrors}
                />

                <SanFormControl
                    model={getModel() as SanDynamicObject}
                    onChange={handleTourSelect}
                    name={"value"}
                    dataSource={tourList}
                    accepter={AccepterType.DROPDOWN}
                    placeholder={t("dataSourceListItemPage.dataSourceListItemForm.tour")}
                    label={t("dataSourceListItemPage.dataSourceListItemForm.tour")}
                    helperText={t("dataSourceListItemPage.dataSourceListItemForm.tourInputInfo")}
                    errors={validationErrors}
                />
            </Fragment>
        )
    }

    const getMailTemplateInputs = () => {
        return (
            <Fragment key={"mail-template-inputs"}>
                <SanFormControl
                    value={replacerValue}
                    onChange={setReplacerValue}
                    name="values"
                    type="text"
                    placeholder={t("dataSourceListItemPage.dataSourceListItemForm.replacer")}
                    label={t("dataSourceListItemPage.dataSourceListItemForm.replacer")}
                    helperText={t("dataSourceListItemPage.dataSourceListItemForm.replacerInputInfo")}
                    errors={validationErrors}
                />
            </Fragment>
        )
    }

    const getUrlParamsInputs = () => {
        return (
            <Fragment key={"url-params-inputs"}>
                <SanFormControl
                    value={urlParamValue}
                    onChange={setUrlParamValue}
                    name="values"
                    type="text"
                    placeholder={t("dataSourceListItemPage.dataSourceListItemForm.urlParams")}
                    label={t("dataSourceListItemPage.dataSourceListItemForm.urlParams")}
                    helperText={t("dataSourceListItemPage.dataSourceListItemForm.urlParamsInputInfo")}
                    errors={validationErrors}
                />
            </Fragment>
        )
    }

    const getImageCategoryInputs = () => {
        return (
            <Fragment key={"image-category-inputs"}>
                <SanFormControl
                    value={imageCategory}
                    onChange={setImageCategory}
                    name="values"
                    type="text"
                    placeholder={t("dataSourceListItemPage.dataSourceListItemForm.imageCategory")}
                    label={t("dataSourceListItemPage.dataSourceListItemForm.imageCategory")}
                    helperText={t("dataSourceListItemPage.dataSourceListItemForm.imageCategoryInputInfo")}
                    errors={validationErrors}
                />
            </Fragment>
        )
    }


    const getTransferInputs = () => {
        return (
            <Fragment key={"transfer-inputs"}>
                <SanFormControl
                    model={getModel() as SanDynamicObject}
                    onChange={handleTransferDepartureSelect}
                    name={"departure"}
                    useCustomSelector={true}
                    customSelectorKey={"id"}
                    dataSource={departures}
                    accepter={AccepterType.DROPDOWN}
                    placeholder={t("dataSourceListItemPage.dataSourceListItemForm.departureLocation")}
                    label={t("dataSourceListItemPage.dataSourceListItemForm.departureLocation")}
                    helperText={t("dataSourceListItemPage.dataSourceListItemForm.departureLocationInputInfo")}
                    errors={validationErrors}
                />

                <SanFormControl
                    model={getModel() as SanDynamicObject}
                    onChange={handleTransferArrivalSelect}
                    name={"arrival"}
                    useCustomSelector={true}
                    customSelectorKey={"id"}
                    dataSource={arrivals}
                    accepter={AccepterType.DROPDOWN}
                    placeholder={t("dataSourceListItemPage.dataSourceListItemForm.arrivalLocation")}
                    label={t("dataSourceListItemPage.dataSourceListItemForm.arrivalLocation")}
                    helperText={t("dataSourceListItemPage.dataSourceListItemForm.arrivalLocation")}
                    errors={validationErrors}
                />
            </Fragment>
        )
    }

    function renderValuePicker() {
        switch (props.dataSourceListGroup?.group) {
            case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUHOTEL:
                var renderItems = new Array<JSX.Element>();
                renderItems = renderItems.concat(getOnlyHotelInputs());
                renderItems = renderItems.concat(getCommonInputs());
                renderItems = renderItems.concat(getDateInputs());
                return (renderItems);
            case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUFLIGHT:
                var renderItems = new Array<JSX.Element>();
                renderItems = renderItems.concat(getFlightInputs());
                renderItems = renderItems.concat(getCommonInputs());
                renderItems = renderItems.concat(getDateInputs());
                return (renderItems);
            case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUDYNAMIC:
                var renderItems = new Array<JSX.Element>();
                renderItems = renderItems.concat(getDynamicInputs());
                renderItems = renderItems.concat(getCommonInputs());
                renderItems = renderItems.concat(getDateInputs());
                return (renderItems);
            case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUEXCURSION:
                var renderItems = new Array<JSX.Element>();
                renderItems = renderItems.concat(getExcursionInputs());
                renderItems = renderItems.concat(getCommonInputs());
                renderItems = renderItems.concat(getDateInputs());
                return (renderItems);
            case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUHOLIDAYPACKAGE:
                var renderItems = new Array<JSX.Element>();
                renderItems = renderItems.concat(getHolpackInputs());
                renderItems = renderItems.concat(getCommonInputs());
                renderItems = renderItems.concat(getDateInputs());
                return (renderItems);
            case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUTRANSFER:
                var renderItems = new Array<JSX.Element>();
                renderItems = renderItems.concat(getTransferInputs());
                renderItems = renderItems.concat(getCommonInputs());
                renderItems = renderItems.concat(getDateInputs());
                return (renderItems);
            case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUTOUR:
                var renderItems = new Array<JSX.Element>();
                renderItems = renderItems.concat(getTourInputs());
                renderItems = renderItems.concat(getCommonInputs());
                renderItems = renderItems.concat(getDateInputs());
                return (renderItems);
            case B2CApiEnums.enmDataSourceListGroupType.MAILTEMPLATES:
                var renderItems = new Array<JSX.Element>();
                renderItems = renderItems.concat(getMailTemplateInputs());
                return (renderItems);
            case B2CApiEnums.enmDataSourceListGroupType.URLPARAMETERS:
                var renderItems = new Array<JSX.Element>();
                renderItems = renderItems.concat(getUrlParamsInputs());
                return (renderItems);
            case B2CApiEnums.enmDataSourceListGroupType.IMAGECATEGORIES:
                var renderItems = new Array<JSX.Element>();
                renderItems = renderItems.concat(getImageCategoryInputs());
                return (renderItems);

        }
    }
    //#endregion

    //#region API CALLS
    async function getArrivalAutoComplete(productType: TVApiEnums.enmProductType, searchKey: string = "") {
        var request = new TVApiServiceModels.ProductModels.GetArrivalAutoCompleteRequest(productType, searchKey, B2CUiEnums.enmCulture.enUS);
        if (productType == TVApiEnums.enmProductType.Flight)
            request.serviceType = props.dataSourceListGroup?.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_ONEWAYFLIGHT ? "1" : "2";
        if (productType == TVApiEnums.enmProductType.Dynamic) {
            request.serviceType = "2";
            delete request.masterProductTypes;
        }
        if (productType == TVApiEnums.enmProductType.Excursion) {
            request.includeSubLocations = true;
            delete request.masterProductTypes;
        }

        if (productType == TVApiEnums.enmProductType.Tour) {
            request.includeSubLocations = true;
            request.doNotCheckPrice = false;
            delete request.masterProductTypes;
        }

        var response = await TVApiServices.ProductService.GetArrivalAutoComplete(request);
        if (response.header?.success) {
            return response.body?.items;
        }
        return null;
    }

    async function getDepartureAutoComplete(searchKey: string, productType: TVApiEnums.enmProductType) {
        var request = new TVApiServiceModels.ProductModels.GetDepartureAutoCompleteRequest(productType, searchKey, B2CUiEnums.enmCulture.enUS);
        if (productType == TVApiEnums.enmProductType.Flight)
            request.serviceType = props.dataSourceListGroup?.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_ONEWAYFLIGHT ? "1" : "2";
        if (productType == TVApiEnums.enmProductType.Dynamic) {
            request.serviceType = "2";
            delete request.masterProductTypes;
        }
        var response = await TVApiServices.ProductService.GetDepartureAutoComplete(request);
        if (response.header?.success) {
            return response.body?.items;
        }
        return null;
    }

    async function getDepartures(productType: TVApiEnums.enmProductType) {
        var request = new TVApiServiceModels.ProductModels.GetDeparturesRequest();
        request.culture = 'en-US';
        request.productType = productType;
        var result = await TVApiServices.ProductService.GetDepartures(request);
        if (result && result.header?.success && result.body && result.body.locations) {
            if (productType == TVApiEnums.enmProductType.HolidayPackage)
                return _.filter(result.body.locations, function (loc) { return loc.type != TVApiEnums.enmLocationType.Country });
            else {
                return _.filter(getOrderedLocations(result.body.locations), function (o) { return o.type != TVApiEnums.enmLocationType.Country });
            }
        } else {
            try {
                var pResult = result as Array<TVApiServiceModels.BaseModels.ServiceResponse<TVApiServiceModels.ProductModels.GetDeparturesResponse>>;
                var concatList = new Array<TVApiModels.mdlLocation>();
                _.each(pResult, function (res) {
                    if (res.header && res.header.success && res.body && res.body.locations)
                        concatList = concatList.concat(res.body.locations);
                })
                return _.filter(_.uniq(concatList), function (c) { return c.type != TVApiEnums.enmLocationType.Country });
            }
            catch {
                return [];
            }
        }
    }

    async function getArrivals(departure: TVApiModels.mdlLocation, productType: TVApiEnums.enmProductType) {
        var request = new TVApiServiceModels.ProductModels.GetArrivalsRequest(productType, B2CUiEnums.enmCulture.enUS);
        request.departureLocations = new Array<TVApiModels.mdlLocation>();
        request.departureLocations.push(departure);
        if (productType == TVApiEnums.enmProductType.Transfer) {
            request.includeSubLocations = true;
            if (props.dataSourceListGroup?.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_ROUNDTRIPTRANSFER) request.direction = "3";
        }
        var result = await TVApiServices.ProductService.GetArrivals(request)
        if (result && result.header?.success && result.body && result.body.locations) {
            if (productType == TVApiEnums.enmProductType.HolidayPackage)
                return _.filter(result.body.locations, function (loc) { return loc.type != TVApiEnums.enmLocationType.Country });
            else
                return _.filter(_.uniq(getOrderedLocations(result.body.locations)), function (o) { return o.type != TVApiEnums.enmLocationType.Country });
        } else {
            return [];
        }
    }

    async function save() {
        if (props.dataSourceListGroup?.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_CHECKIN)
            request.values = JSON.stringify(dateValues);
        switch (props.dataSourceListGroup?.group) {
            case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUHOTEL:
                if (props.dataSourceListGroup?.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_ARRIVAL)
                    request.values = JSON.stringify(onlyHotelValues);
                break;
            case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUFLIGHT:
                if (props.dataSourceListGroup?.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_ONEWAYFLIGHT
                    || props.dataSourceListGroup.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_ROUNDTRIPFLIGHT)
                    request.values = JSON.stringify(flightValues);
                else if (props.dataSourceListGroup?.groupSection == B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_MULTIFLIGHT)
                    request.values = JSON.stringify(multiFlightValues);
                break;
            case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUDYNAMIC:
                request.values = JSON.stringify(dynamicValues);
                break;
            case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUEXCURSION:
                request.values = JSON.stringify(excursionValues);
                break;
            case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUHOLIDAYPACKAGE:
                request.values = JSON.stringify(holpackValues);
                break;
            case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUTRANSFER:
                request.values = JSON.stringify(transferValues);
                break;
            case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUTOUR:
                request.values = JSON.stringify(tourValues);
                break;
            case B2CApiEnums.enmDataSourceListGroupType.MAILTEMPLATES:
                request.values = replacerValue;
                break;
            case B2CApiEnums.enmDataSourceListGroupType.URLPARAMETERS:
                request.values = urlParamValue;
                break;
            case B2CApiEnums.enmDataSourceListGroupType.IMAGECATEGORIES:
                request.values = imageCategory;
                break;
        }

        if (props.dataSourceListGroup)
            request.groupId = props.dataSourceListGroup.id;
        var response = props.dataSourceListItem ? await B2CApiServices.DataSourceService.DataSourceListItem.Update(request as B2CApiServiceModels.Module.DataSource.DataSourceListItem.DataSourceListItemUpdateRequest, false, true) :
            await B2CApiServices.DataSourceService.DataSourceListItem.Create(request as B2CApiServiceModels.Module.DataSource.DataSourceListItem.DataSourceListItemCreateRequest, false, true);
        if (response && response.header.success && response.body) {
            props.successCallback && props.successCallback();
        }
        else {
            props.errorCallback ? props.errorCallback(response.header.messages) : console.log("failed");
        }
    }
    //#endregion

    return (
        <Fragment>
            <Form
                onChange={setRequest}>

                <SanFormControl
                    model={request}
                    onChange={setRequest}
                    name="name"
                    label={t("dataSourceListItemPage.dataSourceListItemForm.name")}
                    type="text"
                    placeholder={t("dataSourceListItemPage.dataSourceListItemForm.name")}
                    helperText={t("dataSourceListItemPage.dataSourceListItemForm.nameInputInfo")}
                    errors={validationErrors}
                />

                {
                    renderValuePicker()
                }

                <SanFormControl
                    model={request}
                    onChange={setRequest}
                    name="editable"
                    label={t("dataSourceListItemPage.editable")}
                    accepter={AccepterType.DROPDOWN}
                    dataSource={B2CUiHelpers.EnumHelper.getTrueFalseAsPair([t("dataSourceListItemPage.editable"), t("dataSourceListItemPage.notEditable")])}
                    placeholder={t("dataSourceListItemPage.editable")}
                    helperText={t("dataSourceListItemPage.dataSourceListItemForm.editableInputInfo")}
                    errors={validationErrors}
                />

                <SanFormControl
                    model={request}
                    onChange={setRequest}
                    name="removable"
                    readonly={!request.removable}
                    label={t("dataSourceListItemPage.removable")}
                    accepter={AccepterType.DROPDOWN}
                    dataSource={B2CUiHelpers.EnumHelper.getTrueFalseAsPair([t("dataSourceListItemPage.removable"), t("dataSourceListItemPage.notRemovable")])}
                    placeholder={t("dataSourceListItemPage.removable")}
                    helperText={t("dataSourceListItemPage.dataSourceListItemForm.removableInputInfo")}
                    errors={validationErrors}
                />

                <ButtonToolbar>
                    <Button appearance="primary" onClick={handleSubmit}>
                        {t("common.submit")}
                    </Button>
                </ButtonToolbar>
            </Form>
        </Fragment>
    );
};
export { DataSourceListItemForm };