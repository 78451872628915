import { DateInput, DatePicker, MaskedInput } from "rsuite"
import _ from 'lodash';
import { useEffect, useState } from "react";
import moment from "moment";
import { SanDateInputProps } from "models/types/san-elements/SanDateInputProps";

export const SanFormDatePicker = (props: SanDateInputProps) => {
    const [value, setValue] = useState<Date>();

    useEffect(() => {
        if (props.receiveFormat) {
            var convertedValue = moment(props.value, props.receiveFormat).toDate();
            setValue(convertedValue);
        }
        else {
            setValue(props.value);
        }
    }, [props])

    //#region EVENTS
    const handleOnChange = (e: any) => {
        var selectedDate = e.currentTarget.value;
        props.onChange && props.onChange(moment(selectedDate).utc(true).toDate());
    }

    function isDateValid(dateStr: string) {
        const date = new Date(dateStr);
        return !isNaN(date.getTime());
    }

    const getValue = (): string => {
        var val = moment(value).format(props.receiveFormat);
        if (value && isDateValid(val)) {
            return val;
        }
        return ""
    }
    //#endregion

    return (
        <input type="date"
            className="san-form-control-date"
            value={getValue()}
            placeholder={props.placeholder}
            disabled={props.readonly}
            onChange={handleOnChange}
        />
    )
}