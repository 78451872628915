import { useEffect, useRef, useState } from "react";
import { B2CApiServiceModels, B2CApiServices, B2CUiModels, B2CUiEnums, B2CApiModels, B2CApiEnums, B2CUiHelpers } from "@santsg/ui-component-core";
import { Module, Permission } from "models/enums/moduleAndPermission";
import SanAbility from "components/sanability/SanAbility";
import { enmActionCellType } from "models/enums/ActionCellType";
import { enmButtonAppearance } from "models/enums/ButtonAppearance";
import { enmButtonColor } from "models/enums/ButtonColor";
import SanPagination from "components/common/SanPagination";
import _ from 'lodash';
import { ObjectValueController } from "@santsg/ui-common";
import { useAppSelector } from "store/hooks";
import SanModal from "components/common/SanModal";
import { Stack } from "rsuite";
import SanPageTools from "components/common/SanPageTools";
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import SanTable from "components/common/SanTable";
import { SanColumnItem } from "models/types/san-elements/SanColumnItem";
import { SanActionWrapper } from "models/types/san-elements/SanActionWrapper";
import { SanRemoveConfirmationProps } from "models/types/san-elements/SanRemoveConfirmationProps";
import SanRemoveConfirmation from "components/common/SanRemoveConfirmation";
import { LocationParameterPageDefinitionListProps } from "models/types/location-parameter/LocationParameterPageDefinitionListProps";
import CustomPageDefinitionForm from "modules/page/custom/CustomPageDefinitionForm";
import { CustomPageDefinitionFormProps } from "models/types/page/custom/CustomPageDefinitionFormProps";
import { CustomPageListProps } from "models/types/page/custom/CustomPageListProps";
import { enmModalSize } from "models/enums/ModalSize";
import CustomPageList from "modules/page/custom/CustomPageList";


function LocationParameterPageDefinitionList(props: LocationParameterPageDefinitionListProps): JSX.Element {
    const settings = useAppSelector(state => state.settings);
    const [t] = useTranslation();
    const [listRequest, setListRequest] = useState<B2CApiServiceModels.Module.Page.PageDefinitionListRequest>(new B2CApiServiceModels.Module.Page.PageDefinitionListRequest());
    const [listResponse, setListResponse] = useState<B2CApiServiceModels.Module.Page.PageDefinitionListResponse>();
    const [openEditModal, setOpenEditModal] = useState<boolean>(false);
    const [openPagesModal, setOpenPagesModal] = useState<boolean>(false);
    const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false);
    const [pageDefinition, setPageDefinition] = useState<B2CApiModels.Module.PageModels.mdlPageDefinition>();
    const [removePageDefinitionId, setRemovePageDefinitionId] = useState<string>();
    const tableRef = useRef(null);

    useEffect(() => {
        if (listResponse == null)
            getPageDefinitions();
    }, [listResponse]);


    //#region API CALLS
    async function getPageDefinitions(request?: B2CApiServiceModels.Module.Page.PageDefinitionListRequest) {
        let req = request ? request : listRequest;
        req.additionalId = props.locationParameter?.id;
        setListRequest(req);
        var response = await B2CApiServices.PageService.DefinitionService.List(req, false, true);
        if (response && response.header.success && response.body)
            setListResponse(response.body);
        else {
            setListResponse(new B2CApiServiceModels.Module.Page.PageDefinitionListResponse([]));
        }
    }

    async function getPageDefinition(id: string) {
        var request = new B2CApiServiceModels.Module.Page.PageDefinitionDetailRequest();
        request.id = id;
        var response = await B2CApiServices.PageService.DefinitionService.Detail(request, false, true);
        if (response && response.header.success && response.body?.pageDefinition) {
            return response.body.pageDefinition;
        }
    }

    async function deletePageDefinition() {
        if (removePageDefinitionId) {
            var response = await B2CApiServices.PageService.DefinitionService.Delete(new B2CApiServiceModels.Module.Page.PageDefinitionDeleteRequest(removePageDefinitionId), false, true);
            if (response && response.header.success && response.body && response.body.deleted) {
                toast.success(t("common.success"));
                getPageDefinitions();
            }
            else {
                toast.error(response.header?.messages?.map(messageModel => messageModel.message).join('.'));
            }
        }
    }
    //#endregion

    //#region MODAL CONTROLS
    const handleEditModalClose = () => {
        setOpenEditModal(false);
    }

    const handlePagesModalClose = () => {
        setOpenPagesModal(false);
    }

    const handleRemoveModalClose = () => {
        setOpenRemoveModal(false);
    }

    const handleRemoveModalConfirm = () => {
        deletePageDefinition();
        setOpenRemoveModal(false);
    }
    //#endregion

    //#region EVENTS
    const handleAddNewClick = () => {
        setPageDefinition(undefined);
        setOpenEditModal(true);
    }

    const handleEditClick = async (id: string) => {
        if (!ObjectValueController.isNullOrUndefinedOrEmpty(id)) {
            var pageDefinition = await getPageDefinition(id);
            if (pageDefinition) {
                setPageDefinition(pageDefinition);
                setOpenEditModal(true);
            }
        }
    };

    const handlePagesClick = async (id: string) => {
        if (!ObjectValueController.isNullOrUndefinedOrEmpty(id)) {
            var pageDefinition = await getPageDefinition(id);
            if (pageDefinition) {
                setPageDefinition(pageDefinition);
                setOpenPagesModal(true);
            }
        }
    };

    const handleRemoveClick = (id: string) => {
        setRemovePageDefinitionId(id);
        setOpenRemoveModal(true);
    }

    const handlePagerChange = (pager?: B2CUiModels.mdlPagerProps) => {
        var req = _.cloneDeep(listRequest);
        req.pageNumber = pager?.pageNumber;
        req.pageSize = pager?.limit;
        getPageDefinitions(req);
    }
    //#endregion

    //#region HELPERS
    const restorePageDefaults = () => {
        setOpenEditModal(false);
    }

    const handleFormError = (messages: { message: string }[]) => {
        toast.error(messages.map(messageModel => messageModel.message).join('.'));
    }

    const handleFormSuccess = () => {
        restorePageDefaults();
        getPageDefinitions();
        setOpenEditModal(false);
        toast.success(t("common.success"));
    }

    const handleExportToPdf = () => {
        const content = tableRef.current;
        if (content) B2CUiHelpers.ExportHelper.exportToPdf((content as HTMLElement).outerHTML);
    };
    //#endregion

    //#region COMPONENT PROPS
    const tableColumns: SanColumnItem[] = [
        {
            key: "id",
            type: "string",
            label: "Id",
            visible: false
        },
        {
            key: "name",
            type: "string",
            label: "Name",
            visible: true
        },
        {
            key: "pageType",
            type: "enum",
            label: "Page Type",
            model: B2CApiEnums.enmPageType,
            visible: true
        },
        {
            key: "modifyDate",
            type: Date,
            label: "Modify Date",
            visible: true
        }
    ];

    const tableActionWrapper: SanActionWrapper = {
        actionCellType: enmActionCellType.Row,
        actions: [
            {
                apperance: enmButtonAppearance.ButtonPrimary,
                color: enmButtonColor.Blue,
                label: "Edit",
                onClick: handleEditClick
            },
            {
                apperance: enmButtonAppearance.ButtonPrimary,
                color: enmButtonColor.Yellow,
                label: "Pages",
                onClick: handlePagesClick
            },
            {
                apperance: enmButtonAppearance.ButtonPrimary,
                color: enmButtonColor.Red,
                label: "Delete",
                onClick: handleRemoveClick
            }]
    };


    const customPageDefinitionFormProps: CustomPageDefinitionFormProps = {
        errorCallback: handleFormError,
        successCallback: handleFormSuccess,
        additionalId: props.locationParameter?.id
    }

    const customPageListProps: CustomPageListProps = {
        errorCallback: handleFormError,
        successCallback: handleFormSuccess,
        pageDefinition: pageDefinition
    }
    //#endregion 

    return <SanAbility module={Module.LocationParameter} permissions={[Permission.Read]} errorState='ShowMessage'>
        {
            <>
                <div>
                    <SanPageTools
                        title={t("locationParameterPage.pageDefinitions.pageDefinitions")}
                        addNewFunction={handleAddNewClick}
                        addNewLabel={t("locationParameterPage.pageDefinitions.addNew")}
                        exportToPdfFunction={handleExportToPdf}
                        exportToPdfLabel={t("common.exportToPdf")} />
                </div>
                <div ref={tableRef}>
                    <SanTable data={listResponse?.data} tableColumns={tableColumns} tableActions={tableActionWrapper} />
                </div>
                <div>
                    <SanPagination totalCount={listResponse?.totalCount} resultCount={listResponse?.resultCount} activePage={listResponse?.pageNumber} activeLimit={listRequest.pageSize} onChange={handlePagerChange} />
                </div>
                <Stack>
                    <SanModal
                        backdrop={"static"}
                        type={settings?.panel.modalShowType}
                        open={openEditModal}
                        onClose={handleEditModalClose}
                        closeLabel="Close"
                        title={t("locationParameterPage.pageDefinitionForm.drawerTitle")}
                        body={CustomPageDefinitionForm}
                        bodyProps={customPageDefinitionFormProps}
                    />
                </Stack>
                <Stack>
                    <SanModal
                        size={enmModalSize.FULL}
                        backdrop={"static"}
                        type={settings?.panel.modalShowType}
                        open={openPagesModal}
                        onClose={handlePagesModalClose}
                        closeLabel="Close"
                        title={t("locationParameterPage.pageList.drawerTitle")}
                        body={CustomPageList}
                        bodyProps={customPageListProps}
                    />
                </Stack>
                <Stack>
                    <SanModal
                        backdrop={"static"}
                        type={B2CUiEnums.enmModalShowType.Modal}
                        open={openRemoveModal}
                        onClose={handleRemoveModalClose}
                        closeLabel="Close"
                        bodyProps={{ callBack: handleRemoveModalConfirm, typingRequired: true } as SanRemoveConfirmationProps}
                        title={t("common.attentionRequired")}
                        body={SanRemoveConfirmation}
                    />
                </Stack>
            </>
        }
    </SanAbility>;
}

export default LocationParameterPageDefinitionList;

