import React, { useState } from "react";
import { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonToolbar, Form } from "rsuite";
import { B2CApiEnums, B2CApiModels, B2CApiServiceModels, B2CApiServices, B2CUiHelpers, B2CUiModels, TVApiEnums } from "@santsg/ui-component-core";
import _ from 'lodash';
import { SanFormControl } from "components/common/SanFormControl";
import { ValidationResult, getValidationResult } from "models/types/common/ValidationResult";
import { AccepterType } from "models/enums/FormAccepterTypes";
import { ObjectValueController } from "@santsg/ui-common";
import { DataSourceFormItemFormProps } from "models/types/datasource/DataSourceFormItemFormProps";

const DataSourceFormItemForm = (props: DataSourceFormItemFormProps) => {
    const [request, setRequest] = React.useState<B2CApiServiceModels.Module.DataSource.DataSourceFormItem.DataSourceFormItemCreateRequest
        | B2CApiServiceModels.Module.DataSource.DataSourceFormItem.DataSourceFormItemUpdateRequest>({});
    const [t] = useTranslation();
    const elementTypes = B2CUiHelpers.EnumHelper.getEnumKeyValues(B2CApiEnums.enmDataSourceElementType);
    const elementCalls = B2CUiHelpers.EnumHelper.getEnumKeyValues(B2CApiEnums.enmDataSourceElementCall);
    const elementWorkTypes = B2CUiHelpers.EnumHelper.getEnumKeyValues(B2CApiEnums.enmDataSourceElementWorkType);
    const elementRegex = B2CUiHelpers.EnumHelper.getEnumKeyValues(B2CApiEnums.enmDataSourceElementRegex);
    const [validationErrors, setValidationErrors] = useState<Array<ValidationResult>>([]);
    const [elementScheme, setElementScheme] = useState<B2CApiModels.Module.DataSource.mdlDataSourceFormItemScheme>(new B2CApiModels.Module.DataSource.mdlDataSourceFormItemScheme());

    useEffect(() => {
        if (!props.dataSourceFormItem) {
            var pRequest = new B2CApiServiceModels.Module.DataSource.DataSourceFormItem.DataSourceFormItemCreateRequest();
            pRequest.formTemplateId = props.dataSourceFormTemplate?.id;
            var scheme = new B2CApiModels.Module.DataSource.mdlDataSourceFormItemScheme();
            scheme.displayOrder = 1;
            scheme.elementRegex = B2CApiEnums.enmDataSourceElementRegex.NONE;
            scheme.elementSourceCallRequest = "{}";
            scheme.elementType = B2CApiEnums.enmDataSourceElementType.TEXTBOX;
            scheme.elementWorkType = B2CApiEnums.enmDataSourceElementWorkType.NONE;
            scheme.required = false;
            setElementScheme(new B2CApiModels.Module.DataSource.mdlDataSourceFormItemScheme());
            setRequest(pRequest);
        } else {
            var pRequest = new B2CApiServiceModels.Module.DataSource.DataSourceFormItem.DataSourceFormItemUpdateRequest();
            pRequest.id = props.dataSourceFormItem.id;
            pRequest.name = props.dataSourceFormItem.name;
            pRequest.formTemplateId = props.dataSourceFormItem.formTemplate?.id;
            pRequest.scheme = props.dataSourceFormItem.scheme;
            pRequest.schemeObj = props.dataSourceFormItem.schemeObj;
            setRequest(pRequest);
            if (props.dataSourceFormItem.schemeObj)
                setElementScheme(props.dataSourceFormItem.schemeObj);
        }
    }, [props])

    //#region EVENTS
    const handleSubmit = () => {
        setValidationErrors([]);
        if (checkValidation()) {
            save();
        }
    }

    const handleJsonUpdate = (e: Object) => {
        setElementScheme({
            ...elementScheme,
            ["dataArray"]: JSON.stringify(e)
        })
    }
    //#endregion

    //#region HELPERS
    const checkValidation = () => {
        var messages = new Array<ValidationResult>();
        var result = false;
        if (ObjectValueController.isNullOrUndefinedOrEmpty(request.name)) {
            messages.push(getValidationResult(t, "name"));
        }
        if (ObjectValueController.isNullOrUndefinedOrEmpty(request.formTemplateId))
            messages.push(getValidationResult(t, "formTemplateId"));
        if (ObjectValueController.isNullOrUndefinedOrEmpty(elementScheme.elementType)) {
            messages.push(getValidationResult(t, "elementType"));
        }
        if (ObjectValueController.isNullOrUndefinedOrEmpty(elementScheme.elementWorkType)) {
            messages.push(getValidationResult(t, "elementWorkType"));
        }
        if (ObjectValueController.isNullOrUndefinedOrEmpty(elementScheme.elementRegex)) {
            messages.push(getValidationResult(t, "elementRegex"));
        }
        if (ObjectValueController.isNullOrUndefinedOrEmpty(elementScheme.displayOrder)) {
            messages.push(getValidationResult(t, "displayOrder"));
        }

        messages.length == 0 ? result = true : result = false;
        setValidationErrors(messages);

        return result;
    }
    //#endregion

    //#region API CALLS
    async function save() {
        request.scheme = JSON.stringify(elementScheme);
        var response = !props.dataSourceFormItem ? await B2CApiServices.DataSourceService.DataSourceFormItem.Create(request as B2CApiServiceModels.Module.DataSource.DataSourceFormItem.DataSourceFormItemUpdateRequest, false, true) :
            await B2CApiServices.DataSourceService.DataSourceFormItem.Update(request as B2CApiServiceModels.Module.DataSource.DataSourceFormItem.DataSourceFormItemCreateRequest, false, true);
        if (response && response.header.success && response.body) {
            props.successCallback && props.successCallback();
        }
        else {
            props.errorCallback ? props.errorCallback(response.header.messages) : console.log("failed");
        }
    }
    //#endregion

    return (
        <Fragment>
            <Form
                onChange={setRequest}>

                <SanFormControl
                    model={request}
                    onChange={setRequest}
                    name="name"
                    label={t("dataSourceFormItemPage.dataSourceFormItemForm.name")}
                    type="text"
                    placeholder={t("dataSourceFormItemPage.dataSourceFormItemForm.name")}
                    helperText={t("dataSourceFormItemPage.dataSourceFormItemForm.nameInputInfo")}
                    errors={validationErrors}
                />

                <SanFormControl
                    model={elementScheme}
                    onChange={setElementScheme}
                    name="label"
                    label={t("dataSourceFormItemPage.dataSourceFormItemForm.label")}
                    type="text"
                    placeholder={t("dataSourceFormItemPage.dataSourceFormItemForm.label")}
                    helperText={t("dataSourceFormItemPage.dataSourceFormItemForm.labelInputInfo")}
                    errors={validationErrors}
                />

                <SanFormControl
                    model={elementScheme}
                    onChange={setElementScheme}
                    name="elementType"
                    label={t("dataSourceFormItemPage.dataSourceFormItemForm.elementType")}
                    accepter={AccepterType.DROPDOWN}
                    dataSource={elementTypes}
                    placeholder={t("dataSourceFormItemPage.dataSourceFormItemForm.elementType")}
                    helperText={t("dataSourceFormItemPage.dataSourceFormItemForm.elementTypeInputInfo")}
                    errors={validationErrors}
                />

                <SanFormControl
                    model={elementScheme}
                    onChange={setElementScheme}
                    name="elementWorkType"
                    label={t("dataSourceFormItemPage.dataSourceFormItemForm.elementWorkType")}
                    accepter={AccepterType.DROPDOWN}
                    dataSource={elementWorkTypes}
                    placeholder={t("dataSourceFormItemPage.dataSourceFormItemForm.elementWorkType")}
                    helperText={t("dataSourceFormItemPage.dataSourceFormItemForm.elementWorkTypeInputInfo")}
                    errors={validationErrors}
                />

                {
                    elementScheme.elementType == B2CApiEnums.enmDataSourceElementType.DROPDOWN
                    &&
                    elementScheme.elementWorkType == B2CApiEnums.enmDataSourceElementWorkType.NONE
                    &&
                    <Fragment>
                        <SanFormControl
                            model={elementScheme}
                            name="dataArray"
                            label={t("dataSourceFormItemPage.dataSourceFormItemForm.dataArray")}
                            onUpdate={handleJsonUpdate}
                            accepter={AccepterType.JSONEDITOR}
                            placeholder={t("dataSourceFormItemPage.dataSourceFormItemForm.dataArray")}
                            helperText={t("dataSourceFormItemPage.dataSourceFormItemForm.dataArrayInputInfo")}
                            errors={validationErrors}
                        />

                        <SanFormControl
                            model={elementScheme}
                            onChange={setElementScheme}
                            name="dataIdentifier"
                            label={t("dataSourceFormItemPage.dataSourceFormItemForm.dataIdentifier")}
                            type="text"
                            placeholder={t("dataSourceFormItemPage.dataSourceFormItemForm.dataIdentifier")}
                            helperText={t("dataSourceFormItemPage.dataSourceFormItemForm.dataIdentifierInputInfo")}
                            errors={validationErrors}
                        />

                        <SanFormControl
                            model={elementScheme}
                            onChange={setElementScheme}
                            name="dataLabel"
                            label={t("dataSourceFormItemPage.dataSourceFormItemForm.dataLabel")}
                            type="text"
                            placeholder={t("dataSourceFormItemPage.dataSourceFormItemForm.dataLabel")}
                            helperText={t("dataSourceFormItemPage.dataSourceFormItemForm.dataLabelInputInfo")}
                            errors={validationErrors}
                        />

                    </Fragment>

                }

                <SanFormControl
                    model={elementScheme}
                    onChange={setElementScheme}
                    name="elementRegex"
                    label={t("dataSourceFormItemPage.dataSourceFormItemForm.elementRegex")}
                    accepter={AccepterType.DROPDOWN}
                    dataSource={elementRegex}
                    placeholder={t("dataSourceFormItemPage.dataSourceFormItemForm.elementRegex")}
                    helperText={t("dataSourceFormItemPage.dataSourceFormItemForm.elementRegexInputInfo")}
                    errors={validationErrors}
                />
                <SanFormControl
                    model={elementScheme}
                    onChange={setElementScheme}
                    name="displayOrder"
                    label={t("dataSourceFormItemPage.dataSourceFormItemForm.displayOrder")}
                    type="text"
                    placeholder={t("dataSourceFormItemPage.dataSourceFormItemForm.displayOrder")}
                    helperText={t("dataSourceFormItemPage.dataSourceFormItemForm.displayOrderInputInfo")}
                    errors={validationErrors}
                />

                <SanFormControl
                    model={elementScheme}
                    onChange={setElementScheme}
                    name="elementSourceCallRequest"
                    label={t("dataSourceFormItemPage.dataSourceFormItemForm.elementSourceCallRequest")}
                    accepter={AccepterType.TEXTAREA}
                    placeholder={t("dataSourceFormItemPage.dataSourceFormItemForm.elementSourceCallRequest")}
                    helperText={t("dataSourceFormItemPage.dataSourceFormItemForm.elementSourceCallRequest")}
                    errors={validationErrors}
                />



                <SanFormControl
                    model={elementScheme}
                    onChange={setElementScheme}
                    name="required"
                    label={t("dataSourceFormItemPage.dataSourceFormItemForm.required")}
                    accepter={AccepterType.DROPDOWN}
                    dataSource={B2CUiHelpers.EnumHelper.getTrueFalseAsPair([t("dataSourceFormItemPage.dataSourceFormItemForm.required"), t("dataSourceFormItemPage.dataSourceFormItemForm.notRequired")])}
                    placeholder={t("dataSourceFormItemPage.dataSourceFormItemForm.required")}
                    helperText={t("dataSourceFormItemPage.dataSourceFormItemForm.requiredInputInfo")}
                    errors={validationErrors}
                />

                <ButtonToolbar>
                    <Button appearance="primary" onClick={handleSubmit}>
                        {t("common.submit")}
                    </Button>
                </ButtonToolbar>
            </Form>
        </Fragment>
    )
}
export { DataSourceFormItemForm };