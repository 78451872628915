import { Fragment, useEffect } from "react";
import { Dropdown, Pagination, Stack } from 'rsuite';
import _ from 'lodash';
import { nanoid } from "@reduxjs/toolkit";
import { B2CUiEnums, B2CUiModels } from "@santsg/ui-component-core";
import { SanPaginationProps } from "models/types/san-elements/SanPaginationProps";

function SanPagination(props: SanPaginationProps) {
    const limitOptions = [10, 20, 50, 100];

    useEffect(() => {
    }, [props]);

    //#region EVENTS
    const handleLimitChange = (limit: number) => {
        if (props.activePage != null) {
            props.onChange(new B2CUiModels.mdlPagerProps(props.activePage, limit))
        }
    }

    const handlePageChange = (page: number) => {
        props.onChange(new B2CUiModels.mdlPagerProps(page, props.activeLimit!));
    }
    //#endregion

    return (
        <Fragment key={nanoid()}>
            {
                props.activePage
                    ?
                    <Stack direction="row" spacing={15}>
                        <Pagination
                            layout={['pager', 'skip']}
                            size={B2CUiEnums.enmSizeType.XSmall}
                            prev
                            next
                            first
                            last
                            ellipsis
                            boundaryLinks
                            total={props.resultCount ? props.resultCount : 0}
                            maxButtons={2}
                            limit={props.activeLimit}
                            activePage={props.activePage}
                            onChangePage={handlePageChange}
                            onChangeLimit={handleLimitChange}
                        />

                        <Stack direction="row" spacing={15} justifyContent="flex-end" alignItems="center">
                            <span>Page Size</span>
                            <Dropdown title={props.activeLimit} activeKey={props.activeLimit} defaultValue={props.activeLimit} trigger="click" placement="topStart">
                                {
                                    limitOptions.map((size: number) => (
                                        <Dropdown.Item onSelect={handleLimitChange} key={nanoid()} active={size == props.activeLimit} eventKey={size}>{size}</Dropdown.Item>
                                    ))
                                }
                            </Dropdown>
                        </Stack>
                    </Stack>
                    : <></>
            }
        </Fragment>
    )
}

export default SanPagination;