import { Fragment, useEffect, useState } from "react";
import { Button, Table, } from 'rsuite';
import { Cell, Column, HeaderCell } from "rsuite-table";
import _ from 'lodash';
import moment from 'moment';
import { enmActionCellType } from "models/enums/ActionCellType";
import MoreIcon from '@rsuite/icons/legacy/More';
import { nanoid } from "@reduxjs/toolkit";
import { SanTableProps } from "models/types/san-elements/SanTableProps";
import { SanDynamicObject } from "models/types/san-elements/SanDynamicObject";
import { ActionCellProps } from "models/types/common/ActionCellProps";
import { SanColumnItem } from "models/types/san-elements/SanColumnItem";
import { SanActionItem } from "models/types/san-elements/SanActionItem";

function SanStandardTable<T extends object>(props: SanTableProps<T>) {
    const [tableData, setTableData] = useState<SanDynamicObject[]>();

    useEffect(() => {
        var data = prepareDataColumns();
        if (data)
            setTableData(data);
    }, [props]);

    //#region ELEMENTS
    const CompactCell = (props: any) => <Cell {...props} key={props.index} style={{ padding: 6 }} />;

    const ActionCell = (props: ActionCellProps) => {
        return (
            <Button color={props.action.color} appearance={props.action.apperance} onClick={() => props.action.onClick && props.action.onClick(props.rowData.id)}>
                {props.action.label}
            </Button>
        );
    };

    const ImageCell = (props: any) => (
        <Cell {...props} style={{ padding: 0 }}>
            <div
                style={{
                    width: 60,
                    height: 40,
                    background: '#f5f5f5',
                    borderRadius: 1,
                    marginTop: 2,
                    overflow: 'hidden',
                    display: 'inline-block'
                }}
            >
                <img src={props.rowData[props.dataKey]} style={{ width: "100%", height: "100%", objectFit: "cover" }} />
            </div>
        </Cell>
    );
    //#endregion

    //#region HELPERS
    const prepareDataColumns = (): SanColumnItem[] | undefined => {
        return props.data && (props.data as SanDynamicObject[]).map(obj => {
            const newObj: SanDynamicObject = {};
            for (const key in obj) {
                var tableConfiguration = _.find(props.tableColumns, function (col) {
                    if (col.key?.includes(".")) {
                        return col.key.split(".")[0] == key;
                    }
                    else {
                        return col.key == key;
                    }
                });
                var keyHasNested = false;
                var nestedKey = "";
                if (tableConfiguration) {
                    var objectCheck = false;
                    if (tableConfiguration.key?.includes(".")) {
                        keyHasNested = true;
                    }

                    if (tableConfiguration.type == "enum") {
                        if (keyHasNested) {
                            nestedKey = tableConfiguration.key!.split(".")[1];
                            objectCheck = Object.values(tableConfiguration?.model).includes(obj[key][nestedKey!]);
                        }
                        objectCheck = Object.values(tableConfiguration?.model).includes(obj[key]);
                    }
                    else {
                        if (keyHasNested)
                            nestedKey = tableConfiguration.key!.split(".")[1];
                        objectCheck = true;
                        nestedKey = tableConfiguration.key!.split(".")[1];
                    }

                    if (tableConfiguration.type != Date && objectCheck) {
                        var value = "";
                        if (keyHasNested) {
                            if (tableConfiguration.type == 'enum') {
                                value = tableConfiguration.model[obj[key][nestedKey]];
                                newObj[key] = value;
                            }
                            else {
                                value = obj[key][nestedKey];
                                newObj[key] = value;
                            }
                        } else {
                            if (tableConfiguration.type == "enum") {
                                value = tableConfiguration.model[obj[key]];
                                newObj[key] = value.replace(/([a-z])([A-Z])/g, '$1 $2');
                            } else if (tableConfiguration.type == Boolean) {
                                value = obj[key];
                                newObj[key] = value ? "✔" : "-";
                            }
                            else {
                                value = obj[key];
                                newObj[key] = value;
                            }
                        }

                    } else if (tableConfiguration.type == Date)
                        newObj[key] = moment(obj[key]).format("YYYY-MM-DD HH:mm:ss");
                    else
                        newObj[key] = obj[key];
                }
            }
            return newObj;
        });
    }
    //#endregion

    return (
        <Fragment key={nanoid()}>
            <Table data={tableData} autoHeight
                affixHeader
                bordered
                cellBordered>
                {
                    props.tableColumns && props.tableColumns.map((data: SanColumnItem, index: number) => (
                        <Fragment key={index}>
                            {
                                data && data.visible &&
                                <Column flexGrow={1} fullText align="center">
                                    <HeaderCell>{data.label ? data.label : data.key}</HeaderCell>
                                    {
                                        data.type == "image"
                                            ?
                                            <ImageCell dataKey={!data.key?.includes(".") ? data.key : data.key.split(".")[0]} />
                                            :
                                            <CompactCell dataKey={!data.key?.includes(".") ? data.key : data.key.split(".")[0]} />
                                    }
                                </Column>
                            }
                        </Fragment>
                    ))
                }

                {
                    props.tableActions && props.tableActions.actionCellType == enmActionCellType.Row
                    &&
                    <Column flexGrow={1} fixed>
                        <HeaderCell>
                            <MoreIcon />
                        </HeaderCell>

                        <Cell style={{ display: "flex", alignItems: "center" }} className="custom-cell-action" dataKey="id">
                            {(rowData: any) => (
                                props.tableActions?.actions?.map((action: SanActionItem, index: number) => (
                                    (action.conditions == null || action.conditions(rowData.id)) ? (
                                        <Fragment key={nanoid()}>
                                            <div className="custom-cell">
                                                <ActionCell rowData={rowData} action={action} key={index} />
                                            </div>
                                        </Fragment>
                                    ) : (
                                        <Fragment key={nanoid()}></Fragment>
                                    )
                                ))
                            )}
                        </Cell>
                    </Column>
                }

            </Table>
        </Fragment>

    )
}

export default SanStandardTable;