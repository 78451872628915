import React, { useState } from "react";
import { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonToolbar, Form } from "rsuite";
import { B2CApiEnums, B2CApiModels, B2CApiServiceModels, B2CApiServices, B2CUiHelpers, B2CUiModels } from "@santsg/ui-component-core";
import _ from 'lodash';
import { SanFormControl } from "components/common/SanFormControl";
import { ObjectValueController } from "@santsg/ui-common";
import { ValidationResult, getValidationResult } from "models/types/common/ValidationResult";
import { AccepterType } from "models/enums/FormAccepterTypes";
import { EventFormProps } from "models/types/event/EventFormProps";
import { getIdNamePairAsArray } from "utils/functions/ObjectMapper";
import { SanDynamicObject } from "models/types/san-elements/SanDynamicObject";

const EventForm = (props: EventFormProps) => {
    const [request, setRequest] = React.useState<B2CApiServiceModels.Module.Event.EventCreateRequest
        | B2CApiServiceModels.Module.Event.EventUpdateRequest>({});
    const [t] = useTranslation();
    const triggerTypes = B2CUiHelpers.EnumHelper.getEnumKeyValues(B2CApiEnums.enmEventTriggerType);
    const actionTypes = B2CUiHelpers.EnumHelper.getEnumKeyValues(B2CApiEnums.enmEventActionType);
    const targetTypes = B2CUiHelpers.EnumHelper.getEnumKeyValues(B2CApiEnums.enmEventTargetType);
    const [validationErrors, setValidationErrors] = useState<Array<ValidationResult>>([]);
    const [receivers, setReceivers] = useState<Array<B2CUiModels.mdlIdNameCodeValue>>();
    const [receiverList, setReceiverList] = useState<Array<B2CApiModels.Module.ReceiverModel.mdlReceiver>>();

    useEffect(() => {
        if (!props.event) {
            setRequest(new B2CApiServiceModels.Module.Event.EventCreateRequest());
        } else {
            var pRequest = new B2CApiServiceModels.Module.Event.EventUpdateRequest();
            pRequest.id = props.event.id;
            pRequest.eventActionType = props.event.eventActionType;
            pRequest.eventTargetType = props.event.eventTargetType;
            pRequest.eventTriggerType = props.event.eventTriggerType;
            pRequest.eventParams = props.event.eventParams;
            setRequest(pRequest);

        }

        if (receivers == null) {
            B2CApiServices.ReceiverService.List(new B2CApiServiceModels.Module.Receiver.ReceiverListRequest(), false, true).then((response) => {
                if (response.header && response.header.success) {
                    setReceiverList(response.body?.data);
                    if (props.event && props.event.eventActionType)
                        filterReceiversByActionType(props.event.eventActionType, response.body?.data);
                }
            })
        }


    }, [props])

    //#region EVENTS
    const handleSubmit = () => {
        setValidationErrors([]);
        if (checkValidation()) {
            saveEvent();
        }
    }

    const handleEventActionTypeChange = (obj: SanDynamicObject) => {
        var value = obj["eventActionType"];
        setRequest({
            ...request,
            ["eventActionType"]: value
        });
        filterReceiversByActionType(value);
    }
    //#endregion

    //#region HELPERS
    const checkValidation = () => {
        var messages = new Array<ValidationResult>();
        var result = false;
        if (ObjectValueController.isNullOrUndefinedOrEmpty(request.eventActionType)) {
            messages.push(getValidationResult(t, "eventActionType"));
        }
        if (ObjectValueController.isNullOrUndefinedOrEmpty(request.eventTriggerType)) {
            messages.push(getValidationResult(t, "eventTriggerType"));
        }
        if (ObjectValueController.isNullOrUndefinedOrEmpty(request.eventTargetType)) {
            messages.push(getValidationResult(t, "eventTargetType"));
        }

        messages.length == 0 ? result = true : result = false;
        setValidationErrors(messages);

        return result;
    }

    const filterReceiversByActionType = (eventActionType: number, pReceiverList?: Array<B2CApiModels.Module.ReceiverModel.mdlReceiver>) => {
        var filtered = _.filter(receiverList == null ? pReceiverList : receiverList, function (receiver) { return receiver.receiverType == eventActionType });
        var pairs = getIdNamePairAsArray((filtered as SanDynamicObject[]), ["id", "name"]);

        setReceivers(pairs);
    }
    //#endregion

    //#region API CALLS
    async function saveEvent() {
        var response = props.event ? await B2CApiServices.EventService.Update(request as B2CApiServiceModels.Module.Event.EventUpdateRequest, false, true) :
            await B2CApiServices.EventService.Create(request as B2CApiServiceModels.Module.Event.EventCreateRequest, false, true);
        if (response && response.header.success && response.body) {
            props.successCallback && props.successCallback();
        }
        else {
            props.errorCallback ? props.errorCallback(response.header.messages) : console.log("failed");
        }
    }
    //#endregion

    return (
        <Fragment>
            <Form
                onChange={setRequest}>
                <SanFormControl
                    model={request}
                    onChange={setRequest}
                    name="eventTriggerType"
                    label={t("eventPage.eventForm.eventTriggerType")}
                    accepter={AccepterType.DROPDOWN}
                    dataSource={triggerTypes}
                    placeholder={t("eventPage.eventForm.eventTriggerType")}
                    helperText={t("eventPage.eventForm.eventTriggerTypeInputInfo")}
                    errors={validationErrors}
                />

                <SanFormControl
                    model={request}
                    onChange={handleEventActionTypeChange}
                    name="eventActionType"
                    label={t("eventPage.eventForm.eventActionType")}
                    accepter={AccepterType.DROPDOWN}
                    dataSource={actionTypes}
                    placeholder={t("eventPage.eventForm.eventActionType")}
                    helperText={t("eventPage.eventForm.eventActionTypeInputInfo")}
                    errors={validationErrors}
                />

                <SanFormControl
                    model={request}
                    onChange={setRequest}
                    name="eventTargetType"
                    label={t("eventPage.eventForm.eventTargetType")}
                    accepter={AccepterType.DROPDOWN}
                    dataSource={targetTypes}
                    placeholder={t("eventPage.eventForm.eventTargetType")}
                    helperText={t("eventPage.eventForm.eventTargetTypeInputInfo")}
                    errors={validationErrors}
                />

                {
                    request.eventTargetType == B2CApiEnums.enmEventTargetType.CmsReceiver
                    &&
                    <SanFormControl
                        model={request}
                        onChange={setRequest}
                        name="eventParams"
                        dataSource={receivers}
                        accepter={AccepterType.CHECKBOXPICKER}
                        placeholder={t("eventPage.eventForm.receivers")}
                        label={t("eventPage.eventForm.receivers")}
                        helperText={t("eventPage.eventForm.receiversInputInfo")}
                        errors={validationErrors}
                    />
                }

                <ButtonToolbar>
                    <Button appearance="primary" onClick={handleSubmit}>
                        {t("common.submit")}
                    </Button>
                </ButtonToolbar>
            </Form>
        </Fragment>
    )
}
export { EventForm };