import { B2CUiModels } from "@santsg/ui-component-core";
import _ from 'lodash';
import { SanDynamicObject } from "models/types/san-elements/SanDynamicObject";
import { LabelValuePair } from "models/types/common/LabelValuePair";

export function getIdNamePairAsArray(dataList: Array<SanDynamicObject>, keyMap: string[], concatKeys?: boolean, splitter?: string, joiner?: string) {
    var idNamePairs = new Array<B2CUiModels.mdlIdNameCodeValue>();
    _.each(dataList, function (data) {
        var mapId = keyMap[0];
        var mapValue = keyMap[1];

        var pair = new B2CUiModels.mdlIdNameCodeValue();
        pair.id = data[mapId];
        if (concatKeys && splitter && joiner) {
            var concatStringArray: string[] = [];
            _.each(mapValue.split(splitter), function (m) {
                if (m.includes(".")) {
                    var childSelector = m.split(".");
                    var childItems = data[childSelector[0]];
                    if (childItems) {
                        concatStringArray.push(childItems[childSelector[1]]);
                    }
                } else {
                    concatStringArray.push(data[m]);
                }
            })
            pair.name = concatStringArray.join(joiner);
        } else {
            pair.name = data[mapValue];
        }

        idNamePairs.push(pair);
    })
    return idNamePairs;
}

export function getLabelValuePairAsArray(dataList: Array<SanDynamicObject>, keyMap: string[]) {
    var labelValuePairs = new Array<LabelValuePair>();
    _.each(dataList, function (data) {
        var mapLabel = keyMap[0];
        var mapValue = keyMap[1];
        var pair = new LabelValuePair();
        pair.label = data[mapLabel];
        pair.value = data[mapValue];
        labelValuePairs.push(pair);
    })
    return labelValuePairs;
}