import { Link } from 'react-router-dom';
import { PfImage } from '@profabric/react-components';
import styled from 'styled-components';
import { SidebarSearch } from 'components/sidebar-search/SidebarSearch';
import { useAppSelector } from 'store/hooks';
import MenuWrapper from 'components/menu-item/MenuWrapper';
import { menuItems } from 'utils/MenuDefinitions';


const StyledBrandImage = styled(PfImage)`
  float: left;
  line-height: 0.8;
  margin: -1px 8px 0 6px;
  opacity: 0.8;
  --pf-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
    0 6px 6px rgba(0, 0, 0, 0.23) !important;
`;

const StyledUserImage = styled(PfImage)`
  --pf-box-shadow: 0 3px 6px #00000029, 0 3px 6px #0000003b !important;
`;

const MenuSidebar = () => {
  const auth = useAppSelector(state => state.auth);
  const sidebarSkin = useAppSelector(state => state.ui?.sidebarSkin);
  const menuItemFlat = useAppSelector(state => state.ui?.menuItemFlat);
  const menuChildIndent = useAppSelector(state => state.ui?.menuChildIndent);

  return (
    <aside className={`main-sidebar elevation-4 ${sidebarSkin}`}>
      <Link to="/" className="brand-link">
        <StyledBrandImage
          src="/img/logo.png"
          alt="AdminLTE Logo"
          width={33}
          height={33}
          rounded
        />
        <span className="brand-text font-weight-light">B2C Panel</span>
      </Link>
      <div className="sidebar">
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          {/* <div className="image">
            <StyledUserImage
              src={authentication?.userInfo?.id}
              fallbackSrc="/img/default-profile.png"
              alt="User"
              width={34}
              height={34}
              rounded
            />
          </div> */}
          <div className="info">
            <Link to="/profile" className="d-block">
              {auth?.user?.userInfo?.firstName + ' ' + auth?.user?.userInfo?.lastName}
            </Link>
          </div>
        </div>

        <div className="form-inline">
          <SidebarSearch />
        </div>

        <nav className="mt-2" style={{ overflowY: 'hidden' }}>
          <ul
            className={`nav nav-pills nav-sidebar flex-column${menuItemFlat ? ' nav-flat' : ''
              }${menuChildIndent ? ' nav-child-indent' : ''}`}
            role="menu"
          >
            <MenuWrapper items={menuItems} />
            {/* {relatedMenus.map((menuItem:IRoute,mi:number)=>(
              <MenuItem
               key={mi}
               menuItem={menuItem}
               />
            ))} */}
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default MenuSidebar;
