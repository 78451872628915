import React, { useState } from "react";
import { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonToolbar, Form } from "rsuite";
import { B2CApiEnums, B2CApiServiceModels, B2CApiServices, B2CUiEnums, B2CUiHelpers, B2CUiModels, TVApiEnums, TVApiModels, TVApiServiceModels, TVApiServices } from "@santsg/ui-component-core";
import _ from 'lodash';
import { SanFormControl } from "components/common/SanFormControl";
import { ObjectValueController } from "@santsg/ui-common";
import { ValidationResult, getValidationResult } from "models/types/common/ValidationResult";
import { LocationParameterFormProps } from "models/types/location-parameter/LocationParameterFormProps";
import { enmAutoCompleteWorkType } from "models/enums/AutoCompleteWorkType";
import { AccepterType } from "models/enums/FormAccepterTypes";
import { SanDynamicObject } from "models/types/san-elements/SanDynamicObject";

const LocationParameterForm = (props: LocationParameterFormProps) => {
    const [request, setRequest] = React.useState<B2CApiServiceModels.Module.LocationParameter.LocationParameterCreateRequest
        | B2CApiServiceModels.Module.LocationParameter.LocationParameterUpdateRequest>({});
    const [t] = useTranslation();
    const [validationErrors, setValidationErrors] = useState<Array<ValidationResult>>([]);
    const [autoCompleteArrivalDataSource, setAutoCompleteArrivalDataSource] = useState<B2CUiModels.mdlIdNameCodeValue[]>();
    const packTypes = B2CUiHelpers.EnumHelper.getEnumKeyValues(B2CApiEnums.enmPackType);
    const [arrivals, setArrivals] = useState<Array<B2CUiModels.mdlIdNameCodeValue>>(new Array<B2CUiModels.mdlIdNameCodeValue>());
    const [departures, setDepartures] = useState<Array<B2CUiModels.mdlIdNameCodeValue>>(new Array<B2CUiModels.mdlIdNameCodeValue>());

    useEffect(() => {
        if (!props.locationParameter) {
            setRequest(new B2CApiServiceModels.Module.LocationParameter.LocationParameterCreateRequest());
        } else {
            var pRequest = new B2CApiServiceModels.Module.LocationParameter.LocationParameterUpdateRequest();
            pRequest.id = props.locationParameter.id;
            pRequest.arrival = JSON.stringify(props.locationParameter.arrival);
            pRequest.departure = JSON.stringify(props.locationParameter.departure);
            pRequest.checkInFrom = props.locationParameter.checkInFrom;
            pRequest.dayRange = props.locationParameter.dayRange;
            pRequest.night = props.locationParameter.night;
            pRequest.packType = props.locationParameter.packType;
            setRequest(pRequest);
        }

        prepareDefaultOptions();
    }, [props])

    const prepareDefaultOptions = async () => {
        if (props.locationParameter && props.locationParameter.packType == B2CApiEnums.enmPackType.Package && departures.length == 0) {
            var departuresResponse = await getDepartures();
            var formattedDepartures = new Array<B2CUiModels.mdlIdNameCodeValue>();
            _.each(departuresResponse, function (item) {
                var dropDownItem = new B2CUiModels.mdlIdNameCodeValue();
                dropDownItem.id = item.id;
                dropDownItem.value = JSON.stringify(item);
                dropDownItem.name = item.name;
                formattedDepartures.push(dropDownItem);
            });
            setDepartures(formattedDepartures);
            var departure = props.locationParameter.departure;
            if (departure) {
                var arrivalResult = await getArrivals(departure, TVApiEnums.enmProductType.HolidayPackage);
                var formattedArrivals = new Array<B2CUiModels.mdlIdNameCodeValue>();
                _.each(arrivalResult, function (item) {
                    var dropDownItem = new B2CUiModels.mdlIdNameCodeValue();
                    dropDownItem.id = item.id;
                    dropDownItem.value = JSON.stringify(item);
                    dropDownItem.name = item.name;
                    formattedArrivals.push(dropDownItem);
                })
                setArrivals(formattedArrivals);
            }
        }
    };

    const handleSubmit = () => {
        setValidationErrors([]);
        if (checkValidation()) {
            save();
        }
    }

    //#region HELPERS
    const checkValidation = () => {
        var messages = new Array<ValidationResult>();
        var result = false;
        if (ObjectValueController.isNullOrUndefinedOrEmpty(request.arrival)) {
            messages.push(getValidationResult(t, "arrival"));
        }
        if (ObjectValueController.isNullOrUndefinedOrEmpty(request.night)) {
            messages.push(getValidationResult(t, "night"));
        }
        if (ObjectValueController.isNullOrUndefinedOrEmpty(request.dayRange)) {
            messages.push(getValidationResult(t, "dayRange"));
        }
        if (ObjectValueController.isNullOrUndefinedOrEmpty(request.checkInFrom)) {
            messages.push(getValidationResult(t, "checkInFrom"));
        }
        if (ObjectValueController.isNullOrUndefinedOrEmpty(request.packType)) {
            messages.push(getValidationResult(t, "packType"));
        }
        if (ObjectValueController.isNullOrUndefinedOrEmpty(request.departure) && (request.packType == B2CApiEnums.enmPackType.Package)) {
            messages.push(getValidationResult(t, "departure"));
        }

        messages.length == 0 ? result = true : result = false;
        setValidationErrors(messages);

        return result;
    }

    //#endregion

    //#region API CALLS
    async function save() {
        var response = props.locationParameter ? await B2CApiServices.LocationParameterService.Update(request as B2CApiServiceModels.Module.LocationParameter.LocationParameterUpdateRequest, false, true) :
            await B2CApiServices.LocationParameterService.Create(request as B2CApiServiceModels.Module.LocationParameter.LocationParameterCreateRequest, false, true);
        if (response && response.header.success && response.body) {
            props.successCallback && props.successCallback();
        }
        else {
            props.errorCallback ? props.errorCallback(response.header.messages) : console.log("failed");
        }
    }
    //#endregion

    const getDepartures = async () => {
        var request = new TVApiServiceModels.ProductModels.GetDeparturesRequest();
        request.culture = 'en-US';
        request.productType = TVApiEnums.enmProductType.HolidayPackage;
        var result = await TVApiServices.ProductService.GetDepartures(request);
        if (result && result.header?.success && result.body && result.body.locations) {
            return _.filter(result.body.locations, function (loc) { return loc.type != TVApiEnums.enmLocationType.Country });
        } else {
            try {
                var pResult = result as Array<TVApiServiceModels.BaseModels.ServiceResponse<TVApiServiceModels.ProductModels.GetDeparturesResponse>>;
                var concatList = new Array<TVApiModels.mdlLocation>();
                _.each(pResult, function (res) {
                    if (res.header && res.header.success && res.body && res.body.locations)
                        concatList = concatList.concat(res.body.locations);
                })
                return _.filter(_.uniq(concatList), function (c) { return c.type != TVApiEnums.enmLocationType.Country });
            }
            catch {
                return [];
            }
        }
    }

    const handleOnlyHotelArrivalSelect = (result: B2CUiModels.mdlIdNameCodeValue) => {
        var arrivalResult = result.value as TVApiModels.mdlAutoCompleteItem;
        setRequest({ ...request, ["arrival"]: JSON.stringify(arrivalResult), ["provider"]: arrivalResult.provider });
    };

    const handleArrivalAutoCompleteChange = async (searchText: string) => {
        var productType = TVApiEnums.enmProductType.Hotel;
        var result = await getArrivalAutoComplete(productType, searchText);
        if (result) {
            var orderType = B2CUiEnums.enmAutoCompleteOrder.locationHotelContent;
            var orderedList = B2CUiHelpers.AutoCompleteHelper.convertDataToDropDownItemArray(result, productType, orderType);
            var labelValuePairs = new Array<B2CUiModels.mdlIdNameCodeValue>();
            _.each(orderedList, function (ol) {
                var pair = new B2CUiModels.mdlIdNameCodeValue();
                pair.name = ol.displayText;
                pair.value = ol.value;
                labelValuePairs.push(pair);
            });
            setAutoCompleteArrivalDataSource(labelValuePairs);
        }
    };

    async function handleHolpackDepartureSelect(result: SanDynamicObject) {
        var parsedResult = JSON.parse(result.departure);

        var arrivalResponse = await getArrivals(parsedResult as TVApiModels.mdlLocation, TVApiEnums.enmProductType.HolidayPackage);
        var formattedArrivals = new Array<B2CUiModels.mdlIdNameCodeValue>();
        _.each(arrivalResponse, function (item) {
            var dropDownItem = new B2CUiModels.mdlIdNameCodeValue();
            dropDownItem.id = item.id;
            dropDownItem.value = JSON.stringify(item);
            dropDownItem.name = item.name;
            formattedArrivals.push(dropDownItem);
        })
        setRequest({
            ...request,
            ["departure"]: result.departure,
            ["arrival"]: undefined
        });
        setArrivals(formattedArrivals);
    }

    function handleHolpackArrivalSelect(result: SanDynamicObject) {
        var arrivalResult = JSON.parse(result.arrival) as TVApiModels.mdlLocation;
        setRequest({
            ...request,
            ["arrival"]: result.arrival,
            ["provider"]: arrivalResult.provider
        });
    }


    async function getArrivalAutoComplete(productType: TVApiEnums.enmProductType, searchKey: string = "") {
        var request = new TVApiServiceModels.ProductModels.GetArrivalAutoCompleteRequest(productType, searchKey, B2CUiEnums.enmCulture.enUS);
        var response = await TVApiServices.ProductService.GetArrivalAutoComplete(request);
        if (response.header?.success) {
            return response.body?.items;
        }
        return null;
    }


    async function getArrivals(departure: TVApiModels.mdlLocation, productType: TVApiEnums.enmProductType) {
        var request = new TVApiServiceModels.ProductModels.GetArrivalsRequest(productType, B2CUiEnums.enmCulture.enUS);
        request.departureLocations = new Array<TVApiModels.mdlLocation>();
        request.departureLocations.push(departure);
        if (productType == TVApiEnums.enmProductType.Transfer) {
            request.includeSubLocations = true;
        }
        var result = await TVApiServices.ProductService.GetArrivals(request)
        if (result && result.header?.success && result.body && result.body.locations) {
            if (productType == TVApiEnums.enmProductType.HolidayPackage)
                return _.filter(result.body.locations, function (loc) { return loc.type != TVApiEnums.enmLocationType.Country });
        } else {
            return [];
        }
    }

    const handlePackTypeChange = async (value: B2CApiServiceModels.Module.LocationParameter.LocationParameterUpdateRequest) => {
        setRequest({ ...request, ["packType"]: value.packType });
        if (value.packType == B2CApiEnums.enmPackType.Package) {
            var departuresResponse = await getDepartures();
            var formattedDepartures = new Array<B2CUiModels.mdlIdNameCodeValue>();
            _.each(departuresResponse, function (item) {
                var dropDownItem = new B2CUiModels.mdlIdNameCodeValue();
                dropDownItem.id = item.id;
                dropDownItem.value = JSON.stringify(item);
                dropDownItem.name = item.name;
                formattedDepartures.push(dropDownItem);
            });
            setDepartures(formattedDepartures);
        }
    }


    return (
        <Fragment>
            <Form
                onChange={setRequest}>

                <SanFormControl
                    model={request}
                    onChange={handlePackTypeChange}
                    name="packType"
                    dataSource={packTypes}
                    accepter={AccepterType.DROPDOWN}
                    placeholder={t("locationParameterPage.locationParameterForm.packType")}
                    label={t("locationParameterPage.locationParameterForm.packType")}
                    helperText={t("locationParameterPage.locationParameterForm.packTypeInputInfo")}
                    errors={validationErrors}
                />

                {
                    request.packType == B2CApiEnums.enmPackType.Hotel
                    &&
                    <SanFormControl
                        model={request}
                        onSelect={handleOnlyHotelArrivalSelect}
                        autoCompleteWorkType={enmAutoCompleteWorkType.Location}
                        name="arrival"
                        label={t("locationParameterPage.locationParameterForm.arrivalLocation")}
                        accepter={AccepterType.AUTOCOMPLETE}
                        onSearch={handleArrivalAutoCompleteChange}
                        dataSource={autoCompleteArrivalDataSource}
                        placeholder={t("locationParameterPage.locationParameterForm.arrivalLocationPlaceHolder")}
                        helperText={t("locationParameterPage.locationParameterForm.arrivalLocationInputInfo")}
                        errors={validationErrors}
                        parseValue={true}
                    />
                }

                {
                    request.packType == B2CApiEnums.enmPackType.Package
                    &&
                    <Fragment key={"hol-pack-wrap"}>
                        <SanFormControl
                            model={request}
                            onChange={handleHolpackDepartureSelect}
                            name={"departure"}
                            useCustomSelector={true}
                            customSelectorKey={"id"}
                            dataSource={departures}
                            accepter={AccepterType.DROPDOWN}
                            placeholder={t("locationParameterPage.locationParameterForm.departureLocation")}
                            label={t("dataSourceListItemPage.dataSourceListItemForm.departureLocation")}
                            helperText={t("dataSourceListItemPage.dataSourceListItemForm.departureLocationInputInfo")}
                            errors={validationErrors}
                        />

                        <SanFormControl
                            model={request}
                            onChange={handleHolpackArrivalSelect}
                            name={"arrival"}
                            useCustomSelector={true}
                            customSelectorKey={"id"}
                            dataSource={arrivals}
                            accepter={AccepterType.DROPDOWN}
                            placeholder={t("locationParameterPage.locationParameterForm.arrivalLocation")}
                            label={t("locationParameterPage.locationParameterForm.arrivalLocation")}
                            helperText={t("locationParameterPage.locationParameterForm.arrivalLocationInputInfo")}
                            errors={validationErrors}
                        />
                    </Fragment>
                }

                <SanFormControl
                    model={request}
                    onChange={setRequest}
                    name="checkInFrom"
                    receiveFormat={"YYYY-MM-DD"}
                    label={t("locationParameterPage.locationParameterForm.checkInFrom")}
                    accepter={AccepterType.DATEPICKER}
                    format={"dd.MM.yyyy"}
                    placeholder={t("locationParameterPage.locationParameterForm.checkInFrom")}
                    helperText={t("locationParameterPage.locationParameterForm.checkInFromInputInfo")}
                    errors={validationErrors}
                />

                <SanFormControl
                    model={request}
                    onChange={setRequest}
                    name="night"
                    label={t("locationParameterPage.locationParameterForm.night")}
                    accepter={AccepterType.NUMBERBOX}
                    placeholder={t("locationParameterPage.locationParameterForm.night")}
                    helperText={t("locationParameterPage.locationParameterForm.nightInputInfo")}
                    errors={validationErrors}
                />

                <SanFormControl
                    model={request}
                    onChange={setRequest}
                    name="dayRange"
                    label={t("locationParameterPage.locationParameterForm.dayRange")}
                    accepter={AccepterType.NUMBERBOX}
                    placeholder={t("locationParameterPage.locationParameterForm.dayRange")}
                    helperText={t("locationParameterPage.locationParameterForm.dayRangeInputInfo")}
                    errors={validationErrors}
                />
                <ButtonToolbar>
                    <Button appearance="primary" onClick={handleSubmit}>
                        {t("common.submit")}
                    </Button>
                </ButtonToolbar>
            </Form>
        </Fragment>
    )
}
export { LocationParameterForm };