import { useEffect, useState } from "react";
import { B2CApiServiceModels, B2CApiServices, B2CApiEnums, B2CUiModels, B2CUiEnums, B2CApiModels } from "@santsg/ui-component-core";
import { Module, Permission } from "models/enums/moduleAndPermission";
import SanAbility from "components/sanability/SanAbility";
import { enmActionCellType } from "models/enums/ActionCellType";
import { enmButtonAppearance } from "models/enums/ButtonAppearance";
import { enmButtonColor } from "models/enums/ButtonColor";
import { enmFilterType } from "models/enums/FilterType";
import { enmFilterInputShowType } from "models/enums/FilterInputShowType";
import SanPagination from "components/common/SanPagination";
import _ from 'lodash';
import { ObjectValueController } from "@santsg/ui-common";
import { useAppSelector } from "store/hooks";
import SanModal from "components/common/SanModal";
import { Stack } from "rsuite";
import SanPageTools from "components/common/SanPageTools";
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import SanTable from "components/common/SanTable";
import { SanColumnItem } from "models/types/san-elements/SanColumnItem";
import { SanActionWrapper } from "models/types/san-elements/SanActionWrapper";
import { SanFilterData } from "models/types/san-elements/SanFilterData";
import SanFilter from "components/common/SanFilter";
import { SanRemoveConfirmationProps } from "models/types/san-elements/SanRemoveConfirmationProps";
import SanRemoveConfirmation from "components/common/SanRemoveConfirmation";
import { DataSourceListItemForm } from "./DataSourceListItemForm";
import { DataSourceFormItemListProps } from "models/types/datasource/DataSourceFormItemListProps";
import { DataSourceFormItemFormProps } from "models/types/datasource/DataSourceFormItemFormProps";
import { DataSourceFormItemForm } from "./DataSourceFormItemForm";


function DataSourceFormItemList(props: DataSourceFormItemListProps): JSX.Element {
    const settings = useAppSelector(state => state.settings);
    const [t] = useTranslation();
    const [listRequest, setListRequest] = useState<B2CApiServiceModels.Module.DataSource.DataSourceFormItem.DataSourceFormItemListRequest>(new B2CApiServiceModels.Module.DataSource.DataSourceFormItem.DataSourceFormItemListRequest());
    const [listResponse, setListResponse] = useState<B2CApiServiceModels.Module.DataSource.DataSourceFormItem.DataSourceFormItemListResponse>();
    const [openEditModal, setOpenEditModal] = useState<boolean>(false);
    const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false);
    const [forceClearFilters, setForceClearFilters] = useState<boolean>(false);
    const [dataSourceFormItem, setDataSourceFormItem] = useState<B2CApiModels.Module.DataSource.mdlDataSourceFormItem>();
    const [removeDataSourceId, setRemoveDataSourceId] = useState<string>();

    useEffect(() => {
        if (listResponse == null)
            getList();
    }, [listResponse]);


    //#region API CALLS
    async function getList(request?: B2CApiServiceModels.Module.DataSource.DataSourceFormItem.DataSourceFormItemListRequest) {
        let req = request ? request : listRequest;
        req.formTemplateId = props.dataSourceFormTemplate?.id;
        setListRequest(req);
        var response = await B2CApiServices.DataSourceService.DataSourceFormItem.List(req, false, true);
        if (response && response.header.success && response.body)
            setListResponse(response.body);
        else {
            setListResponse(new B2CApiServiceModels.Module.DataSource.DataSourceFormItem.DataSourceFormItemListResponse([]));
        }
        setForceClearFilters(false);
    }

    async function getDetail(id: string) {
        var request = new B2CApiServiceModels.Module.DataSource.DataSourceFormItem.DataSourceFormItemDetailRequest();
        request.id = id;
        var response = await B2CApiServices.DataSourceService.DataSourceFormItem.Detail(request, false, true);
        if (response && response.header.success && response.body?.dataSourceFormItem) {
            setDataSourceFormItem(response.body.dataSourceFormItem);
            setOpenEditModal(true);
        }
    }

    async function remove() {
        if (removeDataSourceId) {
            var response = await B2CApiServices.DataSourceService.DataSourceFormItem.Delete(new B2CApiServiceModels.Module.DataSource.DataSourceFormItem.DataSourceFormItemDeleteRequest(removeDataSourceId), false, true);
            if (response && response.header.success && response.body && response.body.deleted) {
                toast.success(t("common.success"));
                getList(new B2CApiServiceModels.Module.DataSource.DataSourceFormItem.DataSourceFormItemListResponse([]));
            }
            else {
                toast.error(response.header?.messages?.map(messageModel => messageModel.message).join('.'));
            }
        }
    }
    //#endregion

    //#region MODAL CONTROLS
    const handleEditModalClose = () => {
        setOpenEditModal(false);
    }

    const handleRemoveModalClose = () => {
        setOpenRemoveModal(false);
    }

    const handleRemoveModalConfirm = () => {
        remove();
        setOpenRemoveModal(false);
    }
    //#endregion

    //#region EVENTS
    const handleAddNewClick = () => {
        setDataSourceFormItem(undefined);
        setOpenEditModal(true);
    }

    const handleEditClick = (id: string) => {
        if (!ObjectValueController.isNullOrUndefinedOrEmpty(id)) {
            getDetail(id);
        }
    };

    const handleRemoveClick = (id: string) => {
        var requestedSource = _.find(listResponse?.data, function (r) { return r.id == id });
        if (requestedSource != null) {
            setRemoveDataSourceId(id);
            setOpenRemoveModal(true);
        }
    }

    const handleFilterChange = (filterProp: B2CUiModels.mdlFilterProps) => {
        if (!ObjectValueController.isNullOrUndefinedOrEmpty(filterProp.key)) {
            var clonedRequest = _.cloneDeep(listRequest);
            clonedRequest = ({
                ...clonedRequest,
                [filterProp.key!]: filterProp.value
            });
            getList(clonedRequest);
        }
    }

    const handleFilterClear = (key?: string) => {
        if (!ObjectValueController.isNullOrUndefinedOrEmpty(key)) {
            var clonedRequest = _.cloneDeep(listRequest);
            delete clonedRequest[key as keyof B2CApiServiceModels.Module.DataSource.DataSourceFormItem.DataSourceFormItemListRequest];
            getList(clonedRequest);
        } else {
            getList(new B2CApiServiceModels.Module.DataSource.DataSourceFormItem.DataSourceFormItemListRequest());
        }
    }

    const handlePagerChange = (pager?: B2CUiModels.mdlPagerProps) => {
        var req = _.cloneDeep(listRequest);
        req.pageNumber = pager?.pageNumber;
        req.pageSize = pager?.limit;
        getList(req);
    }
    //#endregion

    //#region HELPERS
    const restorePageDefaults = () => {
        setForceClearFilters(true);
        setOpenEditModal(false);
    }

    const handleFormError = (messages: { message: string }[]) => {
        toast.error(messages.map(messageModel => messageModel.message).join('.'));
    }

    const handleFormSuccess = () => {
        restorePageDefaults();
        getList();
        setOpenEditModal(false);
        toast.success(t("common.success"));
    }

    const handleExportToExcel = () => {
    }

    const handleExportToPdf = () => {
    }

    //#endregion

    //#region COMPONENT PROPS
    const tableColumns: SanColumnItem[] = [
        {
            key: "id",
            type: "string",
            label: "Id",
            visible: false
        },
        {
            key: "name",
            type: "text",
            label: "Name",
            visible: true
        },
        {
            key: "modifyDate",
            type: Date,
            label: "Modify Date",
            visible: true
        }
    ];

    const tableActionWrapper: SanActionWrapper = {
        actionCellType: enmActionCellType.Row,
        actions: [
            {
                apperance: enmButtonAppearance.ButtonPrimary,
                color: enmButtonColor.Blue,
                label: "Edit",
                onClick: handleEditClick
            },
            {
                apperance: enmButtonAppearance.ButtonPrimary,
                color: enmButtonColor.Red,
                label: "Delete",
                onClick: handleRemoveClick
            }]
    };

    const filters: SanFilterData[] = [
        {
            type: enmFilterType.TEXT,
            dataKey: "name",
            label: t("dataSourceFormItemPage.byName")
        }
    ];

    const dataSourceFormItemFormProps: DataSourceFormItemFormProps = {
        errorCallback: handleFormError,
        successCallback: handleFormSuccess,
        dataSourceFormItem: dataSourceFormItem,
        dataSourceFormTemplate: props.dataSourceFormTemplate
    }
    //#endregion 

    return <SanAbility module={Module.DataSource} permissions={[Permission.Read]} errorState='ShowMessage'>
        {
            <>
                <div>
                    <SanPageTools
                        title={props.inDrawer ? undefined : t("dataSourceFormItemPage.dataSourceFormItems")}
                        addNewFunction={handleAddNewClick}
                        addNewLabel={t("dataSourceFormItemPage.addNew")}
                        exportToExcelFunction={handleExportToExcel}
                        exportToExcelLabel={t("common.exportToExcel")}
                        exportToPdfFunction={handleExportToPdf}
                        exportToPdfLabel={t("common.exportToPdf")} />
                </div>
                <div>
                    <SanFilter forceClear={forceClearFilters} title={t("common.activeFilters")} onClearFilter={handleFilterClear} filters={filters} onChange={handleFilterChange} filterInputShowType={enmFilterInputShowType.SEPERATE} />
                </div>
                <div>
                    <SanTable data={listResponse?.data} tableColumns={tableColumns} tableActions={tableActionWrapper} />
                </div>
                <div>
                    <SanPagination totalCount={listResponse?.totalCount} resultCount={listResponse?.resultCount} activePage={listResponse?.pageNumber} activeLimit={listRequest.pageSize} onChange={handlePagerChange} />
                </div>
                <Stack>
                    <SanModal
                        backdrop={"static"}
                        type={settings?.panel.modalShowType}
                        open={openEditModal}
                        onClose={handleEditModalClose}
                        closeLabel="Close"
                        title={t("dataSourceFormItemPage.dataSourceFormItemForm.drawerTitle")}
                        body={DataSourceFormItemForm}
                        bodyProps={dataSourceFormItemFormProps}
                    />
                </Stack>
                <Stack>
                    <SanModal
                        backdrop={"static"}
                        type={B2CUiEnums.enmModalShowType.Modal}
                        open={openRemoveModal}
                        onClose={handleRemoveModalClose}
                        closeLabel="Close"
                        bodyProps={{ callBack: handleRemoveModalConfirm, typingRequired: true } as SanRemoveConfirmationProps}
                        title={t("common.attentionRequired")}
                        body={SanRemoveConfirmation}
                    />
                </Stack>
            </>
        }
    </SanAbility>;
}

export default DataSourceFormItemList;

