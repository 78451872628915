import React, { useEffect, useState } from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useWindowSize } from './hooks/useWindowSize';
import { calculateWindowSize } from './utils/helpers';
import { setWindowSize } from 'store/features/ui/UiSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { checkRefreshToken, setUser } from 'store/features/user/UserSlice';
import { getSiteCulture } from 'store/features/siteCulture/SiteCultureSlice';
import { useApiInterceptor } from 'utils/ApiResponse';
import { AppRoutes } from 'routes/AppRoutes';
import { getSiteSettings } from 'store/features/settings/SettingsSlice';

declare const FB: any;

const App = () => {
  const windowSize = useWindowSize();
  const screenSize = useAppSelector((state: any) => state.ui);
  const dispatch = useAppDispatch();
  const [isAppLoading, setIsAppLoading] = useState(true);
  useApiInterceptor(process.env.REACT_APP_API_URL || '');



  useEffect(() => {
    const checkSession = async () => {
      var response: any = await Promise.all([dispatch(checkRefreshToken())]);
      if (response && response.length > 0 && response[0] && response[0].payload && response[0].payload.user) {
        dispatch(setUser(response[0].payload.user));
        if (response[0].payload.user?.userInfo != null) {
          dispatch(getSiteCulture());
          dispatch(getSiteSettings());
          setIsAppLoading(false);
        }
        else
          // eslint-disable-next-line no-restricted-globals

          if (location.pathname != '/login')
            // eslint-disable-next-line no-restricted-globals
            location.href = "/login";
        setIsAppLoading(false);
      } else {
        // eslint-disable-next-line no-restricted-globals

        if (location.pathname != '/login')
          // eslint-disable-next-line no-restricted-globals
          location.href = "/login";
        setIsAppLoading(false);
      }
    };
    (global as any).ApiUrl = process.env.REACT_APP_API_URL;
    (global as any).MockApiUrl = process.env.REACT_APP_MOCK_API_URL;
    checkSession();
  }, []);

  useEffect(() => {
    const size = calculateWindowSize(windowSize.width);
    if (screenSize !== size) {
      dispatch(setWindowSize(size));
    }
  }, [windowSize]);

  if (isAppLoading) {
    return <p>Loading</p>;
  }
  return (
    <BrowserRouter>
      <AppRoutes />
      <ToastContainer
        autoClose={3000}
        draggable={false}
        position="top-right"
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnHover
      />
    </BrowserRouter>
  );
};

export default App;
