import grapesjs from 'grapesjs';
import loadBlocks from './blocks';
import loadComponents from './components/index';
import defOptions from './options';

export default grapesjs.plugins.add('gjs-blocks-basic', (editor, opts = {}) => {
    const config = {
        blocks: [
            //'column1',
            //'column2',
            //'column3',
            //'column3-7'
            //'text',
            //'link',
            //'image',
            //'video',
            //'map'
        ],
        flexGrid: 0,
        stylePrefix: 'san-',
        addBasicStyle: true,
        category:{id:'Containers',label:'Containers',open:false},
        labelColumn1: '1 Column',
        labelColumn2: '2 Columns',
        labelColumn3: '3 Columns',
        labelColumn37: '2 Columns 3/7',
        labelText: 'Text',
        labelLink: 'Link',
        labelImage: 'Image',
        labelVideo: 'Video',
        labelMap: 'Map',
        rowHeight: 75,
        ...defOptions,
        ...opts
    };

    // Add blocks
    //const loadBlocks = require('./blocks');
    loadBlocks(editor, config);
    loadComponents(editor, config);
    
});