import _ from 'lodash';
import { Fragment, useEffect, useState } from "react";
import { SanFormAutoCompleteProps } from "models/types/san-elements/SanFormAutoComplete";
import SpinnerIcon from '@rsuite/icons/legacy/Spinner';
import { B2CUiModels } from "@santsg/ui-component-core";
import WarningRoundIcon from '@rsuite/icons/WarningRound';
import { InputGroup } from 'rsuite';

export const SanFormAutoComplete = (props: SanFormAutoCompleteProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const [selectionDone, setSelectionDone] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>('');
    useEffect(() => {
        setLoading(false);
        if (props.value)
            setSelectionDone(true);
    }, [props])

    //#region EVENTS

    //#endregion

    //#regions HELPERS
    //#endregion

    const handleSearch = (event: any) => {
        var text = event.currentTarget.value;
        setSearchText(text);
        if (text && text.length >= 3 && props.onSearch) {
            setOpen(true);
            setLoading(true);
            props.onSearch(text);
        }
    }

    const handleSelect = (item: B2CUiModels.mdlIdNameCodeValue) => {
        setOpen(false);
        setSelectionDone(true);
        props.onSelect && props.onSelect(item);
    }

    const handleRemoveClick = () => {
        setSearchText("");
        setSelectionDone(false);
        props.onSelect && props.onSelect({ value: undefined });
    }

    return (
        <div className="san-form-autocomplete" style={{ maxWidth: "300px" }}>
            <input onChange={(e) => handleSearch(e)} placeholder={props.placeholder} className="rs-input" type="text" value={selectionDone ? props.value : searchText} />
            {
                props.value
                &&
                <WarningRoundIcon className='auto-complete-clear' onClick={handleRemoveClick} />
            }
            {
                open && !loading
                &&
                <div className={`rs-anim-fade rs-anim-in rs-picker-check-menu rs-picker-check-menu rs-picker-popup placement-bottom-start`}
                    style={{ minWidth: "300px" }}>
                    <div role="listbox" style={{ pointerEvents: `${props.readonly ? 'none' : 'all'}`, maxHeight: "320px" }} className="rs-picker-check-menu rs-picker-check-menu-items">
                        {
                            props.data && props.data.map((item: B2CUiModels.mdlIdNameCodeValue, index: number) => (
                                <Fragment key={index}>
                                    <div role="option" className="rs-picker-list-item">
                                        <div className="rs-check-item rs-checkbox">
                                            <div className="rs-checkbox-checker">
                                                <label onClick={() => handleSelect(item)} className="checkbox-dropdown-label">
                                                    <span className="rs-checkbox-label"> {item.name} </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            ))
                        }
                    </div>
                </div>
            }
            {
                open && loading
                &&
                <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                    <SpinnerIcon spin /> Loading...
                </p>
            }

        </div>
    )
}