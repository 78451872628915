import React, { useState } from "react";
import { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonToolbar, Form } from "rsuite";
import { B2CApiServiceModels, B2CApiServices, B2CUiHelpers } from "@santsg/ui-component-core";
import _ from 'lodash';
import { ValidationResult, getValidationResult } from "models/types/common/ValidationResult";
import { ObjectValueController } from "@santsg/ui-common";
import { DataSourceFormValueFormProps } from "models/types/datasource/DataSourceFormValueFormProps";
import { JsonEditor } from 'json-edit-react'
import { AccepterType } from "models/enums/FormAccepterTypes";
import { SanFormControl } from "components/common/SanFormControl";

const DataSourceFormValueForm = (props: DataSourceFormValueFormProps) => {
    const [request, setRequest] = React.useState<B2CApiServiceModels.Module.DataSource.DataSourceFormValue.DataSourceFormValueCreateRequest
        | B2CApiServiceModels.Module.DataSource.DataSourceFormValue.DataSourceFormValueUpdateRequest>({});
    const [t] = useTranslation();
    const [validationErrors, setValidationErrors] = useState<Array<ValidationResult>>([]);
    const [result, setResult] = useState<string>();

    useEffect(() => {
        if (!props.dataSourceFormValue) {
            var request = new B2CApiServiceModels.Module.DataSource.DataSourceFormValue.DataSourceFormValueCreateRequest();
            request.values = "{}";
            setRequest(request);
        } else {
            var pRequest = new B2CApiServiceModels.Module.DataSource.DataSourceFormValue.DataSourceFormValueUpdateRequest();
            pRequest.id = props.dataSourceFormValue.id;
            pRequest.values = props.dataSourceFormValue.values;
            pRequest.formTemplateId = props.dataSourceFormValue!.formTemplateId;
            setRequest(pRequest);
        }
    }, [props])

    //#region EVENTS
    const handleSubmit = () => {
        setValidationErrors([]);
        if (checkValidation()) {
            saveDataSource();
        }
    }

    const handleJsonUpdate = (e: Object) => {
        setResult(JSON.stringify(e));
    }

    //#endregion

    //#region HELPERS
    const checkValidation = () => {
        var messages = new Array<ValidationResult>();
        var result = false;
        if (ObjectValueController.isNullOrUndefinedOrEmpty(request.values)) {
            messages.push(getValidationResult(t, "values"));
        }

        messages.length == 0 ? result = true : result = false;
        setValidationErrors(messages);

        return result;
    }
    //#endregion

    //#region API CALLS
    async function saveDataSource() {
        request.values = result;
        var response = props.dataSourceFormValue ? await B2CApiServices.DataSourceService.DataSourceFormValue.Update(request as B2CApiServiceModels.Module.DataSource.DataSourceFormValue.DataSourceFormValueUpdateRequest, false, true) :
            await B2CApiServices.DataSourceService.DataSourceFormTemplate.Create(request as B2CApiServiceModels.Module.DataSource.DataSourceFormValue.DataSourceFormValueCreateRequest, false, true);
        if (response && response.header.success && response.body) {
            props.successCallback && props.successCallback();
        }
        else {
            props.errorCallback ? props.errorCallback(response.header.messages) : console.log("failed");
        }
    }
    //#endregion

    return (
        <Fragment>
            <Form
                onChange={setRequest}>
                {
                    request.values != undefined
                    &&
                    <SanFormControl
                        model={request}
                        name="values"
                        label={t("dataSourceFormValuePage.dataSourceFormValueForm.values")}
                        onUpdate={handleJsonUpdate}
                        accepter={AccepterType.JSONEDITOR}
                        placeholder={t("dataSourceFormValuePage.dataSourceFormValueForm.values")}
                        helperText={t("dataSourceFormValuePage.dataSourceFormValueForm.valuesInputInfo")}
                        errors={validationErrors}
                    />
                    // <JsonEditor
                    //     data={JSON.parse(request.values!)}
                    //     onUpdate={({ newData }) => {
                    //         handleJsonUpdate(newData)
                    //     }} />
                }


                <ButtonToolbar>
                    <Button appearance="primary" onClick={handleSubmit}>
                        {t("common.submit")}
                    </Button>
                </ButtonToolbar>
            </Form>
        </Fragment>
    )
}
export { DataSourceFormValueForm };