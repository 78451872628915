import { useEffect, useRef, useState } from "react";
import { B2CApiServiceModels, B2CApiServices, B2CUiModels, B2CUiEnums, B2CApiModels, B2CApiEnums, B2CUiHelpers } from "@santsg/ui-component-core";
import { Module, Permission } from "models/enums/moduleAndPermission";
import SanAbility from "components/sanability/SanAbility";
import { enmActionCellType } from "models/enums/ActionCellType";
import { enmButtonAppearance } from "models/enums/ButtonAppearance";
import { enmButtonColor } from "models/enums/ButtonColor";
import { enmFilterType } from "models/enums/FilterType";
import { enmFilterInputShowType } from "models/enums/FilterInputShowType";
import SanPagination from "components/common/SanPagination";
import _ from 'lodash';
import { ObjectValueController } from "@santsg/ui-common";
import { useAppSelector } from "store/hooks";
import SanModal from "components/common/SanModal";
import { Stack } from "rsuite";
import SanPageTools from "components/common/SanPageTools";
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import SanTable from "components/common/SanTable";
import { SanColumnItem } from "models/types/san-elements/SanColumnItem";
import { SanActionWrapper } from "models/types/san-elements/SanActionWrapper";
import { SanFilterData } from "models/types/san-elements/SanFilterData";
import SanFilter from "components/common/SanFilter";
import { SanRemoveConfirmationProps } from "models/types/san-elements/SanRemoveConfirmationProps";
import SanRemoveConfirmation from "components/common/SanRemoveConfirmation";
import { LocationParameterFormProps } from "models/types/location-parameter/LocationParameterFormProps";
import { LocationParameterForm } from "./LocationParameterForm";
import LocationParameterPageDefinitionList from "./LocationParameterPageDefinitionList";
import { enmModalSize } from "models/enums/ModalSize";
import * as XLSX from 'xlsx';

function LocationParameterList(): JSX.Element {
    const settings = useAppSelector(state => state.settings);
    const [t] = useTranslation();
    const [listRequest, setListRequest] = useState<B2CApiServiceModels.Module.LocationParameter.LocationParameterListRequest>(new B2CApiServiceModels.Module.LocationParameter.LocationParameterListRequest());
    const [listResponse, setListResponse] = useState<B2CApiServiceModels.Module.LocationParameter.LocationParameterListResponse>();
    const [openEditModal, setOpenEditModal] = useState<boolean>(false);
    const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false);
    const [openLayoutModal, setOpenLayoutModal] = useState<boolean>(false);
    const [forceClearFilters, setForceClearFilters] = useState<boolean>(false);
    const [locationParameter, setLocationParameter] = useState<B2CApiModels.Module.LocationParameterModel.mdlLocationParameter>();
    const [removeLocationParameterId, setRemoveLocationParameterId] = useState<string>();
    const tableRef = useRef(null);

    useEffect(() => {
        if (listResponse == null)
            getLocationParameters();
    }, [listResponse]);


    //#region API CALLS
    async function getLocationParameters(request?: B2CApiServiceModels.Module.LocationParameter.LocationParameterListRequest) {
        let req = request ? request : listRequest;
        setListRequest(req);
        var response = await B2CApiServices.LocationParameterService.List(req, false, true);
        if (response && response.header.success && response.body)
            setListResponse(response.body);
        else {
            setListResponse(new B2CApiServiceModels.Module.LocationParameter.LocationParameterListResponse([]));
        }
        setForceClearFilters(false);
    }

    async function getLocationParameter(id: string) {
        var request = new B2CApiServiceModels.Module.LocationParameter.LocationParameterDetailRequest(id);
        var response = await B2CApiServices.LocationParameterService.Detail(request, false, true);
        if (response && response.header.success && response.body?.locationParameter) {
            return response.body.locationParameter;
        }
    }

    async function deleteLocationParameter() {
        if (removeLocationParameterId) {
            var response = await B2CApiServices.LocationParameterService.Delete(new B2CApiServiceModels.Module.LocationParameter.LocationParameterDeleteRequest(removeLocationParameterId), false, true);
            if (response && response.header.success && response.body && response.body.deleted) {
                toast.success(t("common.success"));
                getLocationParameters();
            }
            else {
                toast.error(response.header?.messages?.map(messageModel => messageModel.message).join('.'));
            }
        }
    }
    //#endregion

    //#region MODAL CONTROLS
    const handleEditModalClose = () => {
        setOpenEditModal(false);
    }

    const handleLayoutModalClose = () => {
        setOpenLayoutModal(false);
    }

    const handleRemoveModalClose = () => {
        setOpenRemoveModal(false);
    }

    const handleRemoveModalConfirm = () => {
        deleteLocationParameter();
        setOpenRemoveModal(false);
    }
    //#endregion

    //#region EVENTS
    const handleAddNewClick = () => {
        setLocationParameter(undefined);
        setOpenEditModal(true);
    }

    const handleEditClick = async (id: string) => {
        if (!ObjectValueController.isNullOrUndefinedOrEmpty(id)) {
            var locationParameter = await getLocationParameter(id);
            if (locationParameter) {
                setLocationParameter(locationParameter);
                setOpenEditModal(true);
            }
        }
    };

    const handleLayoutsClick = async (id: string) => {
        if (!ObjectValueController.isNullOrUndefinedOrEmpty(id)) {
            var locationParameter = await getLocationParameter(id);
            if (locationParameter) {
                setLocationParameter(locationParameter);
                setOpenLayoutModal(true);
            }
        }
    };

    const handleRemoveClick = async (id: string) => {
        var locationParameter = await getLocationParameter(id);
        if (locationParameter) {
            setLocationParameter(locationParameter);
            setRemoveLocationParameterId(id);
            setOpenRemoveModal(true);
        }
    }

    const handleFilterChange = (filterProp: B2CUiModels.mdlFilterProps) => {
        if (!ObjectValueController.isNullOrUndefinedOrEmpty(filterProp.key)) {
            var clonedRequest = _.cloneDeep(listRequest);
            clonedRequest = ({
                ...clonedRequest,
                [filterProp.key!]: filterProp.value
            });
            getLocationParameters(clonedRequest);
        }
    }

    const handleFilterClear = (key?: string) => {
        if (!ObjectValueController.isNullOrUndefinedOrEmpty(key)) {
            var clonedRequest = _.cloneDeep(listRequest);
            delete clonedRequest[key as keyof B2CApiServiceModels.Module.LocationParameter.LocationParameterListRequest];
            getLocationParameters(clonedRequest);
        } else {
            getLocationParameters(new B2CApiServiceModels.Module.LocationParameter.LocationParameterListRequest());
        }
    }

    const handlePagerChange = (pager?: B2CUiModels.mdlPagerProps) => {
        var req = _.cloneDeep(listRequest);
        req.pageNumber = pager?.pageNumber;
        req.pageSize = pager?.limit;
        getLocationParameters(req);
    }
    //#endregion

    //#region HELPERS
    const restorePageDefaults = () => {
        setForceClearFilters(true);
        setOpenEditModal(false);
    }

    const handleFormError = (messages: { message: string }[]) => {
        toast.error(messages.map(messageModel => messageModel.message).join('.'));
    }

    const handleFormSuccess = () => {
        restorePageDefaults();
        getLocationParameters();
        setOpenEditModal(false);
        toast.success(t("common.success"));
    }

    const handleExportToExcel = () => {
        const rowData = listResponse?.data ? listResponse?.data?.map(locParam => [locParam.arrival?.name, locParam.checkInFrom, B2CApiEnums.enmPackType[locParam.packType!]]) : [];
        if (rowData)
            B2CUiHelpers.ExportHelper.exportToExcel(XLSX, t("locationParameterPage.locationParameters"), [t("locationParameterPage.arrival"), t("locationParameterPage.checkInFrom"), t("locationParameterPage.packType")], rowData as string[][]);
    };

    const handleExportToPdf = () => {
        const content = tableRef.current;
        if (content) B2CUiHelpers.ExportHelper.exportToPdf((content as HTMLElement).outerHTML);
    };

    //#endregion

    //#region COMPONENT PROPS
    const tableColumns: SanColumnItem[] = [
        {
            key: "id",
            type: "string",
            label: "Id",
            visible: false
        },
        {
            key: "arrival.name",
            type: "string",
            label: t("locationParameterPage.arrival"),
            visible: true
        },
        {
            key: "packType",
            type: "enum",
            label: t("locationParameterPage.packType"),
            model: B2CApiEnums.enmPackType,
            visible: true
        },
        {
            key: "checkInFrom",
            type: "string",
            label: t("locationParameterPage.checkInFrom"),
            visible: true
        },
        {
            key: "night",
            type: "string",
            label: t("locationParameterPage.night"),
            visible: true
        },
        {
            key: "modifyDate",
            type: Date,
            label: t("common.modifyDate"),
            visible: true
        }
    ];

    const tableActionWrapper: SanActionWrapper = {
        actionCellType: enmActionCellType.Row,
        actions: [
            {
                apperance: enmButtonAppearance.ButtonPrimary,
                color: enmButtonColor.Blue,
                label: t("common.edit"),
                onClick: handleEditClick
            },
            {
                apperance: enmButtonAppearance.ButtonPrimary,
                color: enmButtonColor.Yellow,
                label: t("locationParameterPage.pagesAndLayouts"),
                onClick: handleLayoutsClick
            },
            {
                apperance: enmButtonAppearance.ButtonPrimary,
                color: enmButtonColor.Red,
                label: t("common.delete"),
                onClick: handleRemoveClick
            }]
    };

    const filters: SanFilterData[] = [
        {
            type: enmFilterType.ENUMSELECT,
            dataKey: "packType",
            label: t("locationParameterPage.byPackType"),
            model: B2CApiEnums.enmPackType
        }
    ];

    const locationParameterFormProps: LocationParameterFormProps = {
        errorCallback: handleFormError,
        successCallback: handleFormSuccess,
        locationParameter: locationParameter
    }

    const locationParameterPageDefinitionProps: LocationParameterFormProps = {
        errorCallback: handleFormError,
        successCallback: handleFormSuccess,
        locationParameter: locationParameter
    }
    //#endregion 

    return <SanAbility module={Module.LocationParameter} permissions={[Permission.Read]} errorState='ShowMessage'>
        {
            <>
                <div>
                    <SanPageTools
                        title={t("locationParameterPage.locationParameters")}
                        addNewFunction={handleAddNewClick}
                        addNewLabel={t("locationParameterPage.addNew")}
                        exportToExcelFunction={handleExportToExcel}
                        exportToExcelLabel={t("common.exportToExcel")}
                        exportToPdfFunction={handleExportToPdf}
                        exportToPdfLabel={t("common.exportToPdf")} />
                </div>
                <div>
                    <SanFilter forceClear={forceClearFilters} title={t("common.activeFilters")} onClearFilter={handleFilterClear} filters={filters} onChange={handleFilterChange} filterInputShowType={enmFilterInputShowType.SEPERATE} />
                </div>
                <div ref={tableRef}>
                    <SanTable data={listResponse?.data} tableColumns={tableColumns} tableActions={tableActionWrapper} />
                </div>
                <div>
                    <SanPagination totalCount={listResponse?.totalCount} resultCount={listResponse?.resultCount} activePage={listResponse?.pageNumber} activeLimit={listRequest.pageSize} onChange={handlePagerChange} />
                </div>
                <Stack>
                    <SanModal
                        backdrop={"static"}
                        type={settings?.panel.modalShowType}
                        open={openEditModal}
                        onClose={handleEditModalClose}
                        closeLabel={t("common.close")}
                        title={t("locationParameterPage.locationParameterForm.drawerTitle")}
                        body={LocationParameterForm}
                        bodyProps={locationParameterFormProps}
                    />
                </Stack>
                <Stack>
                    <SanModal
                        backdrop={"static"}
                        type={settings?.panel.modalShowType}
                        size={enmModalSize.FULL}
                        open={openLayoutModal}
                        onClose={handleLayoutModalClose}
                        closeLabel={t("common.close")}
                        body={LocationParameterPageDefinitionList}
                        bodyProps={locationParameterPageDefinitionProps}
                    />
                </Stack>
                <Stack>
                    <SanModal
                        backdrop={"static"}
                        type={B2CUiEnums.enmModalShowType.Modal}
                        open={openRemoveModal}
                        onClose={handleRemoveModalClose}
                        closeLabel={t("common.close")}
                        bodyProps={{ callBack: handleRemoveModalConfirm, typingRequired: true } as SanRemoveConfirmationProps}
                        title={t("common.attentionRequired")}
                        body={SanRemoveConfirmation}
                    />
                </Stack>
            </>
        }
    </SanAbility>;
}

export default LocationParameterList;

