import React, { useState } from "react";
import { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonToolbar, Form } from "rsuite";
import { B2CApiEnums, B2CApiServiceModels, B2CApiServices, B2CUiHelpers, B2CUiModels, TVApiEnums } from "@santsg/ui-component-core";
import _ from 'lodash';
import { SanFormControl } from "components/common/SanFormControl";
import { ValidationResult, getValidationResult } from "models/types/common/ValidationResult";
import { AccepterType } from "models/enums/FormAccepterTypes";
import { DataSourceListGroupFormProps } from "models/types/datasource/DataSourceListGroupFormProps";
import { ObjectValueController } from "@santsg/ui-common";

const DataSourceListGroupForm = (props: DataSourceListGroupFormProps) => {
    const [request, setRequest] = React.useState<B2CApiServiceModels.Module.DataSource.DataSourceListGroup.DataSourceListGroupCreateRequest
        | B2CApiServiceModels.Module.DataSource.DataSourceListGroup.DataSourceListGroupUpdateRequest>({});
    const [t] = useTranslation();
    //const groupSections = B2CUiHelpers.EnumHelper.getEnumKeyValues(B2CApiEnums.enmDataSourceListItemGroupSectionType);
    const [groupSections, setGroupSections] = useState<Array<B2CUiModels.mdlIdNameCodeValue>>();
    const [validationErrors, setValidationErrors] = useState<Array<ValidationResult>>([]);
    const groups = B2CUiHelpers.EnumHelper.getEnumKeyValues(B2CApiEnums.enmDataSourceListGroupType);

    useEffect(() => {
        if (!props.dataSourceListGroup) {
            var request = new B2CApiServiceModels.Module.DataSource.DataSourceListGroup.DataSourceListGroupCreateRequest();
            request.removable = true;
            request.editable = true;
            setRequest(request);
        } else {
            var pRequest = new B2CApiServiceModels.Module.DataSource.DataSourceListGroup.DataSourceListGroupUpdateRequest();
            pRequest.id = props.dataSourceListGroup.id;
            pRequest.group = props.dataSourceListGroup.group;
            pRequest.groupSection = props.dataSourceListGroup.groupSection;
            pRequest.name = props.dataSourceListGroup.name;
            pRequest.editable = props.dataSourceListGroup.editable;
            pRequest.removable = props.dataSourceListGroup.removable;
            setRequest(pRequest);
            prepareSections(pRequest.group);
        }
    }, [props])

    //#region EVENTS
    const handleSubmit = () => {
        setValidationErrors([]);
        if (checkValidation()) {
            save();
        }
    }

    const handleGroupChange = (value: B2CApiServiceModels.Module.DataSource.DataSourceListGroup.DataSourceListGroupUpdateRequest) => {
        setRequest({
            ...request,
            ["group"]: value.group
        });
        prepareSections(value.group);
    }
    //#endregion

    //#region HELPERS
    const checkValidation = () => {
        var messages = new Array<ValidationResult>();
        var result = false;
        if (ObjectValueController.isNullOrUndefinedOrEmpty(request.group)) {
            messages.push(getValidationResult(t, "group"));
        }
        if (ObjectValueController.isNullOrUndefinedOrEmpty(request.groupSection))
            messages.push(getValidationResult(t, "groupSection"));

        if (ObjectValueController.isNullOrUndefinedOrEmpty(request.name)) {
            messages.push(getValidationResult(t, "name"));
        }

        messages.length == 0 ? result = true : result = false;
        setValidationErrors(messages);

        return result;
    }

    const prepareSections = (group?: B2CApiEnums.enmDataSourceListGroupType) => {
        switch (group) {
            case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUHOTEL:
                var hotelSections = [B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_ARRIVAL, B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_CHECKIN];
                var pair = B2CUiHelpers.EnumHelper.getEnumKeyValuesForSpecificTypes(B2CApiEnums.enmDataSourceListItemGroupSectionType, hotelSections);
                setGroupSections(pair);
                break;
            case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUHOLIDAYPACKAGE:
                var holPackSections = [B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_PACKAGE, B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_CHECKIN];
                var pair = B2CUiHelpers.EnumHelper.getEnumKeyValuesForSpecificTypes(B2CApiEnums.enmDataSourceListItemGroupSectionType, holPackSections);
                setGroupSections(pair);
                break;
            case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUDYNAMIC:
                var dynamicSections = [B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_ARRIVAL, B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_DEPARTURE, B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_CHECKIN];
                var pair = B2CUiHelpers.EnumHelper.getEnumKeyValuesForSpecificTypes(B2CApiEnums.enmDataSourceListItemGroupSectionType, dynamicSections);
                setGroupSections(pair);
                break;
            case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUEXCURSION:
                var excursionSections = [B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_EXCURSION, B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_CHECKIN];
                var pair = B2CUiHelpers.EnumHelper.getEnumKeyValuesForSpecificTypes(B2CApiEnums.enmDataSourceListItemGroupSectionType, excursionSections);
                setGroupSections(pair);
                break;
            case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUFLIGHT:
                var flightSections = [B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_ONEWAYFLIGHT, B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_ROUNDTRIPFLIGHT, B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_MULTIFLIGHT, B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_CHECKIN];
                var pair = B2CUiHelpers.EnumHelper.getEnumKeyValuesForSpecificTypes(B2CApiEnums.enmDataSourceListItemGroupSectionType, flightSections);
                setGroupSections(pair);
                break;
            case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUTRANSFER:
                var transferSections = [B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_ONEWAYTRANSFER, B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_ROUNDTRIPTRANSFER, B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_CHECKIN];
                var pair = B2CUiHelpers.EnumHelper.getEnumKeyValuesForSpecificTypes(B2CApiEnums.enmDataSourceListItemGroupSectionType, transferSections);
                setGroupSections(pair);
                break;
            case B2CApiEnums.enmDataSourceListGroupType.SEARCHMENUTOUR:
                var tourSections = [B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_TOUR, B2CApiEnums.enmDataSourceListItemGroupSectionType.SEARCH_CHECKIN];
                var pair = B2CUiHelpers.EnumHelper.getEnumKeyValuesForSpecificTypes(B2CApiEnums.enmDataSourceListItemGroupSectionType, tourSections);
                setGroupSections(pair);
                break;
            case B2CApiEnums.enmDataSourceListGroupType.MAILTEMPLATES:
                var mailSections = [B2CApiEnums.enmDataSourceListItemGroupSectionType.MAIL_LOSTPASSWORD,
                B2CApiEnums.enmDataSourceListItemGroupSectionType.MAIL_RESERVATIONSUCCESS,
                B2CApiEnums.enmDataSourceListItemGroupSectionType.MAIL_RESERVATIONFAIL,
                B2CApiEnums.enmDataSourceListItemGroupSectionType.MAIL_CONTACTUS,
                B2CApiEnums.enmDataSourceListItemGroupSectionType.MAIL_CALLYOU,
                B2CApiEnums.enmDataSourceListItemGroupSectionType.MAIL_SITEMEMBER,
                B2CApiEnums.enmDataSourceListItemGroupSectionType.MAIL_CUSTOMFORM,
                B2CApiEnums.enmDataSourceListItemGroupSectionType.MAIL_NEWSLETTER,
                B2CApiEnums.enmDataSourceListItemGroupSectionType.MAIL_NEWRESERVATION,
                B2CApiEnums.enmDataSourceListItemGroupSectionType.MAIL_UNSUBSCRIBE,
                B2CApiEnums.enmDataSourceListItemGroupSectionType.MAIL_RESERVATIONDEPOSITPAID,
                B2CApiEnums.enmDataSourceListItemGroupSectionType.MAIL_RESERVATIONRESERVE,
                B2CApiEnums.enmDataSourceListItemGroupSectionType.MAIL_RECEIPT,
                B2CApiEnums.enmDataSourceListItemGroupSectionType.MAIL_GLOBAL_HEADER,
                B2CApiEnums.enmDataSourceListItemGroupSectionType.MAIL_GLOBAL_FOOTER,
                B2CApiEnums.enmDataSourceListItemGroupSectionType.MAIL_AGENCY_PARTNERSHIP,
                B2CApiEnums.enmDataSourceListItemGroupSectionType.MAIL_COMPARE,
                B2CApiEnums.enmDataSourceListItemGroupSectionType.MAIL_SEND_FAIL]
                var pair = B2CUiHelpers.EnumHelper.getEnumKeyValuesForSpecificTypes(B2CApiEnums.enmDataSourceListItemGroupSectionType, mailSections);
                setGroupSections(pair);
                break;
            case B2CApiEnums.enmDataSourceListGroupType.IMAGECATEGORIES:
                var iamgeSection = [B2CApiEnums.enmDataSourceListItemGroupSectionType.IMAGE_CATEGORIES];
                var pair = B2CUiHelpers.EnumHelper.getEnumKeyValuesForSpecificTypes(B2CApiEnums.enmDataSourceListItemGroupSectionType, iamgeSection);
                setGroupSections(pair);
                break;
            default:
                var noneSection = [B2CApiEnums.enmDataSourceListItemGroupSectionType.NONE];
                var pair = B2CUiHelpers.EnumHelper.getEnumKeyValuesForSpecificTypes(B2CApiEnums.enmDataSourceListItemGroupSectionType, noneSection);
                setGroupSections(pair);
        }
    }
    //#endregion

    //#region API CALLS
    async function save() {
        var response = !props.dataSourceListGroup ? await B2CApiServices.DataSourceService.DataSourceListGroup.Create(request as B2CApiServiceModels.Module.DataSource.DataSourceListGroup.DataSourceListGroupUpdateRequest, false, true) :
            await B2CApiServices.DataSourceService.DataSourceListGroup.Update(request as B2CApiServiceModels.Module.DataSource.DataSourceListGroup.DataSourceListGroupCreateRequest, false, true);
        if (response && response.header.success && response.body) {
            props.successCallback && props.successCallback();
        }
        else {
            props.errorCallback ? props.errorCallback(response.header.messages) : console.log("failed");
        }
    }
    //#endregion

    return (
        <Fragment>
            <Form
                onChange={setRequest}>

                <SanFormControl
                    model={request}
                    onChange={setRequest}
                    name="name"
                    label={t("dataSourceListGroupPage.dataSourceListGroupForm.name")}
                    type="text"
                    placeholder={t("dataSourceListGroupPage.dataSourceListGroupForm.name")}
                    helperText={t("dataSourceListGroupPage.dataSourceListGroupForm.nameInputInfo")}
                    errors={validationErrors}
                />

                <SanFormControl
                    model={request}
                    onChange={handleGroupChange}
                    name="group"
                    label={t("dataSourceListGroupPage.dataSourceListGroupForm.group")}
                    accepter={AccepterType.DROPDOWN}
                    dataSource={groups}
                    placeholder={t("dataSourceListGroupPage.dataSourceListGroupForm.group")}
                    helperText={t("dataSourceListGroupPage.dataSourceListGroupForm.groupInputInfo")}
                    errors={validationErrors}
                />

                <SanFormControl
                    model={request}
                    onChange={setRequest}
                    name="groupSection"
                    label={t("dataSourceListGroupPage.dataSourceListGroupForm.groupSection")}
                    accepter={AccepterType.DROPDOWN}
                    dataSource={groupSections}
                    placeholder={t("dataSourceListGroupPage.dataSourceListGroupForm.groupSection")}
                    helperText={t("dataSourceListGroupPage.dataSourceListGroupForm.groupSectionInputInfo")}
                    errors={validationErrors}
                />

                <SanFormControl
                    model={request}
                    onChange={setRequest}
                    name="editable"
                    label={t("dataSourceListGroupPage.dataSourceListGroupForm.editable")}
                    accepter={AccepterType.DROPDOWN}
                    dataSource={B2CUiHelpers.EnumHelper.getTrueFalseAsPair([t("dataSourceListGroupPage.dataSourceListGroupForm.editable"), t("dataSourceListGroupPage.dataSourceListGroupForm.notEditable")])}
                    placeholder={t("dataSourceListGroupPage.dataSourceListGroupForm.editable")}
                    helperText={t("dataSourceListGroupPage.dataSourceListGroupForm.editableInputInfo")}
                    errors={validationErrors}
                />

                <SanFormControl
                    model={request}
                    onChange={setRequest}
                    name="removable"
                    readonly={!request.removable}
                    label={t("dataSourceListGroupPage.dataSourceListGroupForm.removable")}
                    accepter={AccepterType.DROPDOWN}
                    dataSource={B2CUiHelpers.EnumHelper.getTrueFalseAsPair([t("dataSourceListGroupPage.dataSourceListGroupForm.removable"), t("dataSourceListGroupPage.dataSourceListGroupForm.notRemovable")])}
                    placeholder={t("dataSourceListGroupPage.dataSourceListGroupForm.removable")}
                    helperText={t("dataSourceListGroupPage.dataSourceListGroupForm.removableInputInfo")}
                    errors={validationErrors}
                />

                <ButtonToolbar>
                    <Button appearance="primary" onClick={handleSubmit}>
                        {t("common.submit")}
                    </Button>
                </ButtonToolbar>
            </Form>
        </Fragment>
    )
}
export { DataSourceListGroupForm };