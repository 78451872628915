import { Fragment } from "react";
import { IconButton, Stack } from "rsuite";
import PlusIcon from '@rsuite/icons/Plus';
import ExportIcon from '@rsuite/icons/Export';
import ImportIcon from '@rsuite/icons/Import';
import { SanPageToolsProps } from "models/types/san-elements/SanPageToolsProps";

const SanPageTools = (props: SanPageToolsProps) => {
    return (
        <Fragment>
            <Stack justifyContent='space-between'>
                <Stack justifyContent="center">
                    {
                        props.title
                        &&
                        <h2>{props.title}</h2>
                    }
                </Stack>
                <Stack spacing={10}>
                    {
                        props.additionalButtonLabel && props.additionalButtonLink
                        &&
                        <a style={{ color: "aliceblue" }} target="_blank" href={props.additionalButtonLink}>{props.additionalButtonLabel}</a>
                    }
                    {
                        props.addNewFunction
                        &&
                        <IconButton onClick={() => props.addNewFunction && props.addNewFunction()} icon={<PlusIcon />}>{props.addNewLabel}</IconButton>
                    }
                    {
                        props.exportToExcelFunction
                        &&
                        <IconButton onClick={() => props.exportToExcelFunction && props.exportToExcelFunction()} icon={<ExportIcon />}>{props.exportToExcelLabel}</IconButton>
                    }
                    {
                        props.exportToPdfFunction
                        &&
                        <IconButton onClick={() => props.exportToPdfFunction && props.exportToPdfFunction()} icon={<ExportIcon />}>{props.exportToPdfLabel}</IconButton>
                    }
                    {
                        props.importToExcelFunction
                        &&
                        <IconButton onClick={() => props.importToExcelFunction && props.importToExcelFunction()} icon={<ImportIcon />}>{props.importToExcelLabel}</IconButton>
                    }
                </Stack>
            </Stack>
        </Fragment>
    )
}
export default SanPageTools;