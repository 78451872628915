import ReactDOM from 'react-dom/client';
import React from 'react';
import 'select2';
import * as jQ from 'jquery';
import 'summernote/dist/summernote-lite';
import { CookieManager, ObjectValueController } from "@santsg/ui-common";
import {B2CUiEnums} from "@santsg/ui-component-core";
import $ from "jquery";


export default (editor) => {
    editor.TraitManager.addType('contentautocomplete', {

        // Expects as return a simple HTML string or an HTML element
        template: '',
        noLabel: true,
        eventCapture: ['input'],
        // createLabel({ label }) {

        //     return `<div>
        //       ${label}
        //     </div>`;
        // },
        createInput({ trait }) {
            // Here we can decide to use properties from the trait
            // Create a new element container and add some content
            if (trait.target.view.attr.withoutmanager != undefined)
                return '';
            const el = document.createElement('div');
            el.innerHTML = `
            <div class="content-autocomplete-container">
            <select class="content-autocomplete__select hidden"></select>
            <input class="content-autocomplete__name form-control hidden" value=""/>
            <input type="hidden" class="content-autocomplete__data form-control" value=""/>
            </div>
            `;
            // Let's make our content interactive
            const autocompleteSelect = el.querySelector('.content-autocomplete__select');
            const autocompleteData = el.querySelector('.content-autocomplete__data');
            const autocompleteName = el.querySelector('.content-autocomplete__name');

            jQ(autocompleteSelect).select2({
                // language: {
                //     searching: function () { return 'aranıyor...' },
                // },
                //dropdownParent: jQ('.gjs-mdl-content'),
                width: '90%',
                ajax: {
                    url: process.env.REACT_APP_API_URL + "/content/list",
                    contentType: 'application/json',
                    dataType: 'json',
                    delay: 250,
                    type: "POST",
                    data: function (params) {
                        return JSON.stringify({
                            siteCultureId: 0,//store.getState().siteCulture.cultures.at(0).id,
                            query: params.term,
                        });
                    },
                    processResults: function (data, params) {
                        // parse the results into the format expected by Select2
                        // since we are using custom formatting functions we do not need to
                        // alter the remote JSON data, except to indicate that infinite
                        // scrolling can be used
                        params.page = params.page || 1;
                        let tmpResults = [];

                        tmpResults = _.map(data.body.contents,
                            function (ma, mi) { return { id: ma.layoutId, text: ma.name } })

                        ///data=JSON.parse(data);
                        return {
                            results: tmpResults,
                            pagination: {
                                more: (params.page * 30) < data.total_count
                            }
                        };
                    },
                    cache: true
                },

                //selectOnClose: true,
                placeholder: 'Search for content',
                minimumInputLength: 3,
                templateResult: function (repo) {

                    if (repo.loading) {
                        return repo.text;
                    }
                    var $container = jQ(
                        "<div class='select2-result-repository clearfix'>" +

                        "<div class='select2-result-repository__meta'>" +
                        "<div class='select2-result-repository__city'></div>" +
                        "<div class='select2-result-repository__country'></div>" +
                        "</div>"
                    );

                    $container.find(".select2-result-repository__city").text(repo.text);
                    //$container.find(".select2-result-repository__country").text(repo.country.name);

                    return $container;
                },
                templateSelection: function (repo) {
                    return repo.text;
                }

            }).on('select2:select', function (e) {
                autocompleteData.value = e.params.data.id;
                //autocompleteData.value=data;
                //autocompleteName.value=data.city.name;
                autocompleteName.dispatchEvent(new Event('input', { 'bubbles': true }));
            });

            return el;
        },
        onEvent({ elInput, component, event }) {
            const inputType = elInput.querySelector('.content-autocomplete__data');
            //component.replaceWith(`<LayoutWrapper layoutid="${inputType.value}"/>`);
            component.removeAttributes(['layoutid']);
            component.addAttributes({ layoutid: inputType.value });
        },
        onUpdate({ elInput, component }) {
        },
    });
    editor.TraitManager.addType('arrivalautocomplete', {

        // Expects as return a simple HTML string or an HTML element
        template: '',
        noLabel: true,
        eventCapture: ['input'],
        // createLabel({ label }) {

        //     return `<div>
        //       ${label}
        //     </div>`;
        // },
        createInput({ trait }) {
            // Here we can decide to use properties from the trait
            const productType = trait.target.view.attr.productType || 'Hotel';
            // Create a new element container and add some content
            const el = document.createElement('div');
            el.innerHTML = `
            <div class="location-autocomplete-container">
            <input class="location-autocomplete__name form-control" value=""/>
            <input type="hidden" class="location-autocomplete__data form-control" value=""/>
            <button class="location-autocomplate_modal btn btn-info">Modal</button>
            </div>
            `;
            const modalEl = document.createElement('div');
            modalEl.innerHTML = `
            <select class="location-autocomplete__select hidden"></select>
            `;

            // Let's make our content interactive
            const autocompleteSelect = modalEl.querySelector('.location-autocomplete__select');
            const autocompleteData = el.querySelector('.location-autocomplete__data');
            const autocompleteName = el.querySelector('.location-autocomplete__name');
            const btnModal = el.querySelector('.location-autocomplate_modal');

            jQ(autocompleteSelect).select2({
                // language: {
                //     searching: function () { return 'aranıyor...' },
                // },
                dropdownParent: jQ('.gjs-mdl-content'),
                width: '90%',
                ajax: {
                    url: process.env.REACT_APP_API_URL + "/services/invoke",
                    contentType: 'application/json',
                    headers: {
                        'X-Site-CultureId': CookieManager.get('siteCultureId'),
                        'Authorization': CookieManager.get('applicationTokenPanel')

                    },
                    dataType: 'json',
                    delay: 250,
                    type: "POST",
                    data: function (params) {
                        return JSON.stringify({
                            operation: 'productservice/getarrivalautocomplete',
                            data: JSON.stringify({
                                productType: productType,
                                query: params.term,
                            })
                        });
                    },
                    processResults: function (data, params) {
                        // parse the results into the format expected by Select2
                        // since we are using custom formatting functions we do not need to
                        // alter the remote JSON data, except to indicate that infinite
                        // scrolling can be used
                        params.page = params.page || 1;
                        let tmpResults = [];
                        if (productType == 2) {
                            tmpResults = _.map(_.filter(data.body.items, function (w) { return w.type == 2 }),
                                function (ma, mi) { return { id: ma.hotel.id, text: ma.hotel.name, provider: ma.provider, type: ma.type } })
                        } else {
                            tmpResults = _.map(_.filter(data.body.items, function (w) { return w.type != 2 }),
                                function (ma, mi) { return { id: ma.city.id, text: ma.city.name, provider: ma.provider, type: ma.type } })
                        }

                        ///data=JSON.parse(data);
                        return {
                            results: tmpResults,
                            pagination: {
                                more: (params.page * 30) < data.total_count
                            }
                        };
                    },
                    cache: true
                },

                //selectOnClose: true,
                placeholder: 'Search for location',
                minimumInputLength: 3,
                templateResult: function (repo) {

                    if (repo.loading) {
                        return repo.text;
                    }
                    var $container = jQ(
                        "<div class='select2-result-repository clearfix'>" +

                        "<div class='select2-result-repository__meta'>" +
                        "<div class='select2-result-repository__city'></div>" +
                        "<div class='select2-result-repository__country'></div>" +
                        "</div>"
                    );

                    $container.find(".select2-result-repository__city").text(repo.text);
                    //$container.find(".select2-result-repository__country").text(repo.country.name);

                    return $container;
                },
                templateSelection: function (repo) {
                    return repo.text;
                }

            }).on('select2:select', function (e) {
                var data = e.params.data;
                var locationInfo = { id: null, provider: null, type: null, name: null };
                locationInfo.id = data.id;
                locationInfo.provider = data.provider;
                locationInfo.type = data.type;
                locationInfo.name = data.name;

                autocompleteData.value = JSON.stringify(locationInfo);
                //autocompleteData.value=data;
                //autocompleteName.value=data.city.name;
                autocompleteName.dispatchEvent(new Event('input', { 'bubbles': true }));
            });
            btnModal.addEventListener('click', function (ev) {
                editor.Modal.setTitle('Components JSON')
                    .setContent(modalEl)
                    .open();
            })
            return el;
        },
        onEvent({ elInput, component, event }) {
            const inputType = elInput.querySelector('.location-autocomplete__data');
            component.removeAttributes(['product', 'productType', 'provider', 'culture', 'productName']);
            let locationInfo = JSON.parse(inputType.value);
            component.addAttributes({ 'product': locationInfo.id, 'provider': locationInfo.provider, 'productType': 2, 'productName': locationInfo.name, 'culture': 'en-US' });


        },
        onUpdate({ elInput, component }) {

            // const href = component.getAttributes().href || '';
            const inputType = elInput.querySelector('.location-autocomplete__data');
            // let type = 'url';

            // if (href.indexOf('mailto:') === 0) {
            //   const inputEmail = elInput.querySelector('.href-next__email');
            //   const inputSubject = elInput.querySelector('.href-next__email-subject');
            //   const mailTo = href.replace('mailto:', '').split('?');
            //   const email = mailTo[0];
            //   const params = (mailTo[1] || '').split('&').reduce((acc, item) => {
            //     const items = item.split('=');
            //     acc[items[0]] = items[1];
            //     return acc;
            //   }, {});
            //   type = 'email';

            //   inputEmail.value = email || '';
            //   inputSubject.value = params.subject || '';
            // } else {
            //   elInput.querySelector('.href-next__url').value = href;
            // }

            // inputType.value = type;
            //inputType.dispatchEvent(new Event('change'));
        },
    });
    editor.TraitManager.addType('enum-select', {
        createLabel({ label }) {
            return `<div>
              ${label}
            </div>`;
        },
        createInput({ trait }) {
            const traitOpts = trait.get('options') || [];
            const val = this.model.getTargetValue() || traitOpts[0].id;
            const el = document.createElement('div');
            el.innerHTML = `
          <select class="enum-select__type">
            ${traitOpts.map(opt =>
                `<option value="${opt.id}">${opt.name}</option>`
            ).join('')}
          </select>
        `;
            el.querySelector('select').value = val;
            return el;
        },
        onEvent({ elInput, component }) {
            const inputType = elInput.querySelector('.enum-select__type');
            var propName = this.model.get('name');
            component.getTrait(propName).set('value', parseInt(inputType.value));
        }
    });
    editor.TraitManager.addType('select-gallery', {
        createLabel({ label }) {
            return `<div>
              ${label}
            </div>`;
        },

        createInput({ trait }) {

            const traitOpts = [
                {
                    id: 1, name: 'place holder', value: [
                        {
                            url: "https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png",
                            title: "place holder",
                            description: "lorem ipsum",
                            readMoreUrl: "https://www.google.com",
                            readMoreText: "Read More",
                        },
                        {
                            url: "https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png",
                            title: "place holder",
                            description: "lorem ipsum",
                            readMoreUrl: "https://www.google.com",
                            readMoreText: "Read More",
                        },
                        {
                            url: "https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png",
                            title: "place holder",
                            description: "lorem ipsum",
                            readMoreUrl: "https://www.google.com",
                            readMoreText: "Read More",
                        }
                    ]
                },
                {
                    id: 2, name: 'hotels', value: [
                        {
                            url: "https://media-cdn.tripadvisor.com/media/photo-s/22/0a/28/88/delphin-imperial-hotel.jpg",
                            title: "hotel 1",
                            description: "Antalya",
                            readMoreUrl: "https://www.google.com",
                            readMoreText: "Read More",
                        },
                        {
                            url: "https://img.directbooking.ro/getimage.ashx?file=5dda27ab-b16a-4187-b34e-23f17056c251.jpg",
                            title: "hotel 2",
                            description: "Amsterdam",
                            readMoreUrl: "https://www.google.com",
                            readMoreText: "Read More",
                        },
                        {
                            url: "https://images2.alphacoders.com/121/121132.jpg",
                            title: "hotel 3",
                            description: "Dubai",
                            readMoreUrl: "https://www.google.com",
                            readMoreText: "Read More",
                        }
                    ]
                }

            ];
            trait.set('options', traitOpts);
            const el = document.createElement('div');
            el.innerHTML = `
          <select class="select-gallery__type">
            ${traitOpts.map((opt) =>
                `<option value=${opt.id}=>${opt.name}</option>`
            ).join('')}
          </select>
        `;
            return el;
        },
        onEvent({ elInput, component, trait }) {
            const inputType = elInput.querySelector('.select-gallery__type');
            var propName = this.model.get('name');
            const traitOpts = trait.get('options') || [];
            var val = traitOpts.find(x => x.id == parseInt(inputType.value.replace("=", "")));
            component.getTrait(propName).set('value', val.value);
        }
    });
    editor.TraitManager.addType('component-selector', {
        //eventCapture: ['select'],
        createLabel({ label }) {
            return `<div>
              ${label}
            </div>`;
        },

        createInput({ trait }) {
            const traitOpts = editor.Components.componentTypes.filter(w => w.groupCode == trait.attributes.groupCode);
            trait.set('options', traitOpts);
            const el = document.createElement('div');
            el.innerHTML = `
          <select class="select-component_selector__type">
            ${traitOpts.map((opt) =>
                `<option value=${opt.code}>${opt.id}</option>`
            ).join('')}
          </select>
        `;
            const componentSelector = el.querySelector('.select-component_selector__type');
            componentSelector.addEventListener('change', () => this.onEvent)
            setTimeout(() => {
                componentSelector.value = trait.attributes.value;
                componentSelector.dispatchEvent(new CustomEvent('change', { bubbles: true }));
            });

            return el;
        },
        onEvent({ elInput, component, trait }) {
            const inputType = elInput.querySelector('.select-component_selector__type');
            var propName = this.model.get('name');
            component.getTrait(propName).set('value', inputType.value);

            if (inputType.value == '') {
                var selectedFromProps = component.attributes.attributes[component.attributes.attributes["selector-key"] ?? propName];
                inputType.value = selectedFromProps;
            }

            const cmp = editor.Components.componentTypes.find(w => w.code == inputType.value);
            if (cmp) {
                let trid = trait.id + '_';
                let traits = cmp.model.getDefaults().traits;
                let activeTraits = component.getTraits();
                activeTraits.forEach(f => {
                    if (f.id.includes('_') && f.id.includes(trid))
                        component.removeTrait(f.id);
                })
                traits.forEach(tr => {
                    component.addTrait({
                        type: tr.type,
                        name: trid + inputType.value + '_' + tr.name,
                        options: tr.options,
                        label: tr.label
                    });
                });
            }
        },
        onUpdate(el) {
        }
    });
    editor.TraitManager.addType('wysiwyg-editor', {

        //eventCapture: ['select'],
        noLabel: true,
        eventCapture: ['input'],
        createInput({ trait }) {
            const el = document.createElement('div');
            el.innerHTML = `
            <div class="wysiwyg-editor-container">
            <button class="wysiwyg-editor-modal btn btn-info">Html Editor</button>
            <input type="hidden" class="txt-wysiwyg-editor__data" value=""/>
            </div>
            `;
            const modalEl = document.createElement('div');
            modalEl.innerHTML = `
            <div class="wysiwyg-editor-textarea"></div>
            `;

            // Let's make our content interactive
            const txtEditor = modalEl.querySelector('.wysiwyg-editor-textarea');
            const txtData = el.querySelector('.txt-wysiwyg-editor__data');
            const btnModal = el.querySelector('.wysiwyg-editor-modal');
            jQ(txtEditor).summernote({
                tabsize: 2,
                dialogsInBody: true,
                height: 250,
                theme: 'default',
                toolbar: [
                    ['style', ['style']],
                    ['font', ['bold', 'underline', 'clear']],
                    ['color', ['color']],
                    ['para', ['ul', 'ol', 'paragraph']],
                    ['table', ['table']],
                    ['insert', ['link', 'picture', 'video']],
                    ['view', ['codeview']]
                ],
                callbacks: {
                    onChange: function (contents, $editable) {
                        txtData.value = contents;
                        txtData.dispatchEvent(new Event('input', { 'bubbles': true }));
                    }
                }
            });
            editor.Modal.onceOpen(() => {
                if (txtData.value != '')
                    jQ(txtEditor).summernote('code', txtData.value);

            });
            btnModal.addEventListener('click', function (ev) {
                editor.Modal.setTitle('Html Editor')
                    .setContent(modalEl)
                    .open();
            })
            return el;
        },
        onEvent({ elInput, component, trait }) {
            const inputType = elInput.querySelector('.txt-wysiwyg-editor__data');
            component.addAttributes({ 'htmlString': inputType.value });
            component.html = inputType.value;
        },
        onUpdate({ elInput, component }) {
            const inputType = elInput.querySelector('.txt-wysiwyg-editor__data');
            inputType.value = component.getAttributes().htmlString || '';
        },
    });

    editor.TraitManager.addType('title', {
        createLabel({ label }) {
            return `<div style="width:max-content;font-weight:bold;color:white;">
              ${label}
            </div>`;
        },
        createInput({ trait }) {
            const el = document.createElement('div');
            el.style.display = "none";
            return el;
        },

    });


    editor.TraitManager.addType('custom-selector-with-checkbox', {
        noLabel: true,
        createInput({ trait }) {
            const val = this.model.getTargetValue();
            let traitOpts = val.options ? val.options.map(v => ({ code: v.id, id: v.name })) : editor.Components.componentTypes.filter(w => w.groupCode == trait.attributes.groupCode);
            trait.set('options', traitOpts);

            const el = document.createElement('div');
            el.innerHTML = `
            <div style="background-color: black; text-align: center; color: white">Select And Order</div>
            <div class="custom-selector-with-checkbox">
                ${traitOpts.map((opt) =>
                `<div class="gjs-trt-trait gjs-trt-trait--checkbox" draggable="true">
                        <div class="gjs-label-wrp" data-label="" style="font-size: 12px">
                            <div class="gjs-label"></div>
                            <span id="order-${opt.code}"></span>
                            ${opt.id}
                        </div>
                        <div class="gjs-field-wrp gjs-field-wrp--checkbox" data-input="">
                            <label class="gjs-field gjs-field-checkbox" data-input="">
                                <input type="checkbox" placeholder="" value="${opt.code}">
                                <i class="gjs-chk-icon"></i>
                            </label>
                        </div>
                    </div>`
            ).join('')}
            </div>
            <div class="selector-area" style="display: ${val.selectorVisibilityStatus ? 'block' : 'none'}">
            <div style="background-color: black; text-align: center; color: white;">Selector</div>
            <select class="selectable-traits" style="border-top: 1px solid #ddd">
                ${traitOpts.map((opt) =>
                `<option value=${opt.code}>${opt.id}</option>`
            ).join('')}
            </select>
            </div>
            `;
            const checkboxes = el.querySelectorAll('.custom-selector-with-checkbox input[type="checkbox"]');
            checkboxes.forEach(checkbox => {
                if (val && val.selectableOptions?.includes(checkbox.value)) {
                    checkbox.checked = true;
                }
            });

            const componentSelector = el.querySelector('.selectable-traits');
            componentSelector.addEventListener('change', () => this.onEvent);
            setTimeout(() => {
                componentSelector.value = val.selected;
                componentSelector.dispatchEvent(new CustomEvent('change', { bubbles: true }));
            });

            return el;
        },
        onEvent({ elInput, component, trait }) {
            const propName = this.model.get('name');
            const val = this.model.getTargetValue();
            let traitOpts = val.options ? val.options.map(v => ({ code: v.id, id: v.name })) : editor.Components.componentTypes.filter(w => w.groupCode === trait.attributes.groupCode);
            trait.set('options', traitOpts);

            const inputType = elInput.querySelector('.selectable-traits');
            const selectedValues = val.selectableOptions || [];
            const checkboxes = elInput.querySelectorAll('.custom-selector-with-checkbox input[type="checkbox"]');
            let checkedValue = inputType.value;
            component.getTrait(propName).set('value', {});

            function updateSelectedValues() {
                checkboxes.forEach(checkbox => {
                    const isChecked = checkbox.checked;
                    const value = checkbox.value;
                    if (isChecked && !selectedValues.includes(value)) {
                        selectedValues.push(value);
                        checkedValue = value;
                    } else if (!isChecked && selectedValues.includes(value)) {
                        const index = selectedValues.indexOf(value);
                        selectedValues.splice(index, 1);
                        checkedValue = selectedValues.length == 1 ? selectedValues[0] : selectedValues.filter(s => s != checkedValue)[selectedValues.length - 2];
                    }
                });
            }
            updateSelectedValues();
            traitOpts.forEach(value => {
                const orderElement = elInput.querySelector(`#order-${value.code}`);
                if (orderElement) {
                    const isSelected = selectedValues.includes(value.code);
                    orderElement.innerHTML = isSelected ? `${selectedValues.indexOf(value.code) + 1}-` : '';
                }
            });

            inputType.innerHTML = '';
            selectedValues.forEach(selectedValue => {
                const foundTrait = traitOpts.find(t => t.code === selectedValue);
                if (foundTrait) {
                    const option = document.createElement('option');
                    option.text = foundTrait.id;
                    option.value = selectedValue;
                    inputType.appendChild(option);
                    inputType.value = checkedValue;
                }
            });

            component.getTrait(propName).set('value', {
                selected: checkedValue,
                selectableOptions: selectedValues,
                options: val.options,
                selectorVisibilityStatus: val.selectorVisibilityStatus
            });


            const cmp = editor.Components.componentTypes.find(w => w.code === inputType.value);
            const trid = trait.id + '_';
            const activeTraits = component.getTraits();

            activeTraits.forEach(f => {
                if (f.id.includes('_') && f.id.includes(trid)) {
                    component.removeTrait(f.id);
                }
            });

            if (cmp) {
                const traits = cmp.model.getDefaults().traits;

                traits.forEach(tr => {
                    component.addTrait({
                        type: tr.type,
                        name: trid + inputType.value + '_' + tr.name,
                        options: tr.options,
                        label: tr.label,
                        groupCode: tr.groupCode
                    });
                });

            }
        }
    });
    editor.TraitManager.addType('data-source-form-selector', {
        events: {
            // Listen for changes in the dropdown value
            change: function () {
                // Get the value selected in the dropdown
                const selectedValue = this.model.get('value');
                // Do something with the selected value
                console.log('Selected value:', selectedValue);
            }
        },
        getInputEl: function () {
            // Create a select element for the dropdown
            const selectEl = document.createElement('select');
            const requestData = {
                pageSize: 0
            };
            // Populate the dropdown options asynchronously from your backend service
            fetch(process.env.REACT_APP_API_URL + "/datasourceformtemplate/list", {
                method: 'POST',
                headers: {
                    'X-Site-CultureId': CookieManager.get('siteCultureId'),
                    'Authorization': CookieManager.get('applicationTokenPanel'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestData)
            })
                .then(response => response.json())
                .then(data => {
                    if (data && data.header.success) {
                        var selected = this.target.attributes.attributes.formTemplateId;

                        const chooseOptionEl = document.createElement('option');
                        chooseOptionEl.classList.add("custom-form-selector")
                        chooseOptionEl.value = "";
                        chooseOptionEl.textContent = "Choose A Form";
                        selectEl.appendChild(chooseOptionEl);
                        data.body.data.forEach(option => {
                            const optionEl = document.createElement('option');
                            optionEl.value = option.id;
                            optionEl.selected = selected == option.id;
                            optionEl.textContent = option.name;
                            selectEl.appendChild(optionEl);
                        });
                    }
                })
                .catch(error => console.error('Error fetching data:', error));
            return selectEl;
        },
        onEvent({ elInput, component, event }) {
            var selected = _.find(elInput.options, function (e) {
                return e.selected == true;
            })
            if (selected != null) {
                component.addAttributes({ formTemplateId: selected.value });
            }
            else {
                component.addAttributes({ formTemplateId: "" });
            }
        },
    });
    editor.TraitManager.addType('menu-items-selector-with-checkbox', {
        noLabel: true,
        createInput({ trait }) {
            const val = this.model.getTargetValue();
            let traitOpts = val.options ? val.options.map(v => ({ code: v.id, id: v.name })) : editor.Components.componentTypes.filter(w => w.groupCode == trait.attributes.groupCode);
            trait.set('options', traitOpts);

            const el = document.createElement('div');
            el.innerHTML = `
            <div style="background-color: black; text-align: center; color: white">Select And Order</div>
            <div class="custom-selector-with-checkbox">
                ${traitOpts.map((opt) =>
                `<div class="gjs-trt-trait gjs-trt-trait--checkbox" draggable="true">
                        <div class="gjs-label-wrp" data-label="" style="font-size: 12px">
                            <div class="gjs-label"></div>
                            <span id="order-${opt.code}"></span>
                            ${opt.id}
                        </div>
                        <div class="gjs-field-wrp gjs-field-wrp--checkbox" data-input="">
                            <label class="gjs-field gjs-field-checkbox" data-input="">
                                <input type="checkbox" placeholder="" value="${opt.code}">
                                <i class="gjs-chk-icon"></i>
                            </label>
                        </div>
                    </div>`
            ).join('')}
            </div>
            <div class="selector-area" style="display: ${val.selectorVisibilityStatus ? 'block' : 'none'}">
            <div style="background-color: black; text-align: center; color: white;">Selector</div>
            <select class="selectable-traits" style="border-top: 1px solid #ddd">
                ${traitOpts.map((opt) =>
                `<option value=${opt.code}>${opt.id}</option>`
            ).join('')}
            </select>
            <div class="view-type-area" style="display:none">
            <div style="background-color: black; text-align: center; color: white;">Selected Menu View Type</div>
            <select class="selectable-types" style="border-top: 1px solid #ddd">
                <option value="0">Choose</option>
                <option value="1">Standard</option>
                <option value="2">Dropdown</option>
                <option value="3">Mega</option>
            </select> 
  
            </div>
            </div>
            `;
            const checkboxes = el.querySelectorAll('.custom-selector-with-checkbox input[type="checkbox"]');
            checkboxes.forEach(checkbox => {
                if (val && val.selectableOptions?.includes(checkbox.value)) {
                    checkbox.checked = true;
                }
            });

            const componentSelector = el.querySelector('.selectable-traits');
            componentSelector.addEventListener('change', () => this.onEvent);
            setTimeout(() => {
                componentSelector.value = val.selected;
                componentSelector.dispatchEvent(new CustomEvent('change', { bubbles: true }));
            });

            return el;
        },
        onEvent({ elInput, component, trait }) {
            component.attributes.attributes.dataList.forEach(data => {
                if (data.payloadSheme.includes("selectedMenus")) {
                    data.payloadSheme = "{\"selectedMenus\":{selectedMenus}}";
                }
            })
            const propName = this.model.get('name');
            const val = this.model.getTargetValue();
            let traitOpts = val.options ? val.options.map(v => ({ code: v.id, id: v.name })) : editor.Components.componentTypes.filter(w => w.groupCode === trait.attributes.groupCode);
            trait.set('options', traitOpts);
            const inputChooseValue = "0";
            const inputType = elInput.querySelector('.selectable-traits');
            const selectedValues = val.selectableOptions || [];
            const checkboxes = elInput.querySelectorAll('.custom-selector-with-checkbox input[type="checkbox"]');
            let checkedValue = inputType.value;
            if (checkedValue != val.selected) {
                const selectableTypes = elInput.querySelector('.selectable-types');
                selectableTypes.value = inputChooseValue;
            }
            component.getTrait(propName).set('value', {});


            function updateSelectedValues() {
                checkboxes.forEach(checkbox => {
                    const isChecked = checkbox.checked;
                    const value = checkbox.value;
                    if (isChecked && !selectedValues.includes(value)) {
                        selectedValues.push(value);
                        checkedValue = value;
                    } else if (!isChecked && selectedValues.includes(value)) {
                        const index = selectedValues.indexOf(value);
                        selectedValues.splice(index, 1);
                        checkedValue = selectedValues.length == 1 ? selectedValues[0] : selectedValues.filter(s => s != checkedValue)[selectedValues.length - 2];
                    }
                });
            }
            updateSelectedValues();
            traitOpts.forEach(value => {
                const orderElement = elInput.querySelector(`#order-${value.code}`);
                if (orderElement) {
                    const isSelected = selectedValues.includes(value.code);
                    orderElement.innerHTML = isSelected ? `${selectedValues.indexOf(value.code) + 1}-` : '';
                }
            });

            if (val.selected || val.selected == "")
                elInput.querySelector('.view-type-area').style.display = 'block';

            val.options.forEach(value => {
                const selectableTypes = elInput.querySelector('.selectable-types');
                if (checkedValue !== "" && value.id === checkedValue) {
                    if (selectableTypes.value == inputChooseValue) {
                        selectableTypes.value = Number(value.code);
                    } else {
                        value.code = selectableTypes.value;
                    }
                }
            });
            inputType.innerHTML = '';
            selectedValues.forEach(selectedValue => {
                const foundTrait = traitOpts.find(t => t.code === selectedValue);
                if (foundTrait) {
                    const option = document.createElement('option');
                    option.text = foundTrait.id;
                    option.value = selectedValue;
                    inputType.appendChild(option);
                    inputType.value = checkedValue;
                }
            });

            component.getTrait(propName).set('value', {
                selected: checkedValue,
                selectableOptions: selectedValues,
                options: val.options,
                selectorVisibilityStatus: val.selectorVisibilityStatus
            });


            component.addAttributes({ 'selectedMenus': JSON.stringify(val.selectableOptions) });
            const trid = trait.id + '_';
            const activeTraits = component.getTraits();

            activeTraits.forEach(f => {
                if (f.id.includes('_') && f.id.includes(trid)) {
                    component.removeTrait(f.id);
                }
            });


        }
    })
    editor.TraitManager.addType('content-selector', {
        events: {
            // Listen for changes in the dropdown value
            change: function () {
                // Get the value selected in the dropdown
                const selectedValue = this.model.get('value');
                // Do something with the selected value
            }
        },
        getInputEl: function () {
            // Create a select element for the dropdown
            const selectEl = document.createElement('select');

            const requestData = {
                pageSize: 0
            };
            const cDataParams = editor.editor._config.cData;
            if (cDataParams != null && typeof cDataParams == "object") {
                var contentType = cDataParams["type"];
                if (contentType != null)
                    requestData.type = parseInt(contentType);
            }
            else
                requestData.type = B2CUiEnums.enmContentDefinitionType.HtmlContent;

            // Populate the dropdown options asynchronously from your backend service
            fetch(process.env.REACT_APP_API_URL + "/contentdefinition/list", {
                method: 'POST',
                headers: {
                    'X-Site-CultureId': CookieManager.get('siteCultureId'),
                    'Authorization': CookieManager.get('applicationTokenPanel'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestData)
            })
                .then(response => response.json())
                .then(data => {
                    if (data && data.header.success) {
                        var selected = this.target.attributes.attributes.contentId;

                        const chooseOptionEl = document.createElement('option');
                        chooseOptionEl.classList.add("custom-form-selector")
                        chooseOptionEl.value = "";
                        chooseOptionEl.textContent = "Choose A Content";
                        selectEl.appendChild(chooseOptionEl);
                        data.body.data.forEach(option => {
                            const optionEl = document.createElement('option');
                            optionEl.value = option.id;
                            optionEl.selected = selected == option.id;
                            optionEl.textContent = option.name;
                            selectEl.appendChild(optionEl);
                        });
                    }
                })
                .catch(error => console.error('Error fetching data:', error));
            return selectEl;
        },
        onEvent({ elInput, component, event }) {
            var selected = _.find(elInput.options, function (e) {
                return e.selected == true;
            })
            if (selected != null && !ObjectValueController.isNullOrUndefinedOrEmpty(selected.value)) {
                var extraParams = editor.editor._config.cData;
                if (extraParams != null && typeof extraParams == "object") {
                    var siteCulture = extraParams["siteCultureId"];
                    if (siteCulture != null)
                        component.addAttributes({ contentDefinitionId: selected.value, cultureId: siteCulture });
                    else
                        component.addAttributes({ contentDefinitionId: selected.value });
                }
                else
                    component.addAttributes({ contentDefinitionId: selected.value });
            }
            else {
                component.addAttributes({ contentDefinitionId: "" });
            }
        }
    });

    editor.TraitManager.addType('gallery-selector', {
        events: {
        },
        getInputEl: function () {
            // Create a select element for the dropdown
            const selectEl = document.createElement('select');
            const requestData = {
                pageSize: 0,
            };

            var extraParams = editor.editor._config.cData;
            if (extraParams != null && typeof extraParams == "object") {
                var siteCulture = extraParams["siteCultureId"];
                if (siteCulture != null)
                    requestData.cultureId = siteCulture;
            }

            // Populate the dropdown options asynchronously from your backend service
            fetch(process.env.REACT_APP_API_URL + "/gallery/list", {
                method: 'POST',
                headers: {
                    'X-Site-CultureId': CookieManager.get('siteCultureId'),
                    'Authorization': CookieManager.get('applicationTokenPanel'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestData)
            })
                .then(response => response.json())
                .then(data => {
                    if (data && data.header.success) {
                        var componentSelector = this.target.attributes.attributes.cIGA;
                        var propSelector = "cIGA_" + componentSelector + "_galleryId";
                        var selected = this.target.attributes.attributes[propSelector];

                        const chooseOptionEl = document.createElement('option');
                        chooseOptionEl.classList.add("custom-form-selector")
                        chooseOptionEl.value = "-99";
                        chooseOptionEl.textContent = "Choose Manually";
                        selectEl.appendChild(chooseOptionEl);
                        data.body.data.forEach(option => {
                            const optionEl = document.createElement('option');
                            optionEl.value = option.id;
                            optionEl.selected = selected == option.id;
                            optionEl.textContent = option.name;
                            selectEl.appendChild(optionEl);
                        });
                        if (selectEl) {
                            var imageOpener = $(".image-opener-modal");
                            var imageOrderer = $(".image-orderer-modal");
                            if (imageOpener.length > 0) {
                                if ($(imageOpener).css("display") != "none") {
                                    $(imageOpener).css("display", "none");
                                }
                            }
                            if (imageOrderer.length > 0) {
                                if ($(imageOrderer).css("display") != "none") {
                                    $(imageOrderer).css("display", "none");
                                }
                            }
                        }
                    }
                })
                .catch(error => console.error('Error fetching data:', error));
            return selectEl;
        },
        onEvent({ elInput, component, event }) {
            var selected = _.find(elInput.options, function (e) {
                return e.selected == true;
            })

            var column = component.attributes.attributes;
            var imageOpener = $(".image-opener-modal");
            var imageOrderer = $(".image-orderer-modal");
            if (selected != null && !ObjectValueController.isNullOrUndefinedOrEmpty(selected.value) && selected.value != "-99") {
                if (imageOpener.length > 0) {
                    if ($(imageOpener).css("display") != "none") {
                        $(imageOpener).css("display", "none");
                    }
                }
                if (imageOrderer.length > 0) {
                    if ($(imageOrderer).css("display") != "none") {
                        $(imageOrderer).css("display", "none");
                    }
                }
                var attrSelector = "cIGA_";
                var attr = component.attributes.attributes.cIGA;
                if (attr) {
                    attrSelector += attr + "_images";
                }
                component.removeAttributes([attrSelector]);
                if (selected.value != "-99")
                    component.addAttributes({ [`cIGA_${attr}_galleryId`]: selected.value });
                else {
                    component.removeAttributes([`cIGA_${attr}_galleryId`]);
                    //component.addAttributes({ galleryId: undefined });
                }

            }
            else {
                var attr = component.attributes.attributes.cIGA;
                if (imageOpener.length > 0) {
                    if ($(imageOpener).css("display") == "none") {
                        $(imageOpener).css("display", "block");
                    }
                }
                if (imageOrderer.length > 0) {
                    if ($(imageOrderer).css("display") == "none") {
                        $(imageOrderer).css("display", "block");
                    }
                }
                component.removeAttributes([`cIGA_${attr}_galleryId`]);
                // component.addAttributes({ [`cIGA_${attr}_galleryId`]: undefined });
            }
        }
    });

    editor.TraitManager.addType('image-selector', {
        template: '',
        noLabel: true,
        eventCapture: ['input'],
        createInput({ trait }) {
            // Here we can decide to use properties from the trait
            const component = trait.target.view.attr.cIGA;
            var selector = "cIGA_" + component + "_images";
            var values = trait.target.view.attr[selector];
            const el = document.createElement('div');
            var orderSelector = "cIGA_" + component + "_orders";
            var orders = trait.target.view.attr[orderSelector];
            var gallerySelector = "cIGA_" + component + "_galleryId";
            var galleryId = trait.target.view.attr[gallerySelector];
            el.innerHTML = `
                <button class="image-opener-modal btn btn-info">Choose Images</button>
                <button class="image-orderer-modal btn btn-info">Change Order</button>
                <input type="text" id="image-src-input" data-id="" style="display:none;"></input>
                <input type="text" id="order-holder" data-order-list="" style="display:none;"></input>
                <input type="text" id="appfile-holder" data-list="" style="display:none;"></input>
            `;

            var inputEl = el.querySelector('#image-src-input');
            var dataListEl = el.querySelector('#appfile-holder');
            if (values != "" && values != null) {
                $(inputEl).attr("data-id", values.join(";"));
            }
            var inputSrc = $(inputEl).attr("data-id");
            $(el).css({ "display": "flex", "flex-direction": "column", "gap": "5px" });
            var srcList = [];
            if (inputSrc != "") {
                srcList = inputSrc.split(";");
            }
            const modalEl = document.createElement('div');
            $(modalEl).css({ "max-height": "600px", "overflow-y": "scroll" });
            const modalElHtml = `
                        <div class='image-wrapper' style='display: flex;justify-content: flex-end;'> 
                            <button class="image-ok btn btn-info" style="margin-right:10px;">Choose</button>
                            <button class="image-closer btn btn-info">Close</button>
                        </div>`;

            const orderModalEl = document.createElement('div');
            orderModalEl.innerHTML = `
            <div class='image-orderer' style='display: flex;justify-content: flex-end;'> 
                <button class="image-order-ok btn btn-info" style="margin-right:10px;">Choose</button>
                <button class="image-order-closer btn btn-info">Close</button>
            </div>
            <div class="image-orderer-body"> </div>`;

            //#region API CALL For App Files
            const requestData = {
                pageSize: 0,
                isBase: true
            };

            fetch(process.env.REACT_APP_API_URL + "/appfile/list", {
                method: 'POST',
                headers: {
                    'X-Site-CultureId': CookieManager.get('siteCultureId'),
                    'Authorization': CookieManager.get('applicationTokenPanel'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestData)
            })
                .then(response => response.json())
                .then(data => {
                    //#region Modal HTML Creation
                    if (data && data.header.success) {
                        modalEl.innerHTML = modalElHtml;
                        $(inputEl).attr("data-id", srcList.join(";"));
                        $(dataListEl).attr("data-list", JSON.stringify(data.body.data));
                        _.each(data.body.data, function (d, i) {
                            modalEl.innerHTML += `
                                <img id='s-image-${i}' style='width:300px;max-height:200px;margin:10px;cursor:pointer;' data-id='${d.id}' src='${d.imagePath}' />
                            `;
                        });
                    }
                    //#endregion
                })
                .catch(error => console.error('Error fetching data:', error));

            //#endregion

            //#region Modal Buton Operations
            const btnModal = el.querySelector('.image-opener-modal');
            btnModal.addEventListener('click', function (ev) {
                editor.Modal.setTitle('Images')
                    .setContent(modalEl)
                    .open();

                const btnOk = $('.image-ok');
                const btnClose = $('.image-closer');

                btnOk[0].addEventListener('click', function (ev) {
                    var inputEl = $('#image-src-input')[0];
                    var selectedImageIds = $(inputEl).attr("data-id");
                    $(el).attr("selected-srcs", selectedImageIds);
                    el.dispatchEvent(new Event('input', { 'bubbles': true }));
                    editor.Modal.close();
                });

                btnClose[0].addEventListener('click', function (ev) {
                    editor.Modal.close();
                });

                const images = document.querySelectorAll('img');
                images.forEach((image) => {
                    if (image.id.startsWith('s-image-')) {
                        var inputEl = $('#image-src-input')[0];
                        var inputSrc = $(inputEl).attr("data-id");
                        var srcList = [];
                        if (inputSrc != "") {
                            srcList = inputSrc.split(";");
                        }
                        var appFileId = $(image).attr("data-id");
                        if (srcList.includes(appFileId)) {
                            var el = $(`#${image.id}`);
                            if (el.length > 0) {
                                $(el).css("opacity", "0.2");
                            }
                        }
                        image.addEventListener('click', () => {
                            var inputEl = $('#image-src-input')[0];
                            var inputSrc = $(inputEl).attr("data-id");
                            var srcList = [];
                            if (inputSrc != "") {
                                srcList = inputSrc.split(";");
                            }
                            var appFileId = $(image).attr("data-id");
                            if (!srcList.includes(appFileId)) {
                                srcList.push(appFileId);
                                var el = $(`#${image.id}`);
                                if (el.length > 0) {
                                    $(el).css("opacity", "0.2");
                                }
                            } else {
                                srcList = _.filter(srcList, function (s) { return s != appFileId });
                                var el = $(`#${image.id}`);
                                if (el.length > 0) {
                                    $(el).css("opacity", "1");
                                }
                            }
                            $(inputEl).attr("data-id", srcList.join(";"));
                            $(el).attr("selected-srcs", srcList.join(";"));
                        });
                    }
                });
            })

            const btnOrderModal = el.querySelector('.image-orderer-modal');
            btnOrderModal.addEventListener('click', function (ev) {
                editor.Modal.setTitle('Orders')
                    .setContent(orderModalEl)
                    .open();

                const btnOrderOk = $('.image-order-ok');
                const btnOrderClose = $('.image-order-closer');

                btnOrderOk[0].addEventListener('click', function (ev) {
                    var modalBody = document.querySelector(".image-orderer-body");
                    var dropzoneImgs = modalBody.querySelectorAll("img");
                    var orderedImageIds = [];
                    _.each(dropzoneImgs, function (img) {
                        orderedImageIds.push($(img).attr("data-img-id"));
                    })
                    // var orderInputEl = $('#order-holder')[0];
                    // var orderedImageIds = $(orderInputEl).attr("data-order-list");
                    $(el).attr("selected-orders", orderedImageIds.join(";"));
                    el.dispatchEvent(new Event('input', { 'bubbles': true }));
                    editor.Modal.close();
                });

                btnOrderClose[0].addEventListener('click', function (ev) {
                    editor.Modal.close();
                });


                var ordererModalBody = orderModalEl.querySelector(".image-orderer-body");
                const imageOrdererEl = document.createElement('div');
                imageOrdererEl.id = "dropzone";
                ordererModalBody.innerHTML = '';
                $(imageOrdererEl).css({ "display": "flex", "flex-direction": "row", "gap": "10px" });
                var fileHolder = el.querySelector('#appfile-holder');
                var stringAppFileData = $(fileHolder).attr("data-list");
                var parsedAppFiles = [];
                var selectedImageEl = el.querySelector("#image-src-input");
                var stringSelectedFileData = $(selectedImageEl).attr("data-id");
                var splittedSelectedFiles = [];
                if (stringAppFileData != "")
                    parsedAppFiles = JSON.parse(stringAppFileData);
                if (orders != null && orders != "")
                    splittedSelectedFiles = orders;
                else if (stringSelectedFileData != "")
                    splittedSelectedFiles = stringSelectedFileData.split(";");
                _.each(splittedSelectedFiles, function (value) {
                    var foundImage = _.find(parsedAppFiles, function (a) { return a.id == value });
                    if (foundImage) {
                        imageOrdererEl.innerHTML += `<img src="${foundImage.imagePath}" data-img-id="${foundImage.id}" draggable="true" class="drag-image-item" style="width:150px;height:100px;background-color:lightblue;text-align:center;line-height:100px;cursor:pointer;border:1px solid #000;"/>`;
                    }
                });
                ordererModalBody.appendChild(imageOrdererEl);

                ordererModalBody.querySelectorAll('.drag-image-item').forEach(item => {
                    item.addEventListener('dragstart', (event) => {
                        draggedElement = item;
                        $(item).css("opacity", "0.5");
                        $(item).addClass("dragging");
                    });

                    item.addEventListener('dragend', () => {
                        draggedElement = null;
                        $(item).css("opacity", "1");
                        $(item).removeClass("dragging");
                    });
                });

                let draggedElement = null;
                imageOrdererEl.addEventListener('dragover', (event) => {
                    event.preventDefault();
                    const afterElement = getDragAfterElement(imageOrdererEl, event.clientX, event.clientY);
                    if (afterElement == null) {
                        imageOrdererEl.appendChild(draggedElement);
                    } else {
                        imageOrdererEl.insertBefore(draggedElement, afterElement);
                    }
                });

                function getDragAfterElement(imageOrdererEl, x, y) {
                    const draggableElements = [...imageOrdererEl.querySelectorAll('.drag-image-item:not(.dragging)')];

                    return draggableElements.reduce((closest, child) => {
                        const box = child.getBoundingClientRect();
                        const offset = (y - box.top - box.height / 2) + (x - box.left - box.width / 2);
                        if (offset < 0 && offset > closest.offset) {
                            return { offset: offset, element: child };
                        } else {
                            return closest;
                        }
                    }, { offset: Number.NEGATIVE_INFINITY }).element;
                }
            })
            //#endregion
            return el;
        },
        onEvent({ elInput, component, event }) {
            var attrSelector = "cIGA_";
            var componentCodeAttr = component.attributes.attributes.cIGA;
            var srcListSelector = attrSelector + componentCodeAttr + "_images";
            var orderListSelector = attrSelector + componentCodeAttr + "_orders";

            var srcList = $(elInput).attr("selected-srcs");
            var orderList = $(elInput).attr("selected-orders");

            if (orderList != "" && orderList != null) {
                component.removeAttributes([orderListSelector]);
                component.addAttributes({ [orderListSelector]: orderList.split(";") });
            }
            if (srcList != null && srcList != "") {
                component.removeAttributes([srcListSelector]);
                component.addAttributes({ [srcListSelector]: srcList.split(";") });
            }
        },
        onUpdate({ elInput, component }) {
        },
    });
}