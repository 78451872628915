import {B2CApiServiceModels,B2CApiServices} from '@santsg/ui-component-core';

export default async (editor, opt = {}) => {
    let bm = editor.BlockManager;
    const replaceName = (name) => {
        return name.replaceAll(' ', '_').toLowerCase()
    }
    let req = new B2CApiServiceModels.Module.CompositComponent.CompositComponentListRequest();
    req.withLayout = true;
    B2CApiServices.CompositComponentService.List(req, false, true).then(t => {
        t.body.data.forEach(f => {
            let fName = replaceName(f.name);

            bm.add(fName, {
                label: f.name,
                category: f.category,
                attributes: { customComponent: true },
                content: `<div data-gjs-type="LayoutWrapper" withOutManager=true layoutId="${f.layoutId}"></div>`
                //components: 
            })
        });
    });
    const categories = editor.BlockManager.getCategories();
    categories.each(category => {
        console.log(category);
        category.set('open', false).on('change:open', opened => {
            opened.get('open') && categories.each(category => {
                category !== opened && category.set('open', false)
            })
        })
    })

}