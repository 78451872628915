import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CookieManager } from '@santsg/ui-common';
import { B2CApiModels,B2CApiServiceModels,B2CApiServices } from '@santsg/ui-component-core';

export const getSiteSettings = createAsyncThunk('setting/grouped-list', async () => {
    const request = new B2CApiServiceModels.Module.Settings.GroupedSettingListRequest();
    const response = await B2CApiServices.SettingService.GroupedList(request, false, true);
    return { settings: response.body };
});

const initialState: B2CApiModels.Module.SettingModel.ISettings = {
    panel: {} as B2CApiModels.Module.SettingModel.ISettingsPanel,
    general: {} as B2CApiModels.Module.SettingModel.ISettingsGeneral,
    partner: {} as B2CApiModels.Module.SettingModel.ISettingsPartner,
};

export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(getSiteSettings.fulfilled, (state, action) => {
            const settings = action.payload.settings;
            if (settings) {
                state.general = settings.general;
                state.panel = settings.panel;
                state.partner = settings.partner;
            }
        });
    },
});


export default settingsSlice.reducer;
