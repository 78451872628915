import { ReactNode } from "react"
import { Module, Permission } from 'models/enums/moduleAndPermission';
import { EnumValues } from "@santsg/ui-common"
import { useAppSelector } from "store/hooks";
import { useNavigate } from "react-router-dom";

type Props = {
    permissions: Array<Permission>
    module: Module
    children: ReactNode
    errorState?: 'RedirectToLogin' | 'ShowMessage' | 'Empty'
}
const SanAbility: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    const auth = useAppSelector(s => s.auth);

    function checkPermission(): boolean {
        var perm = false;
        let permissions = auth?.authorizedModules;
        let requestedModule = permissions?.find((w:any) => w.module == props.module.toString());
        if (requestedModule != null) {
            props.permissions.forEach(f => {
                if (!perm)
                    perm = requestedModule?.permissions.find((w:any) => w == f) != null;
            })
        }
        return perm;
    }
    function returnResponse() {
        if (checkPermission())
            return props.children;
        else
            switch (props.errorState) {
                case "RedirectToLogin": navigate('/login'); break;
                case "ShowMessage": return <p>You are not authorized to view the {EnumValues.getNameFromValue(Module, props.module)} module</p>; break;
                default: return <></>
            }
    }

    return (
        <>
            {returnResponse()}
        </>
    )
}
export default SanAbility;