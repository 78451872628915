import { useNavigate } from "react-router-dom";
import { removeSession } from "store/features/user/UserSlice";
import { useAppDispatch } from "store/hooks";

export function useApiInterceptor(pApiUrl:string) {
  const dispath=useAppDispatch();
    XMLHttpRequest = new Proxy(XMLHttpRequest, {
        construct: function (target, args) {
          //@ts-ignore
          const xhr = new target(...args);
          xhr.addEventListener('load',function(t){
            if(this.responseURL.indexOf(pApiUrl!.toString())>=0)
            {
                    if (xhr.readyState === 4) {
                      if (xhr.status === 401 || xhr.status==403) {
                        // dispath(removeSession(undefined));
                        // // eslint-disable-next-line no-restricted-globals
                        // if(location.pathname!=='/login')
                        // // eslint-disable-next-line no-restricted-globals
                        //   location.href='/login';
                      }
                    }
            }
          })
          return xhr;
        },
      });
}