import _ from 'lodash';
import { Fragment, useEffect } from "react";
import { SanInputErrorLabelProps } from "models/types/san-elements/SanInputErrorLabelProps";

export const SanInputErrorLabel = (props: SanInputErrorLabelProps) => {
    useEffect(() => {
    }, [props])

    return (
        <Fragment>
            {
                props.hasError && props.errorText
                &&
                <div id={`${props.name}-${props.controlId}-error-message`} role="alert" aria-relevant="all" className="rs-form-control-message-wrapper rs-form-error-message-wrapper rs-form-error-message-placement-bottom-start">
                    <span className="rs-form-error-message rs-form-error-message-show">
                        <span className="rs-form-error-message-arrow">
                        </span>
                        <span className="rs-form-error-message-inner">
                            {props.errorText}
                        </span>
                    </span>
                </div>
            }
        </Fragment>
    )
}