import { useEffect, useState } from "react";
import { B2CApiServiceModels, B2CApiServices, B2CApiEnums, B2CUiModels, B2CUiEnums, B2CApiModels } from "@santsg/ui-component-core";
import { Module, Permission } from "models/enums/moduleAndPermission";
import SanAbility from "components/sanability/SanAbility";
import { enmActionCellType } from "models/enums/ActionCellType";
import { enmButtonAppearance } from "models/enums/ButtonAppearance";
import { enmButtonColor } from "models/enums/ButtonColor";
import { enmFilterType } from "models/enums/FilterType";
import { enmFilterInputShowType } from "models/enums/FilterInputShowType";
import SanPagination from "components/common/SanPagination";
import _ from 'lodash';
import { ObjectValueController } from "@santsg/ui-common";
import { useAppSelector } from "store/hooks";
import SanModal from "components/common/SanModal";
import { Stack } from "rsuite";
import SanPageTools from "components/common/SanPageTools";
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import SanTable from "components/common/SanTable";
import { SanColumnItem } from "models/types/san-elements/SanColumnItem";
import { SanActionWrapper } from "models/types/san-elements/SanActionWrapper";
import { SanFilterData } from "models/types/san-elements/SanFilterData";
import SanFilter from "components/common/SanFilter";
import { SanRemoveConfirmationProps } from "models/types/san-elements/SanRemoveConfirmationProps";
import SanRemoveConfirmation from "components/common/SanRemoveConfirmation";
import { ReceiverFormProps } from "models/types/receiver/ReceiverFormProps";
import { ReceiverForm } from "./ReceiverForm";


function ReceiverList(): JSX.Element {
    const settings = useAppSelector(state => state.settings);
    const [t] = useTranslation();
    const [listRequest, setListRequest] = useState<B2CApiServiceModels.Module.Receiver.ReceiverListRequest>(new B2CApiServiceModels.Module.Receiver.ReceiverListRequest());
    const [listResponse, setListResponse] = useState<B2CApiServiceModels.Module.Receiver.ReceiverListResponse>();
    const [openEditModal, setOpenEditModal] = useState<boolean>(false);
    const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false);
    const [forceClearFilters, setForceClearFilters] = useState<boolean>(false);
    const [receiver, setReceiver] = useState<B2CApiModels.Module.ReceiverModel.mdlReceiver>();
    const [removeReceiverId, setRemoveReceiverId] = useState<string>();

    useEffect(() => {
        if (listResponse == null)
            getReceivers();
    }, [listResponse]);


    //#region API CALLS
    async function getReceivers(request?: B2CApiServiceModels.Module.Receiver.ReceiverListRequest) {
        let req = request ? request : listRequest;
        setListRequest(req);
        var response = await B2CApiServices.ReceiverService.List(req, false, true);
        if (response && response.header.success && response.body)
            setListResponse(response.body);
        else {
            setListResponse(new B2CApiServiceModels.Module.Receiver.ReceiverListResponse([]));
        }
        setForceClearFilters(false);
    }

    async function getReceiver(id: string) {
        var request = new B2CApiServiceModels.Module.Receiver.ReceiverDetailRequest(id);
        var response = await B2CApiServices.ReceiverService.Detail(request, false, true);
        if (response && response.header.success && response.body?.receiver) {
            setReceiver(response.body.receiver);
            setOpenEditModal(true);
        }
    }

    async function deleteReceiver() {
        if (removeReceiverId) {
            var response = await B2CApiServices.ReceiverService.Delete(new B2CApiServiceModels.Module.Receiver.ReceiverDeleteRequest(removeReceiverId), false, true);
            if (response && response.header.success && response.body && response.body.deleted) {
                toast.success(t("common.success"));
                getReceivers();
            }
            else {
                toast.error(response.header?.messages?.map(messageModel => messageModel.message).join('.'));
            }
        }
    }
    //#endregion

    //#region MODAL CONTROLS
    const handleEditModalClose = () => {
        setOpenEditModal(false);
    }

    const handleRemoveModalClose = () => {
        setOpenRemoveModal(false);
    }

    const handleRemoveModalConfirm = () => {
        deleteReceiver();
        setOpenRemoveModal(false);
    }
    //#endregion

    //#region EVENTS
    const handleAddNewClick = () => {
        setReceiver(undefined);
        setOpenEditModal(true);
    }

    const handleEditClick = (id: string) => {
        if (!ObjectValueController.isNullOrUndefinedOrEmpty(id)) {
            getReceiver(id);
        }
    };

    const handleRemoveClick = (id: string) => {
        setRemoveReceiverId(id);
        setOpenRemoveModal(true);
    }

    const handleFilterChange = (filterProp: B2CUiModels.mdlFilterProps) => {
        if (!ObjectValueController.isNullOrUndefinedOrEmpty(filterProp.key)) {
            var clonedRequest = _.cloneDeep(listRequest);
            clonedRequest = ({
                ...clonedRequest,
                [filterProp.key!]: filterProp.value
            });
            getReceivers(clonedRequest);
        }
    }

    const handleFilterClear = (key?: string) => {
        if (!ObjectValueController.isNullOrUndefinedOrEmpty(key)) {
            var clonedRequest = _.cloneDeep(listRequest);
            delete clonedRequest[key as keyof B2CApiServiceModels.Module.Receiver.ReceiverListRequest];
            getReceivers(clonedRequest);
        } else {
            getReceivers(new B2CApiServiceModels.Module.Receiver.ReceiverListRequest());
        }
    }

    const handlePagerChange = (pager?: B2CUiModels.mdlPagerProps) => {
        var req = _.cloneDeep(listRequest);
        req.pageNumber = pager?.pageNumber;
        req.pageSize = pager?.limit;
        getReceivers(req);
    }
    //#endregion

    //#region HELPERS
    const restorePageDefaults = () => {
        setForceClearFilters(true);
        setOpenEditModal(false);
    }

    const handleFormError = (messages: { message: string }[]) => {
        toast.error(messages.map(messageModel => messageModel.message).join('.'));
    }

    const handleFormSuccess = () => {
        restorePageDefaults();
        getReceivers();
        setOpenEditModal(false);
        toast.success(t("common.success"));
    }

    const handleExportToExcel = () => {
    }

    const handleExportToPdf = () => {
    }

    //#endregion

    //#region COMPONENT PROPS
    const tableColumns: SanColumnItem[] = [
        {
            key: "id",
            type: "string",
            label: "Id",
            visible: false
        },
        {
            key: "receiverType",
            model: B2CApiEnums.enmReceiverType,
            type: "enum",
            label: "Receiver Type",
            visible: true
        },
        {
            key: "name",
            type: "string",
            label: "Name",
            visible: true
        },
        {
            key: "eventType",
            model: B2CApiEnums.enmEventType,
            type: "enum",
            label: "Event Type",
            visible: true
        },
        {
            key: "to",
            type: "string",
            label: "To",
            visible: true
        },
        {
            key: "modifyDate",
            type: Date,
            label: "Modify Date",
            visible: true
        }
    ];

    const tableActionWrapper: SanActionWrapper = {
        actionCellType: enmActionCellType.Row,
        actions: [
            {
                apperance: enmButtonAppearance.ButtonPrimary,
                color: enmButtonColor.Blue,
                label: "Edit",
                onClick: handleEditClick
            },
            {
                apperance: enmButtonAppearance.ButtonPrimary,
                color: enmButtonColor.Red,
                label: "Delete",
                onClick: handleRemoveClick
            }]
    };

    const filters: SanFilterData[] = [
        {
            type: enmFilterType.ENUMSELECT,
            dataKey: "receiverType",
            label: t("receiverPage.byReceiverType"),
            model: B2CApiEnums.enmReceiverType
        },
        {
            type: enmFilterType.TEXT,
            dataKey: "name",
            label: t("receiverPage.byName")
        },
        {
            type: enmFilterType.ENUMSELECT,
            dataKey: "eventType",
            label: t("receiverPage.byEventType"),
            model: B2CApiEnums.enmEventType
        },
        {
            type: enmFilterType.TEXT,
            dataKey: "to",
            label: t("receiverPage.byTo")
        },
    ];

    const receiverFormProps: ReceiverFormProps = {
        errorCallback: handleFormError,
        successCallback: handleFormSuccess,
        receiver: receiver
    }
    //#endregion 

    return <SanAbility module={Module.Receiver} permissions={[Permission.Read]} errorState='ShowMessage'>
        {
            <>
                <div>
                    <SanPageTools
                        title={t("receiverPage.receivers")}
                        addNewFunction={handleAddNewClick}
                        addNewLabel={t("receiverPage.addNew")}
                        exportToExcelFunction={handleExportToExcel}
                        exportToExcelLabel={t("common.exportToExcel")}
                        exportToPdfFunction={handleExportToPdf}
                        exportToPdfLabel={t("common.exportToPdf")} />
                </div>
                <div>
                    <SanFilter forceClear={forceClearFilters} title={t("common.activeFilters")} onClearFilter={handleFilterClear} filters={filters} onChange={handleFilterChange} filterInputShowType={enmFilterInputShowType.SEPERATE} />
                </div>
                <div>
                    <SanTable data={listResponse?.data} tableColumns={tableColumns} tableActions={tableActionWrapper} />
                </div>
                <div>
                    <SanPagination totalCount={listResponse?.totalCount} resultCount={listResponse?.resultCount} activePage={listResponse?.pageNumber} activeLimit={listRequest.pageSize} onChange={handlePagerChange} />
                </div>
                <Stack>
                    <SanModal
                        backdrop={"static"}
                        type={settings?.panel.modalShowType}
                        open={openEditModal}
                        onClose={handleEditModalClose}
                        closeLabel="Close"
                        title={t("receiverPage.receiverForm.drawerTitle")}
                        body={ReceiverForm}
                        bodyProps={receiverFormProps}
                    />
                </Stack>
                <Stack>
                    <SanModal
                        backdrop={"static"}
                        type={B2CUiEnums.enmModalShowType.Modal}
                        open={openRemoveModal}
                        onClose={handleRemoveModalClose}
                        closeLabel="Close"
                        bodyProps={{ callBack: handleRemoveModalConfirm, typingRequired: true } as SanRemoveConfirmationProps}
                        title={t("common.attentionRequired")}
                        body={SanRemoveConfirmation}
                    />
                </Stack>
            </>
        }
    </SanAbility>;
}

export default ReceiverList;

